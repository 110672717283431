import React from 'react';
import PropTypes from 'prop-types';
import { Grid, ListItem, TextField } from '@material-ui/core';
import BooleanResultToggleButtons from '../../util/BooleanResultToggleButtons';
import TitledBooleanResultToggleButtons from '../../util/TitledBooleanResultToggleButtons';

const PULMONARY_VALVE_TYPE_DYSPLASTIC = 0;
const PULMONARY_VALVE_TYPE_NON_DYSPLASTIC = 1;

const PULMONARY_VALVE_GRADIENT_LESS_THAN_30 = 0;
const PULMONARY_VALVE_GRADIENT_MORE_THAN_30 = 1;

export class PulmonaryValvuloplasty extends React.PureComponent {

  constructor(props) {
    super(props);
  }

  onChanged(key, value, path = []) {
    path.unshift(this.props.dataGroupId);
    this.props.onChanged(key, value, path);
  }

  render() {
    return (
      <ListItem>
        <Grid container justify="space-around" alignItems="center" spacing={3}>
          <Grid item xs={3}>
            Пулмонална Валвулопластика
          </Grid>
          <Grid item xs={3}>
            <BooleanResultToggleButtons value={this.props.performed} 
              onValueChange={(newValue) => this.onChanged("performed", newValue)} />
          </Grid>
          <Grid item xs={6} />

          {this.props.performed && (
            <>
              <Grid item xs={6}>
                <TextField style={{ width: "100%" }} label="Индикации" variant="outlined" multiline rows={2} 
                  value={this.props.indications}
                  onChange={(event) => this.onChanged("indications", event.target.value)}/>
              </Grid>
              <Grid item xs={6} />

              <Grid item xs={3}>
                Подклапна Стеноза
              </Grid>
              <Grid item xs={3}>
                <BooleanResultToggleButtons
                  value={this.props.subvalvarObstruction}
                  onValueChange={(newValue) => this.onChanged("subvalvarObstruction", newValue)} />
              </Grid>
              <Grid item xs={6} />

              <Grid item xs={3}>
                Диспластична Пулмонална Клапа
              </Grid>
              <Grid item xs={3}>
                <BooleanResultToggleButtons
                  value={this.props.pulmonaryValveType === PULMONARY_VALVE_TYPE_DYSPLASTIC}
                  falseLabel="Да" trueLabel="Не"
                  onValueChange={(newValue) => 
                    this.onChanged("pulmonaryValveType", newValue ? PULMONARY_VALVE_TYPE_DYSPLASTIC : PULMONARY_VALVE_TYPE_NON_DYSPLASTIC)
                  } />
              </Grid>
              <Grid item xs={6} />

              <Grid item xs={2}>
                Градиент
              </Grid>
              <Grid item xs={2}>
                <TitledBooleanResultToggleButtons title="Преди процедурата"
                  value={this.props.pulmonaryValveGradientBeforeProcedure === PULMONARY_VALVE_GRADIENT_MORE_THAN_30}
                  falseLabel="< 30 mmHg" trueLabel="> 30 mmHg"
                  onValueChange={(newValue) => 
                    this.onChanged("pulmonaryValveGradientBeforeProcedure", newValue ? PULMONARY_VALVE_GRADIENT_MORE_THAN_30 : PULMONARY_VALVE_GRADIENT_LESS_THAN_30)
                  } />
              </Grid>
              <Grid item xs={2}>
                <TitledBooleanResultToggleButtons title="След процедурата"
                  value={this.props.pulmonaryValveGradientAfterProcedure === PULMONARY_VALVE_GRADIENT_MORE_THAN_30}
                  falseLabel="< 30 mmHg" trueLabel="> 30 mmHg"
                  onValueChange={(newValue) => 
                    this.onChanged("pulmonaryValveGradientAfterProcedure", newValue ? PULMONARY_VALVE_GRADIENT_MORE_THAN_30 : PULMONARY_VALVE_GRADIENT_LESS_THAN_30)
                  } />
              </Grid>
              <Grid item xs={6} />

              <Grid item xs={3}>
                Пулмонална Инсуфициенция
              </Grid>
              <Grid item xs={3}>
                <BooleanResultToggleButtons
                  value={this.props.pulmonaryInsufficiency}
                  onValueChange={(newValue) => this.onChanged("pulmonaryInsufficiency", newValue)} />
              </Grid>
              <Grid item xs={6} />

              {this.props.pulmonaryInsufficiency && (
                <>
                  <Grid item xs={3}>
                    Проведена Интервенция
                  </Grid>
                  <Grid item xs={3}>
                    <BooleanResultToggleButtons
                      value={this.props.pulmonaryInsufficiencyInterventionPerformed}
                      onValueChange={(newValue) => this.onChanged("pulmonaryInsufficiencyInterventionPerformed", newValue)} />
                  </Grid>
                  <Grid item xs={6} />
                </>
              )}
            </>
          )}
        </Grid>
      </ListItem>
    );
  }
}

PulmonaryValvuloplasty.propTypes = {
  onChanged: PropTypes.func.isRequired,
  dataGroupId: PropTypes.string.isRequired,

  performed: PropTypes.bool,
  indications: PropTypes.string,

  subvalvarObstruction: PropTypes.bool,
  pulmonaryValveType: PropTypes.number,
  pulmonaryValveGradientBeforeProcedure: PropTypes.number,
  pulmonaryValveGradientAfterProcedure: PropTypes.number,
  pulmonaryInsufficiency: PropTypes.bool,
  pulmonaryInsufficiencyInterventionPerformed: PropTypes.bool,
}
