import React from 'react'
import PropTypes from 'prop-types'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core'
import TreeViewComponent from '../../util/TreeViewComponent'
import { recordsToTree } from '../../util/TreeDataUtils'
import { listDiagnoses } from '../../rest/RESTUtils'
import { DiagnoseInput } from './DiagnoseInput'
import MultipleChoiceOptions from '../../util/MultipleChoiceOptions'
import DateInput from '../../util/DateInput'
import TextInput from '../../util/TextInput'

class DiagnoseChoiceDialog extends React.PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      selected: []
    }
  }

  onNodeSelected(nodeIds) {
    this.setState(function(state) {
      const selected = state.selected
      
      if (TreeViewComponent.isLeafNode(this.props.diagnoses, false, nodeIds[0]) && selected.indexOf(nodeIds[0]) == -1) {
        selected.push(nodeIds[0])
      }

      return {
        selected: selected
      }
    })
  }

  render() {
    return (
      <Dialog onClose={() => this.props.onClose(null)} open={true} width={500} fullWidth>
        <DialogTitle>Детайли</DialogTitle>
        <DialogContent>
          <TreeViewComponent multiSelect={true} root={this.props.diagnoses} showRootNode={false} 
            onNodeSelect={(nodeIds) => this.onNodeSelected(nodeIds)} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.props.onClose(null)} color='primary'>
            Затвори
          </Button>
          <Button disabled={this.state.level == -1 || this.state.timeToManage == -1} color='primary'
            onClick={() => this.props.onClose(this.state.selected)}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

DiagnoseChoiceDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  diagnoses: PropTypes.object.isRequired
}

export class GeneralInformation extends React.PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      birthDate: this.getBirthDate(this.props.personId)
    }
  }

  componentDidMount() {
    listDiagnoses((diagnoses) => {
      this.setState({ 
        diagnoses: recordsToTree(diagnoses)
      })
    })
  }

  onChanged(key, value, path = []) {
    path.unshift(this.props.dataGroupId)
    this.props.onChanged(key, value, path)
  }

  onStateChanged(key, value) {
    this.setState({
      [key]: value
    })
  }

  onDiagnosisChanged(diagnosisIds) {
    this.onChanged('diagnosisIds', diagnosisIds || [])
  }

  onBirthDateChanged(birthDate) {
    this.setState({
      birthDate: birthDate
    })
    this.onChanged('personId', this.getPersonId(birthDate))
  }

  onPersonIdChanged(personId) {
    this.setState({
      birthDate: this.getBirthDate(personId)
    })
    this.onChanged('personId', personId)
  }

  isPersonIdValid(personId) {
    return personId && personId.length === 10 && !personId.endsWith('0000')
  }

  getBirthDate(personId) {
    if (personId && personId.length >= 6) {
      let year = parseInt(personId.substring(0, 2))
      let month = parseInt(personId.substring(2, 4))
      let day = personId.substring(4, 6)

      if (month > 40) {
        year += 2000
        month -= 40
      } else if (month > 20) {
        year += 1800
        month -= 20
      } else {
        year += 1900
      }

      return day + '.' + month.toString().padStart(2, '0') + '.' + year.toString()
    } else {
      return ''
    }
  }

  getPersonId(birthDate) {
    if (birthDate && birthDate.length === 10) {
      let day = birthDate.substring(0, 2)
      let month = parseInt(birthDate.substring(3, 5))
      let year = parseInt(birthDate.substring(6, 10))

      if (year >= 2000) {
        year -= 2000
        month += 40
      } else if (year < 1900) {
        year -= 1800
        month += 20
      } else {
        year -= 1900
      }

      return year.toString() + month.toString().padStart(2, '0') + day + '0000'
    } else {
      return ''
    }
  }

  getValidPersonId(personId) {
    if (personId && !personId.endsWith('0000')) {
      return personId
    } else {
      return ''
    }
  }

  render () {
    return (
      <>
        { this.state.diagnoses && (
          <Grid container justify='space-around' alignItems='center' spacing={3}>
            <Grid item xs={3}>
              <TextInput 
                value={this.props.name || ''}
                label='Име'
                onValueChanged={value => this.onChanged('name', value)} />
            </Grid>
            <Grid item xs={3}>
              <TextInput 
                value={this.getValidPersonId(this.props.personId)}
                label='ЕГН'
                onValueChanged={value => this.onPersonIdChanged(value)} />
            </Grid>
            <Grid item xs={3}>
              <TextInput 
                value={this.state.birthDate}
                disabled={this.isPersonIdValid(this.props.personId)}
                label='Дата на раждане'
                onValueChanged={value => this.onBirthDateChanged(value)} />
            </Grid>
            <Grid item xs={3}/>

            <Grid item xs={3}>
              <TextInput 
                value={this.props.patientId || ''}
                label='ИЗ'
                onValueChanged={value => this.onChanged('patientId', value)} />
            </Grid>

            <Grid item xs={3}>
              <TextInput 
                value={this.props.protocolId || ''}
                label='Номер на протокол'
                onValueChanged={value => this.onChanged('protocolId', value)} />
            </Grid>

            <Grid item xs={3}>
              <DateInput label='Дата'
                value={this.props.date}
                onValueChanged={value => this.onChanged('date', value)}/>
            </Grid>

            <Grid item xs={3}/>

            <Grid item xs={6}>
              <DiagnoseInput diagnosesIds={this.props.diagnosisIds} diagnoses={this.state.diagnoses}
                onChanged={diagnosesIds => this.onDiagnosisChanged(diagnosesIds)} />
            </Grid>

            <Grid item xs={6}/>

            <Grid item xs={12}>
              <MultipleChoiceOptions 
                labels={[ 'Спешно', 'Планово' ]} 
                exclusive={true} 
                value={this.props.admissionType} onValueChanged={value => this.onChanged('admissionType', value)}
              />
            </Grid>
            <Grid item xs={12}>
              <MultipleChoiceOptions 
                labels={[ 'Нативно', 'Реоперация' ]} 
                exclusive={true} 
                value={this.props.operationHistoryInfo} onValueChanged={value => this.onChanged('operationHistoryInfo', value)}
              />
            </Grid>
            {this.props.operationHistoryInfo === 1 && 
              <Grid item xs={12}>
                <TextInput 
                  value={this.props.operationHistoryDescription || ''}
                  label='Допълнителна информация'
                  onValueChanged={value => this.onChanged('operationHistoryDescription', value)} />
              </Grid>
            } 

          </Grid>
        )}
      </>
    )
  }
}

GeneralInformation.propTypes = {
  onChanged: PropTypes.func.isRequired,
  dataGroupId: PropTypes.string.isRequired,

  name: PropTypes.string,
  personId: PropTypes.string,
  patientId: PropTypes.string,
  date: PropTypes.string,
  protocolId: PropTypes.string,
  diagnosisIds: PropTypes.array,
  admissionType: PropTypes.number,
  operationHistoryInfo: PropTypes.number,
  operationHistoryDescription: PropTypes.string
}