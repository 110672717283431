import React from 'react';
import PropTypes from 'prop-types';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

class BooleanResultToggleButtons extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <ToggleButtonGroup exclusive value={this.props.value || false} onChange={(event, newValue) => this.props.onValueChange(newValue)}>
        <ToggleButton value={false}>
          {this.props.falseLabel ? this.props.falseLabel : 'Не'}
        </ToggleButton>
        <ToggleButton value={true}>
          {this.props.trueLabel ? this.props.trueLabel : 'Да'}
        </ToggleButton>
      </ToggleButtonGroup>
    )
  }
}

BooleanResultToggleButtons.propTypes = {
  onValueChange: PropTypes.func.isRequired,
  
  value: PropTypes.bool,
  falseLabel: PropTypes.string,
  trueLabel: PropTypes.string
}

export default BooleanResultToggleButtons;
