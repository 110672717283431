import React from 'react';
import PropTypes from 'prop-types';
import { Grid, ListItem, TextField } from '@material-ui/core';
import BooleanResultToggleButtons from '../../util/BooleanResultToggleButtons';

export class PDADeviceClosure extends React.PureComponent {

  constructor(props) {
    super(props);
  }

  onChanged(key, value, path = []) {
    path.unshift(this.props.dataGroupId);
    this.props.onChanged(key, value, path);
  }

  render() {
    return (
      <ListItem>
        <Grid container justify="space-around" alignItems="center" spacing={3}>
          <Grid item xs={3}>
            PDA DEVICE CLOSURE
          </Grid>
          <Grid item xs={3}>
            <BooleanResultToggleButtons value={this.props.performed} 
              onValueChange={(newValue) => this.onChanged("performed", newValue)} />
          </Grid>
          <Grid item xs={6} />

          {this.props.performed && (
            <>
              <Grid item xs={6}>
                <TextField style={{ width: "100%" }} label="Индикации" variant="outlined" multiline rows={2} 
                  value={this.props.indications}
                  onChange={(event) => this.onChanged("indications", event.target.value)}/>
              </Grid>
              <Grid item xs={6} />

              <Grid item xs={3}>
                Остатъчен шънт
              </Grid>
              <Grid item xs={3}>
                <BooleanResultToggleButtons 
                  value={this.props.residualShunt}
                  onValueChange={(newValue) => this.onChanged("residualShunt", newValue)} />
              </Grid>
              <Grid item xs={6} />

              {this.props.residualShunt && (
                <>
                  <Grid item xs={3}>
                    <TextField style={{ width: "100%" }} label="Размер на шънта" variant="outlined" 
                      value={this.props.shuntSize}
                      onChange={(event) => this.onChanged("shuntSize", parseInt(event.target.value))}/>
                  </Grid>
                  <Grid item xs={9} />
                </>
              )}

              <Grid item xs={3}>
                Нова стеноза на лява пулмонална артерия
              </Grid>
              <Grid item xs={3}>
                <BooleanResultToggleButtons 
                  value={this.props.newLeftPulmonaryArteryStenosis}
                  onValueChange={(newValue) => this.onChanged("newLeftPulmonaryArteryStenosis", newValue)} />
              </Grid>
              <Grid item xs={6} />

              <Grid item xs={3}>
                Нова обструкция на дъгата на аортата
              </Grid>
              <Grid item xs={3}>
                <BooleanResultToggleButtons 
                  value={this.props.newAorticArchObstruction}
                  onValueChange={(newValue) => this.onChanged("newAorticArchObstruction", newValue)} />
              </Grid>
              <Grid item xs={6} />

              <Grid item xs={3}>
                Хемолиза след процедурата
              </Grid>
              <Grid item xs={3}>
                <BooleanResultToggleButtons 
                  value={this.props.hemolysisFollowingProcedure}
                  onValueChange={(newValue) => this.onChanged("hemolysisFollowingProcedure", newValue)} />
              </Grid>
              <Grid item xs={6} />

              <Grid item xs={3}>
                Устройството е изкарано от тялото
              </Grid>
              <Grid item xs={3}>
                <BooleanResultToggleButtons 
                  value={this.props.deviceRemovedFromBody}
                  onValueChange={(newValue) => this.onChanged("deviceRemovedFromBody", newValue)} />
              </Grid>
              <Grid item xs={6} />

              {this.props.deviceRemovedFromBody && (
                <>
                  <Grid item xs={6}>
                    <TextField style={{ width: "100%"}} label="Причина" variant="outlined" 
                      value={this.props.deviceRemovedReason}
                      onChange={(event) => this.onChanged("deviceRemovedReason", event.target.value)}/>
                  </Grid>
                  <Grid item xs={6} />

                  <Grid item xs={3}>
                    Поставено второ устройство
                  </Grid>
                  <Grid item xs={3}>
                    <BooleanResultToggleButtons 
                      value={this.props.secondDevicePlaced}
                      onValueChange={(newValue) => this.onChanged("secondDevicePlaced", newValue)} />
                  </Grid>
                  <Grid item xs={6} />

                  {this.props.secondDevicePlaced && (
                    <>
                      <Grid item xs={3}>
                        И второто устройство е изкарано
                      </Grid>
                      <Grid item xs={3}>
                        <BooleanResultToggleButtons 
                          value={this.props.secondDeviceRemoved}
                          onValueChange={(newValue) => this.onChanged("secondDeviceRemoved", newValue)} />
                      </Grid>
                      <Grid item xs={6} />

                      {this.props.secondDeviceRemoved && (
                        <>
                          <Grid item xs={6}>
                            <TextField style={{ width: "100%"}} label="Причина" variant="outlined" 
                              value={this.props.secondDeviceRemovedReason}
                              onChange={(event) => this.onChanged("secondDeviceRemovedReason", event.target.value)}/>
                          </Grid>
                          <Grid item xs={6} />
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </Grid>
      </ListItem>
    );
  }
}

PDADeviceClosure.propTypes = {
  onChanged: PropTypes.func.isRequired,
  dataGroupId: PropTypes.string.isRequired,

  performed: PropTypes.bool,
  indications: PropTypes.string,

  residualShunt: PropTypes.bool,
  shuntSize: PropTypes.number,
  newLeftPulmonaryArteryStenosis: PropTypes.bool,
  newAorticArchObstruction: PropTypes.bool,
  hemolysisFollowingProcedure: PropTypes.bool,
  deviceRemovedFromBody: PropTypes.bool,
  deviceRemovedReason: PropTypes.string,
  secondDevicePlaced: PropTypes.bool,
  secondDeviceRemoved: PropTypes.bool,
  secondDeviceRemovedReason: PropTypes.string
}
