import React from 'react'
import { PureComponent } from 'react'
import SurgicalPendingTable from './SurgicalPendingTable'
import { listPending, listSurgicalPending, listDiagnoses } from '../rest/RESTUtils'
import { recordsToTree } from '../util/TreeDataUtils'
import WeeklyPlan from './WeeklyPlan'
import CASE_TYPES from '../util/SurgicalCaseTypes'


class SurgicalPlanning extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      caseTypes: [],
      diagnoses: []
    }
  }
  
  init() {
    listDiagnoses(diagnoses => {
      listSurgicalPending((surgicalPending) => {
        listPending((pending) => {
          surgicalPending.forEach(element => {
            element.local = true
          })

          this.setState({
            caseTypes: recordsToTree(CASE_TYPES),
            diagnoses: recordsToTree(diagnoses),
            surgicalPending: surgicalPending,
            pending: pending
          })
        })
      })
    })
  }

  onPendingChanged() {
    listSurgicalPending((surgicalPending) => {
      surgicalPending.forEach(element => {
        element.local = true
      })
      
      this.setState({
        surgicalPending: surgicalPending
      })
    })
  }

  componentDidMount() {
    this.init()
  }

  render () {
    const allPending = []
    Array.prototype.push.apply(allPending, this.state.surgicalPending)
    Array.prototype.push.apply(allPending, this.state.pending)

    return (
      <>
        { this.state.pending && (
          <>
            <SurgicalPendingTable caseTypes={this.state.caseTypes} diagnoses={this.state.diagnoses} 
              pending={this.state.surgicalPending} onChanged={() => this.onPendingChanged()}/>
            <WeeklyPlan caseTypes={this.state.caseTypes} diagnoses={this.state.diagnoses} pending={allPending}/>
          </>
        )}
      </>
    )
  }
}

export default SurgicalPlanning
