import React from 'react'
import { AppBar, Tabs, Tab } from '@material-ui/core'
import Home from './home/Home.js'
import AddCase from './caseinfo/AddCase.js'
// import AddGuchCase from './caseinfo/AddGuchCase.js'
import AddSurgicalCase from './surgicalcaseinfo/AddSurgicalCase.js'
import Search from './search/Search.js'
// import GuchSearch from './search/GuchSearch.js'
import Planning from './planning/Planning.js'
import TabPanel from './util/TabPanel'
import SurgicalPlanning from './planning/SurgicalPlanning.js'
import UserUtils from './rest/UserUtils.js'
import SurgicalSearch from './surgicalsearch/SurgicalSearch.js'
import RevatioPlanning from './planning/RevatioPlanning.js'
import GuchPlanning from './planning/GuchPlanning.js'
import { getReport } from './rest/RESTUtils.js'

class LinkInformation {
  constructor(text, leftMargin, Component, allowedRoles) {
    this.text = text
    this.leftMargin = leftMargin
    this.Component = Component
    this.allowedRoles = allowedRoles
  }
}

const LINKS = [
  new LinkInformation('Начало', 'initial', Home),
  new LinkInformation('Добавяне на случай', 'initial', AddCase, ['riki-catheterization-team', 'riki-admin']),
  new LinkInformation('Добавяне на хир. случай', 'initial', AddSurgicalCase, ['riki-surgical-team', 'riki-admin']),
  // new LinkInformation('Добавяне на GUCH случай', 'initial', AddGuchCase, ['riki-guch-cath-team', 'riki-admin']),
  new LinkInformation('Търсене', 'initial', Search),
  new LinkInformation('Хирургично търсене', 'initial', SurgicalSearch),
  // new LinkInformation('GUCH търсене', 'initial', GuchSearch, ['riki-guch-cath-team', 'riki-admin']),
  new LinkInformation('Планиране', 'initial', Planning, ['riki-catheterization-team', 'riki-admin']),
  new LinkInformation('Хирургично Планиране', 'initial', SurgicalPlanning, ['riki-surgical-team', 'riki-admin']),
  new LinkInformation('GUCH', 'initial', GuchPlanning, ['riki-guch-cath-team', 'riki-admin']),
  new LinkInformation('Ревацио', 'initial', RevatioPlanning, ['riki-admin']),
  new LinkInformation('Отчет', 'initial', Home, ['riki-report', 'riki-admin']),
  new LinkInformation('Изход', 'auto', Home)
]

class App extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      selected: 0,
      links: this.filteredLinks()
    }
  }

  filteredLinks() {
    return LINKS.filter(link => !link.allowedRoles || UserUtils.hasRole(link.allowedRoles))
  }

  handleChange(selected) {
    if (selected === this.state.links.length - 1) {
      UserUtils.doLogout()
      this.setState({
        selected: 0
      })
    } else if (UserUtils.hasRole(['riki-report', 'riki-admin']) && selected === this.state.links.length - 2) {
      this.generateReport()
    } else {
      this.setState({selected: selected})
    }
  }

  generateReport() {
    getReport((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'report.xlsx');
      
      document.body.appendChild(link);
      
      link.click();
      
      link.parentNode.removeChild(link)
    })
  }

  render () {
    return (
      <div>
        <AppBar position="sticky">
          <Tabs value={this.state.selected} onChange={(event, newValue) => this.handleChange(newValue)}>
            {
              this.state.links
                .map((info, index) => (
                  <Tab key={index} label={info.text} style={{ 'margin-left': info.leftMargin, 'font-size': 13 }} />
                ))
            }
          </Tabs>
        </AppBar>

        {
          this.state.links
            .map((info, index) => (
              <TabPanel key={index} value={this.state.selected} index={index} >
                <info.Component />
              </TabPanel>
            ))
        }
      </div>
    )
  }
}

export default App
