import { TreeItem, TreeView } from '@material-ui/lab';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import React from 'react';
import PropTypes from 'prop-types';

class TreeViewComponent extends React.Component {
  
  constructor(props) {
    super(props);
  }

  generateTreeItems() {
    const root = this.props.root;
    const shouldShowRoot = this.props.shouldShowRoot;

    if (shouldShowRoot) {
      return (
        this.generateNodeAndChildren(root, "")
      )
    } else {
      return (
        root.children && root.children.map((child) => (
          <>
            {this.generateNodeAndChildren(child, "")}
          </>
        ))
      )
    }
  }

  generateNodeAndChildren(node, prefix) {
    const nodeId = prefix ? prefix + "|" + node.id : node.id;

    return (
      <TreeItem key={nodeId} nodeId={nodeId} label={node.title}>
        {
          node.children && node.children.map((child) => (
            <>
              {this.generateNodeAndChildren(child, nodeId)}
            </>
          ))
        }
      </TreeItem>
    )
  }

  getNode(nodeId) {
    return this.getNode(this.props.root, this.props.shouldShowRoot, nodeId);
  }

  static getNode(root, shouldShowRoot, nodeId) {
    const pathParts = nodeId.split("|").slice(shouldShowRoot ? 1 : 0)

    let currentNode = root;

    for (const pathPart of pathParts) {
      const children = currentNode.children;
      currentNode = null;

      if (children) {
        for (const child of children) {
          if (child.id === pathPart) {
            currentNode = child;
            break;
          }
        }
      }

      if (currentNode === null) {
        return null;
      }
    }

    return currentNode;
  }

  static isLeafNode(root, shouldShowRoot, nodeId) {
    const data = TreeViewComponent.getNode(root, shouldShowRoot, nodeId);
    return data && (!data.children || !data.children.length);
  }

  render() {
    return (
      <TreeView multiSelect={this.props.multiSelect} style={{ height: 300, overflow: "auto" }} 
        defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />}
        onNodeSelect={(event, nodeIds) => this.props.onNodeSelect(nodeIds)}>
        {
          this.generateTreeItems()
        }
      </TreeView>
    )
  }
}

TreeViewComponent.propTypes = {
  root: PropTypes.object.isRequired,
  shouldShowRoot: PropTypes.bool,
  multiSelect: PropTypes.bool,
  onNodeSelect: PropTypes.func
}

export default TreeViewComponent;