import Keycloak from 'keycloak-js'
import keycloakConfig from '../keycloak.json'

const initOptions = {
    url: keycloakConfig.url, 
    realm: keycloakConfig.realm, 
    clientId: keycloakConfig.clientId, 
    onLoad: keycloakConfig.onLoad, 
    checkLoginIframe: keycloakConfig.checkLoginIframe
}

const keycloak = new Keycloak(initOptions)

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback) => {
  keycloak.init({
    onLoad: initOptions.onLoad
  })
    .then((authenticated) => {
      if (authenticated) {
        onAuthenticatedCallback()
      } else {
        console.log('user is not authenticated..!')
      }
    })
    .catch(console.error)
}

const doLogin = keycloak.login

const doLogout = keycloak.logout

const getToken = () => keycloak.token

const isLoggedIn = () => !!keycloak.token

const updateToken = (successCallback) =>
  keycloak.updateToken(5)
    .then(successCallback)
    .catch(doLogin)

const getUsername = () => keycloak.tokenParsed?.given_name + ' ' + keycloak.tokenParsed?.family_name
const getResearcherNumber = () => keycloak.tokenParsed?.researcherNumber

const hasRole = (roles) => roles.some((role) => keycloak.hasRealmRole(role))

const UserUtils = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  getResearcherNumber,
  hasRole,
}

export default UserUtils