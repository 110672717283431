import configData from '../config.json'
import UserUtils from './UserUtils.js'

function performRESTCall(callback) {
  UserUtils.updateToken(callback)
}

export function listDiagnoses(listDiagnosesCallback) {
  performRESTCall(() => {
    const url = configData.SERVER_URL + '/api/diseases'
    const requestOptions = {
      method: 'GET',
      headers: { 'Authorization': 'Bearer ' + UserUtils.getToken() }
    }

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(caseTypes => listDiagnosesCallback(caseTypes))
      .catch(console.log)
  })
}

export function listPending(listPendingCallback) {
  performRESTCall(() => {
    const url = configData.SERVER_URL + '/api/pending'
    const requestOptions = {
      method: 'GET',
      headers: { 'Authorization': 'Bearer ' + UserUtils.getToken() }
    }

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(pending => listPendingCallback(pending))
      .catch(console.log)
  })
}

export function addPending(pending, addPendingCallback) {
  performRESTCall(() => {
    const url = configData.SERVER_URL + '/api/pending'
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + UserUtils.getToken() },
      body: JSON.stringify(pending)
    }

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(data => addPendingCallback(data))
      .catch(console.log)
  })
}

export function deletePending(pendingId, deletePendingCallback) {
  performRESTCall(() => {
    const url = configData.SERVER_URL + '/api/pending/' + pendingId
    const requestOptions = {
      method: 'DELETE',
      headers: { 'Authorization': 'Bearer ' + UserUtils.getToken() }
    }

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(() => deletePendingCallback())
      .catch(console.log)
  })
}

export function deletePendingByPersonId(personId, deletePendingCallback) {
  performRESTCall(() => {
    const url = configData.SERVER_URL + '/api/pending'
    const requestOptions = {
      method: 'DELETE',
      headers: { 'Authorization': 'Bearer ' + UserUtils.getToken() },
      body: JSON.stringify({ 'personId': personId })
    }

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(() => deletePendingCallback())
      .catch(console.log)
  })
}

export function planPending(pendingId, targetDate, planPendingCallback) {
  performRESTCall(() => {
    const url = configData.SERVER_URL + '/api/pending/' + pendingId + '/planFor/' + targetDate
    const requestOptions = {
      method: 'PUT',
      headers: { 'Authorization': 'Bearer ' + UserUtils.getToken() }
    }

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(() => planPendingCallback())
      .catch(console.log)
  })
}

export function listSurgicalPending(listPendingCallback) {
  performRESTCall(() => {
    const url = configData.SERVER_URL + '/api/surgicalpending'
    const requestOptions = {
      method: 'GET',
      headers: { 'Authorization': 'Bearer ' + UserUtils.getToken() }
    }

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(pending => listPendingCallback(pending))
      .catch(console.log)
  })
}

export function addSurgicalPending(pending, addPendingCallback) {
  performRESTCall(() => {
    const url = configData.SERVER_URL + '/api/surgicalpending'
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + UserUtils.getToken() },
      body: JSON.stringify(pending)
    }

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(data => addPendingCallback(data))
      .catch(console.log)
  })
}

export function deleteSurgicalPending(pendingId, deletePendingCallback) {
  performRESTCall(() => {
    const url = configData.SERVER_URL + '/api/surgicalpending/' + pendingId
    const requestOptions = {
      method: 'DELETE',
      headers: { 'Authorization': 'Bearer ' + UserUtils.getToken() }
    }

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(() => deletePendingCallback())
      .catch(console.log)
  })
}

export function deleteSurgicalPendingByPersonId(personId, deletePendingCallback) {
  performRESTCall(() => {
    const url = configData.SERVER_URL + '/api/surgicalpending'
    const requestOptions = {
      method: 'DELETE',
      headers: { 'Authorization': 'Bearer ' + UserUtils.getToken() },
      body: JSON.stringify({ 'personId': personId })
    }

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(() => deletePendingCallback())
      .catch(console.log)
  })
}

export function planSurgicalPending(pendingId, targetDate, planPendingCallback) {
  performRESTCall(() => {
    const url = configData.SERVER_URL + '/api/surgicalpending/' + pendingId + '/planFor/' + targetDate
    const requestOptions = {
      method: 'PUT',
      headers: { 'Authorization': 'Bearer ' + UserUtils.getToken() }
    }

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(() => planPendingCallback())
      .catch(console.log)
  })
}

export function listRevatioPending(listPendingCallback) {
  performRESTCall(() => {
    const url = configData.SERVER_URL + '/api/revatiopending'
    const requestOptions = {
      method: 'GET',
      headers: { 'Authorization': 'Bearer ' + UserUtils.getToken() }
    }

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(pending => listPendingCallback(pending))
      .catch(console.log)
  })
}

export function addRevatioPending(pending, addPendingCallback) {
  performRESTCall(() => {
    const url = configData.SERVER_URL + '/api/revatiopending'
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + UserUtils.getToken() },
      body: JSON.stringify(pending)
    }

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(data => addPendingCallback(data))
      .catch(console.log)
  })
}

export function deleteRevatioPending(pendingId, deletePendingCallback) {
  performRESTCall(() => {
    const url = configData.SERVER_URL + '/api/revatiopending/' + pendingId
    const requestOptions = {
      method: 'DELETE',
      headers: { 'Authorization': 'Bearer ' + UserUtils.getToken() }
    }

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(() => deletePendingCallback())
      .catch(console.log)
  })
}

export function deleteRevatioPendingByPersonId(personId, deletePendingCallback) {
  performRESTCall(() => {
    const url = configData.SERVER_URL + '/api/revatiopending'
    const requestOptions = {
      method: 'DELETE',
      headers: { 'Authorization': 'Bearer ' + UserUtils.getToken() },
      body: JSON.stringify({ 'personId': personId })
    }

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(() => deletePendingCallback())
      .catch(console.log)
  })
}

export function planRevatioPending(pendingId, targetDate, planPendingCallback) {
  performRESTCall(() => {
    const url = configData.SERVER_URL + '/api/revatiopending/' + pendingId + '/planFor/' + targetDate
    const requestOptions = {
      method: 'PUT',
      headers: { 'Authorization': 'Bearer ' + UserUtils.getToken() }
    }

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(() => planPendingCallback())
      .catch(console.log)
  })
}

export function listGuchPending(listPendingCallback) {
  performRESTCall(() => {
    const url = configData.SERVER_URL + '/api/guchpending'
    const requestOptions = {
      method: 'GET',
      headers: { 'Authorization': 'Bearer ' + UserUtils.getToken() }
    }

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(pending => listPendingCallback(pending))
      .catch(console.log)
  })
}

export function addGuchPending(pending, addPendingCallback) {
  performRESTCall(() => {
    const url = configData.SERVER_URL + '/api/guchpending'
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + UserUtils.getToken() },
      body: JSON.stringify(pending)
    }

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(data => addPendingCallback(data))
      .catch(console.log)
  })
}

export function deleteGuchPending(pendingId, deletePendingCallback) {
  performRESTCall(() => {
    const url = configData.SERVER_URL + '/api/guchpending/' + pendingId
    const requestOptions = {
      method: 'DELETE',
      headers: { 'Authorization': 'Bearer ' + UserUtils.getToken() }
    }

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(() => deletePendingCallback())
      .catch(console.log)
  })
}

export function deleteGuchPendingByPersonId(personId, deletePendingCallback) {
  performRESTCall(() => {
    const url = configData.SERVER_URL + '/api/guchpending'
    const requestOptions = {
      method: 'DELETE',
      headers: { 'Authorization': 'Bearer ' + UserUtils.getToken() },
      body: JSON.stringify({ 'personId': personId })
    }

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(() => deletePendingCallback())
      .catch(console.log)
  })
}

export function planGuchPending(pendingId, targetDate, planPendingCallback) {
  performRESTCall(() => {
    const url = configData.SERVER_URL + '/api/guchpending/' + pendingId + '/planFor/' + targetDate
    const requestOptions = {
      method: 'PUT',
      headers: { 'Authorization': 'Bearer ' + UserUtils.getToken() }
    }

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(() => planPendingCallback())
      .catch(console.log)
  })
}

export function listCaseTypes(listCaseTypesCallback) {
  performRESTCall(() => {
    const url = configData.SERVER_URL + '/api/casetypes'
    const requestOptions = {
      method: 'GET',
      headers: { 'Authorization': 'Bearer ' + UserUtils.getToken() }
    }

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(caseTypes => listCaseTypesCallback(caseTypes))
      .catch(console.log)
  })
}

export function getCase(caseId, getCaseCallback) {
  performRESTCall(() => {
    const url = configData.SERVER_URL + '/api/cases/' + caseId
    const requestOptions = {
      method: 'GET',
      headers: { 'Authorization': 'Bearer ' + UserUtils.getToken() }
    }

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(caseInfo => getCaseCallback(caseInfo))
      .catch(console.log)
  })
}

export function addCase(caseInformation, addCaseCallback) {
  performRESTCall(() => {
    const url = configData.SERVER_URL + '/api/cases'
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + UserUtils.getToken() },
      body: JSON.stringify(caseInformation)
    }

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(data => addCaseCallback(data))
      .catch(console.log)
  })
}

export function getCaseProtocol(caseId, getCaseProtocolCallback) {
  performRESTCall(() => {
    const url = configData.SERVER_URL + '/api/cases/' + caseId + '/protocol'
    const requestOptions = {
      method: 'GET',
      headers: { 'Authorization': 'Bearer ' + UserUtils.getToken() }
    }

    fetch(url, requestOptions)
      .then(response => response.blob())
      .then(blob => getCaseProtocolCallback(blob))
      .catch(console.log)
  })
}

export function getReport(getReportCallback) {
  performRESTCall(() => {
    const url = configData.SERVER_URL + '/api/cases/report'
    const requestOptions = {
      method: 'GET',
      headers: { 'Authorization': 'Bearer ' + UserUtils.getToken() }
    }

    fetch(url, requestOptions)
      .then(response => response.blob())
      .then(blob => getReportCallback(blob))
      .catch(console.log)
  })
}

export function getCurrentCaseProtocol(caseInfo, getCaseProtocolCallback) {
  performRESTCall(() => {
    const url = configData.SERVER_URL + '/api/cases/protocol'
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + UserUtils.getToken() },
      body: JSON.stringify(caseInfo)
    }

    fetch(url, requestOptions)
      .then(response => response.blob())
      .then(blob => getCaseProtocolCallback(blob))
      .catch(console.log)
  })
}

export function deleteCase(caseId, deleteCaseCallback) {
  performRESTCall(() => {
    const url = configData.SERVER_URL + '/api/cases/' + caseId
    const requestOptions = {
      method: 'DELETE',
      headers: { 'Authorization': 'Bearer ' + UserUtils.getToken() }
    }

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(() => deleteCaseCallback())
      .catch(console.log)
  })
}

export function listCases(filter, listCasesCallback) {
  performRESTCall(() => {
    const url = createFilterURL(filter)
    const requestOptions = {
      method: 'GET',
      headers: { 'Authorization': 'Bearer ' + UserUtils.getToken() }
    }

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(newData => listCasesCallback(newData))
      .catch(console.log)
  })
}

export function getGuchCase(caseId, getCaseCallback) {
  performRESTCall(() => {
    const url = configData.SERVER_URL + '/api/guchcases/' + caseId
    const requestOptions = {
      method: 'GET',
      headers: { 'Authorization': 'Bearer ' + UserUtils.getToken() }
    }

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(caseInfo => getCaseCallback(caseInfo))
      .catch(console.log)
  })
}

export function addGuchCase(caseInformation, addCaseCallback) {
  performRESTCall(() => {
    const url = configData.SERVER_URL + '/api/guchcases'
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + UserUtils.getToken() },
      body: JSON.stringify(caseInformation)
    }

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(data => addCaseCallback(data))
      .catch(console.log)
  })
}

export function getGuchCaseProtocol(caseId, getCaseProtocolCallback) {
  performRESTCall(() => {
    const url = configData.SERVER_URL + '/api/guchcases/' + caseId + '/protocol'
    const requestOptions = {
      method: 'GET',
      headers: { 'Authorization': 'Bearer ' + UserUtils.getToken() }
    }

    fetch(url, requestOptions)
      .then(response => response.blob())
      .then(blob => getCaseProtocolCallback(blob))
      .catch(console.log)
  })
}

export function getGuchReport(getReportCallback) {
  performRESTCall(() => {
    const url = configData.SERVER_URL + '/api/guchcases/report'
    const requestOptions = {
      method: 'GET',
      headers: { 'Authorization': 'Bearer ' + UserUtils.getToken() }
    }

    fetch(url, requestOptions)
      .then(response => response.blob())
      .then(blob => getReportCallback(blob))
      .catch(console.log)
  })
}

export function getCurrentGuchCaseProtocol(caseInfo, getCaseProtocolCallback) {
  performRESTCall(() => {
    const url = configData.SERVER_URL + '/api/guchcases/protocol'
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + UserUtils.getToken() },
      body: JSON.stringify(caseInfo)
    }

    fetch(url, requestOptions)
      .then(response => response.blob())
      .then(blob => getCaseProtocolCallback(blob))
      .catch(console.log)
  })
}

export function deleteGuchCase(caseId, deleteCaseCallback) {
  performRESTCall(() => {
    const url = configData.SERVER_URL + '/api/guchcases/' + caseId
    const requestOptions = {
      method: 'DELETE',
      headers: { 'Authorization': 'Bearer ' + UserUtils.getToken() }
    }

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(() => deleteCaseCallback())
      .catch(console.log)
  })
}

export function listGuchCases(filter, listCasesCallback) {
  performRESTCall(() => {
    const url = createGuchFilterURL(filter)
    const requestOptions = {
      method: 'GET',
      headers: { 'Authorization': 'Bearer ' + UserUtils.getToken() }
    }

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(newData => listCasesCallback(newData))
      .catch(console.log)
  })
}

export function getSurgicalCase(caseId, getCaseCallback) {
  performRESTCall(() => {
    const url = configData.SERVER_URL + '/api/surgicalcases/' + caseId
    const requestOptions = {
      method: 'GET',
      headers: { 'Authorization': 'Bearer ' + UserUtils.getToken() }
    }

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(caseInfo => getCaseCallback(caseInfo))
      .catch(console.log)
  })
}

export function addSurgicalCase(caseInformation, addCaseCallback) {
  performRESTCall(() => {
    const url = configData.SERVER_URL + '/api/surgicalcases'
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + UserUtils.getToken() },
      body: JSON.stringify(caseInformation)
    }

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(data => addCaseCallback(data))
      .catch(console.log)
  })
}

export function getSurgicalCaseProtocol(caseId, getCaseProtocolCallback) {
  performRESTCall(() => {
    const url = configData.SERVER_URL + '/api/surgicalcases/' + caseId + '/protocol'
    const requestOptions = {
      method: 'GET',
      headers: { 'Authorization': 'Bearer ' + UserUtils.getToken() }
    }

    fetch(url, requestOptions)
      .then(response => response.blob())
      .then(blob => getCaseProtocolCallback(blob))
      .catch(console.log)
  })
}

export function getCurrentSurgicalCaseProtocol(caseInfo, getCaseProtocolCallback) {
  performRESTCall(() => {
    const url = configData.SERVER_URL + '/api/surgicalcases/protocol'
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + UserUtils.getToken() },
      body: JSON.stringify(caseInfo)
    }

    fetch(url, requestOptions)
      .then(response => response.blob())
      .then(blob => getCaseProtocolCallback(blob))
      .catch(console.log)
  })
}

export function deleteSurgicalCase(caseId, deleteCaseCallback) {
  performRESTCall(() => {
    const url = configData.SERVER_URL + '/api/surgicalcases/' + caseId
    const requestOptions = {
      method: 'DELETE',
      headers: { 'Authorization': 'Bearer ' + UserUtils.getToken() }
    }

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(() => deleteCaseCallback())
      .catch(console.log)
  })
}

export function listSurgicalCases(filter, listCasesCallback) {
  performRESTCall(() => {
    const url = createSurgicalFilterURL(filter)
    const requestOptions = {
      method: 'GET',
      headers: { 'Authorization': 'Bearer ' + UserUtils.getToken() }
    }

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(newData => listCasesCallback(newData))
      .catch(console.log)
  })
}

function createFilterURL(filter) {
  const url = configData.SERVER_URL + '/api/cases'
  const params = filter && 
    Object.entries(filter)
          .filter((element) => {
            return element[1]
          })
          .map((element) => {
            return element[1] && element[0] + '=' + encodeURIComponent(element[1])
          })
          .join('&')

  if (params) {
    return url + '?' + params
  } else {
    return url
  }
}

function createGuchFilterURL(filter) {
  const url = configData.SERVER_URL + '/api/guchcases'
  const params = filter && 
    Object.entries(filter)
          .filter((element) => {
            return element[1]
          })
          .map((element) => {
            return element[1] && element[0] + '=' + encodeURIComponent(element[1])
          })
          .join('&')

  if (params) {
    return url + '?' + params
  } else {
    return url
  }
}

function createSurgicalFilterURL(filter) {
  const url = configData.SERVER_URL + '/api/surgicalcases'
  const params = filter && 
    Object.entries(filter)
          .filter((element) => {
            return element[1]
          })
          .map((element) => {
            return element[1] && element[0] + '=' + encodeURIComponent(element[1])
          })
          .join('&')

  if (params) {
    return url + '?' + params
  } else {
    return url
  }
}