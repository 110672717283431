import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { calculateBodyArea } from '../../util/Formulas';

export class PhysicalInformation extends React.PureComponent {

  constructor(props) {
    super(props);
  }

  onChanged(key, value, path = []) {
    path.unshift(this.props.dataGroupId);
    this.props.onChanged(key, value, path);
  }

  calculateAge(age) {
    if (age) {
      return (age.years ? age.years + "г " : "") + 
             (age.months ? age.months + "м " : "") + 
             (age.days ? age.days + "д " : "");
    } else {
      return "";
    }
  }

  render() {
    return (
      <Grid container justify="space-around" alignItems="center" spacing={3} >
        <Grid item xs={3}>
          <TextField style={{ width: "100%" }} disabled variant="outlined" label="Възраст" 
            value={this.calculateAge(this.props.age)} />
        </Grid>
        <Grid item xs={3}>
          <FormControl style={{ width: "100%", minWidth: 100 }}>
            <InputLabel id="gender-select-label" style={{ marginLeft: 15 }}>Пол</InputLabel>
            <Select style={{ width: "100%" }} variant="outlined" value={this.props.gender || ""}
              labelId="gender-select-label"
              onChange={(event) => this.onChanged("gender", event.target.value)}
            >
              {
                ["Мъжки", "Женски"].map((item, index) => (
                  <MenuItem key={item} value={index + 1}>{item}</MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} />

        <Grid item xs={3}>
          <TextField variant="outlined" value={this.props.height || ""} 
            style={{ width: "100%" }} label="Ръст"
            type="number"
            onChange={(event) => this.onChanged("height", parseInt(event.target.value))}
            InputProps={{
              endAdornment: <InputAdornment position="end">cm</InputAdornment>,
              inputProps: { min: 10, max:300 }
            }}/>
        </Grid>
        <Grid item xs={3}>
          <TextField variant="outlined" value={this.props.weight || ""} 
            style={{ width: "100%" }} label="Тегло"
            onChange={(event) => this.onChanged("weight", event.target.value)}
            InputProps={{
              endAdornment: <InputAdornment position="end">kg</InputAdornment>,
            }}/>
        </Grid>
        <Grid item xs={6} />

        <Grid item xs={3}>
          <TextField disabled variant="outlined" value={calculateBodyArea({height: this.props.height, weight: this.props.weight})}
            style={{ width: "100%" }} label="Телесна повърхност" />
        </Grid>
        <Grid item xs={9} />
      </Grid>
    );
  }
}

PhysicalInformation.propTypes = {
  onChanged: PropTypes.func.isRequired,
  dataGroupId: PropTypes.string.isRequired,

  age: PropTypes.object,
  gender: PropTypes.number,
  height: PropTypes.number,
  weight: PropTypes.number,
  hemoglobin: PropTypes.number
}
