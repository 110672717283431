import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Paper } from '@material-ui/core'
import MultipleChoiceOptions from '../../util/MultipleChoiceOptions'
import BooleanOptions from '../../util/BooleanOptions'
import TextInput from '../../util/TextInput'

export class ProcedureCharacteristics extends React.PureComponent {

  constructor(props) {
    super(props)
  }

  onChanged(key, value, path = []) {
    path.unshift(this.props.dataGroupId)
    this.props.onChanged(key, value, path)
  }

  render () {
    return (
      <Grid container justify='space-around' alignItems='center' spacing={3}>
        <Grid item xs={6}>
          <MultipleChoiceOptions title='Достъп:'
            labels={[ 'стернотомия', 'торакотомия' ]} 
            exclusive={true} 
            value={this.props.access} onValueChanged={value => this.onChanged('access', value)}
          />
        </Grid>
        <Grid item xs={6}/>

        <Grid item xs={3}>
          <BooleanOptions
            falseLabel='операция без ЕКК' trueLabel='операция с ЕКК'
            exclusive={true} 
            value={this.props.ecc} onValueChanged={value => this.onChanged('ecc', value)}
          />
        </Grid>
        <Grid item xs={3}>
          <MultipleChoiceOptions title='Канюлиране:'
            labels={[ 'стандартно', 'феморално', 'друго' ]} 
            disabled={!this.props.ecc}
            exclusive={true} 
            value={this.props.eccType} onValueChanged={value => this.onChanged('eccType', value)}
          />
        </Grid>
        {this.props.ecc && this.props.eccType === 2 ? (
          <Grid item xs={3}>
            <TextInput 
              value={this.props.otherECCType}
              label='Друго канюлиране'
              onValueChanged={value => this.onChanged('otherECCType', value)} />
          </Grid>
        ) : (
          <Grid item xs={3}/>
        )} 
        <Grid item xs={3}/>
        
        <Grid item xs={3}>
          <TextInput 
            value={this.props.eccTime}
            type='number'
            label='ЕКК време'
            adornment='мин.'
            onValueChanged={value => this.onChanged('eccTime', value)} />
        </Grid>
        <Grid item xs={3}>
          <TextInput 
            value={this.props.clampTime}
            type='number'
            label='Клампаж'
            adornment='мин.'
            onValueChanged={value => this.onChanged('clampTime', value)} />
        </Grid>
        <Grid item xs={3}>
          <TextInput 
            value={this.props.reperfusion}
            type='number'
            label='Реперфузия'
            adornment='мин.'
            onValueChanged={value => this.onChanged('reperfusion', value)} />
        </Grid>
        <Grid item xs={3}/>

        <Grid item xs={3}>
          <TextInput 
            value={this.props.dhca}
            type='number'
            label='ДХЦА'
            adornment='мин.'
            onValueChanged={value => this.onChanged('dhca', value)} />
        </Grid>
        <Grid item xs={3}>
          <TextInput 
            value={this.props.antegradeBrainPerfusion}
            type='number'
            label='Антеградна мозъчна перфузия'
            adornment='мин.'
            onValueChanged={value => this.onChanged('antegradeBrainPerfusion', value)} />
        </Grid>
        <Grid item xs={6}/>

        <Grid item xs={12}>
          <Paper elevation={3} style={{ padding: 5 }}>
            <Grid container justify='space-around' alignItems='center' spacing={3}>
              <Grid item xs={12}>
                <b>Кардиоплегия</b>
              </Grid>

              <Grid item xs={3}>
                <TextInput 
                  value={this.props.cardioplegiaType}
                  label='Вид'
                  onValueChanged={value => this.onChanged('cardioplegiaType', value)} />
              </Grid>
              <Grid item xs={3}>
                <TextInput 
                  value={this.props.cardioplegiaVolume}
                  type='number'
                  label='Количество'
                  adornment='мл.'
                  onValueChanged={value => this.onChanged('cardioplegiaVolume', value)} />
              </Grid>
              <Grid item xs={3}>
                <TextInput 
                  value={this.props.cardioplegiaTime}
                  type='number'
                  label='Време'
                  adornment='мин.'
                  onValueChanged={value => this.onChanged('cardioplegiaTime', value)} />
              </Grid>
              <Grid item xs={3}/>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={3}>
          <TextInput 
            value={this.props.lowestTemperature}
            type='number'
            label='Най-ниска температура'
            adornment='°C'
            onValueChanged={value => this.onChanged('lowestTemperature', value)} />
        </Grid>
        <Grid item xs={3}>
          <BooleanOptions
            exclusive={true} title='УФ:'
            value={this.props.uf} onValueChanged={value => this.onChanged('uf', value)}/>
        </Grid>
        <Grid item xs={3}>
          <BooleanOptions
            exclusive={true} title='МУФ:'
            value={this.props.muf} onValueChanged={value => this.onChanged('muf', value)}/>
        </Grid>
        <Grid item xs={3}/>

      </Grid>
    )
  }
}

ProcedureCharacteristics.propTypes = {
  onChanged: PropTypes.func.isRequired,
  dataGroupId: PropTypes.string.isRequired,

  access: PropTypes.number,

  ecc: PropTypes.bool,
  eccType: PropTypes.number,
  otherECCType: PropTypes.string,

  eccTime: PropTypes.number,
  clampTime: PropTypes.number,
  reperfusion: PropTypes.number,
  dhca: PropTypes.number,
  antegradeBrainPerfusion: PropTypes.number,

  cardioplegiaType: PropTypes.string,
  cardioplegiaVolume: PropTypes.number,
  cardioplegiaTime: PropTypes.number,

  lowestTemperature: PropTypes.number,

  uf: PropTypes.bool,
  muf: PropTypes.bool
}