import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Fab, FormControl, Grid, IconButton, InputLabel, 
  MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types'
import { PureComponent } from 'react';
import { addSurgicalPending, deleteSurgicalPending } from '../rest/RESTUtils'
import { calculateAge } from '../util/Formulas'
import TreeViewComponent from '../util/TreeViewComponent';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import DateSelectDialog from '../util/DateSelectDialog'
import { DiagnoseInput } from '../caseinfo/generalinfo/DiagnoseInput';
import EditIcon from '@material-ui/icons/Edit';
import EventIcon from '@material-ui/icons/Event';
import DeleteIcon from '@material-ui/icons/Delete';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { CaseType } from '../caseinfo/casetype/CaseType';

const columns = [
  { id: 'name', label: 'Име', width: "10%", align: 'center' },
  { id: 'age', label: 'Възраст', width: "7%", align: 'center' },
  { id: 'phoneNumber', label: 'Телефонен номер', width: "8%", align: 'center' },
  { id: 'diagnose', label: 'Диагноза', width: "15%", align: 'center' },
  { id: 'caseType', label: 'Вид на процедура', width: "15%", align: 'center' },
  { id: 'additionalInfo', label: '', width: "15%", align: 'center' },
  { id: 'previousICE', label: 'ИКИ', width: "10%", align: 'center' },
  { id: 'remark', label: 'Забележка', width: "10%", align: 'center' },
  { id: 'notAfter', label: 'Срок', width: "8%", align: 'center' }
];

const periodTypes = ["1 месец", "3 месеца", "6 месеца", "12 месеца", "Друг период"]
const operators = ["Д-р Стоян Лазаров", "Д-р Ивайло Митев", "Д-р Георги Константинов", "Д-р Венцислав Бошнаков"]

class EventDetailsDialog extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      customPeriodDialogOpen: false,
      id: this.props.id,
      name: this.props.name,
      personId: this.props.personId,
      phoneNumber: this.props.phoneNumber,
      reportedBy: this.props.reportedBy,
      reportedOn: this.props.reportedOn,
      diagnose: this.props.diagnose,
      caseType: this.props.caseType,
      operatorName: this.props.operatorName,
      operationHistoryInfo: this.props.operationHistoryInfo,
      operationHistoryDescription: this.props.operationHistoryDescription,
      previousICE: this.props.previousICE,
      remark: this.props.remark,
      plannedFor: this.props.plannedFor,
      sentDate: this.props.sentDate,
      approvedDate: this.props.approvedDate
    }
  }

  onChanged(key, value) {
    this.setState({
      [key]: value
    })
  }

  onPeriodChanged(value) {
    if (value == 4) {
      this.onChanged('customPeriodDialogOpen', true)
    } else {
      const plannedFor = new Date();
      
      if (value == 0) {
        plannedFor.setMonth(plannedFor.getMonth() + 1);
      } else if (value == 1) {
        plannedFor.setMonth(plannedFor.getMonth() + 3);
      } else if (value == 2) {
        plannedFor.setMonth(plannedFor.getMonth() + 6);
      } else if (value == 3) {
        plannedFor.setMonth(plannedFor.getMonth() + 12);
      } 

      this.onChanged('plannedFor', plannedFor.getTime())
    }
  }

  isDataFilled() {
    return this.state.name && this.state.personId && this.state.personId.length == 10 &&
      this.state.reportedBy && this.state.diagnose && this.state.plannedFor;
  }

  render() {
    let periodInput = null

    if (this.state.plannedFor) {
      periodInput = (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker disableToolbar
            variant="inline" margin="normal" label="Срок" 
            format="dd/MM/yyyy" value={new Date(this.state.plannedFor)}
            onChange={(newValue) => this.onChanged("date", newValue?.getTime())}
          />
        </MuiPickersUtilsProvider>
      )
    } else {
      periodInput = (
        <FormControl style={{ width: "100%", minWidth: 100 }}>
          <InputLabel id="periodType-select-label" style={{ marginLeft: 15 }}>Срок</InputLabel>
          <Select style={{ width: "100%" }} variant="outlined" 
            value={""}
            labelId="periodType-select-label"
            onChange={(event) => this.onPeriodChanged(event.target.value)}
          >
            {
              periodTypes.map((item, index) => (
                <MenuItem key={item} value={index}>{item}</MenuItem>
              ))
            }
          </Select>
        </FormControl>
      )
    }

    return (
      <>
        <Dialog onClose={() => this.props.onClose(-1, -1)} open={true} maxWidth={'lg'}>
          <DialogTitle>Детайли</DialogTitle>
          <DialogContent>
            <Grid container justify="space-around" alignItems="center" spacing={3} >
              <Grid item xs={12}>
                <TextField value={this.state.name || ""} label="Име" fullWidth 
                  onChange={(event) => this.onChanged("name", event.target.value)}/>
              </Grid>

              <Grid item xs={6}>
                <TextField value={this.state.personId || ""} label="ЕГН" fullWidth
                  onChange={(event) => this.onChanged("personId", event.target.value)} />
              </Grid>
              <Grid item xs={6}>
                <TextField value={this.state.phoneNumber || ""} label="Телефонен номер" fullWidth 
                  onChange={(event) => this.onChanged("phoneNumber", event.target.value)}/>
              </Grid>

              <Grid item xs={6}>
                <TextField value={this.state.reportedBy || ""} label="Докладвано от" fullWidth 
                  onChange={(event) => this.onChanged("reportedBy", event.target.value)}/>
              </Grid>
              <Grid item xs={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker disableToolbar
                    variant="inline" margin="normal" label="Докладвано на" inputVariant="outlined"
                    format="dd/MM/yyyy" value={(this.state.reportedOn && new Date(this.state.reportedOn)) || null}
                    onChange={(newValue) => this.onChanged("reportedOn", newValue?.getTime())}
                  />
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item xs={6}>
                <FormControl style={{ width: "100%", minWidth: 100 }}>
                  <InputLabel id="operator-select-label" style={{ marginLeft: 15 }}>Оператор</InputLabel>
                  <Select style={{ width: "100%" }} variant="outlined" 
                    value={this.state.operatorName && operators.indexOf(this.state.operatorName)}
                    labelId="operator-select-label"
                    onChange={(event) => this.onChanged("operatorName", operators[event.target.value])}
                  >
                    {
                      operators.map((item, index) => (
                        <MenuItem key={item} value={index}>{item}</MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker disableToolbar
                    variant="inline" margin="normal" label="ИКИ" inputVariant="outlined"
                    format="dd/MM/yyyy" value={(this.state.previousICE && new Date(this.state.previousICE)) || null}
                    onChange={(newValue) => this.onChanged("previousICE", newValue?.getTime())}
                  />
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item xs={12}>
                <ToggleButtonGroup exclusive value={this.state.operationHistoryInfo || ""} 
                  onChange={(event, newValue) => this.onChanged("operationHistoryInfo", newValue)}>
                  <ToggleButton value={'native'}>
                    {'Нативно'}
                  </ToggleButton>
                  <ToggleButton value={'operated'}>
                    {'Реоперация'}
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              {this.state.operationHistoryInfo === 'operated' && 
                <Grid item xs={12}>
                  <TextField style={{ width: "100%" }} label="Допълнителна информация" variant="outlined" 
                    value={this.state.operationHistoryDescription} multiline rows={2}
                    onChange={(event) => this.onChanged("operationHistoryDescription", event.target.value)}/>
                </Grid>
              } 

              <Grid item xs={12}>
                <DiagnoseInput diagnosesIds={this.state.diagnose} diagnoses={this.props.diagnoses}
                  onChanged={diagnosesIds => this.onChanged("diagnose", diagnosesIds)} />
              </Grid>

              <Grid item xs={12}>
                <CaseType caseTypes={this.props.caseTypes} selectedCases={this.state.caseType?.selectedCases} 
                  onChanged={(key, value) => this.onChanged('caseType', {selectedCases: value})} />              
              </Grid>

              <Grid item xs={12}>
                <TextField value={this.state.remark || ""} label="Забележка" fullWidth 
                  onChange={(event) => this.onChanged("remark", event.target.value)}/>
              </Grid>

              <Grid item xs={6}>
                {periodInput}
              </Grid>
              <Grid item xs={6} />

              <Grid item xs={3}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker disableToolbar
                    variant="inline" margin="normal" label="Подадено в НЗОК" inputVariant="outlined"
                    format="dd/MM/yyyy" value={(this.state.sentDate && new Date(this.state.sentDate)) || null}
                    onChange={(newValue) => this.onChanged("sentDate", newValue?.getTime())}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={3}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker disableToolbar
                    variant="inline" margin="normal" label="Одобрено от НЗОК" inputVariant="outlined"
                    format="dd/MM/yyyy" value={(this.state.approvedDate && new Date(this.state.approvedDate)) || null}
                    onChange={(newValue) => this.onChanged("approvedDate", newValue?.getTime())}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={6} />
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.props.onClose()} color="primary">
              Затвори
            </Button>
            <Button disabled={!this.isDataFilled()} color="primary"
              onClick={() => this.props.onClose({
                id: this.state.id,
                name: this.state.name,
                personId: this.state.personId,
                phoneNumber: this.state.phoneNumber,
                reportedBy: this.state.reportedBy,
                reportedOn: this.state.reportedOn,
                diagnose: this.state.diagnose,
                caseType: this.state.caseType,
                previousICE: this.state.previousICE,
                operatorName: this.state.operatorName,
                operationHistoryInfo: this.state.operationHistoryInfo,
                operationHistoryDescription: this.state.operationHistoryDescription,
                remark: this.state.remark,
                plannedFor: this.state.plannedFor,
                sentDate: this.state.sentDate,
                approvedDate: this.state.approvedDate
              })}>
              OK
            </Button>
          </DialogActions>
        </Dialog>
        
        {this.customPeriodDialogOpen && (
          <DateSelectDialog label='' onClose={date => this.onChanged('plannedFor', date)}/>
        )}
      </>
    )
  }
}

EventDetailsDialog.propTypes = {
  onClose: PropTypes.func.isRequired,

  diagnoses: PropTypes.object.isRequired,
  caseTypes: PropTypes.object.isRequired,
  
  id: PropTypes.string,
  name: PropTypes.string,
  personId: PropTypes.string,
  phoneNumber: PropTypes.string,
  reportedBy: PropTypes.string,
  reportedOn: PropTypes.number,
  diagnose: PropTypes.array,
  caseType: PropTypes.object,
  remark: PropTypes.string,
  operatorName: PropTypes.string,
  operationHistoryInfo: PropTypes.bool, 
  operationHistoryDescription: PropTypes.string,
  previousICE: PropTypes.string,
  plannedFor: PropTypes.number,
  sentDate: PropTypes.number,
  approvedDate: PropTypes.number
}

class SurgicalPendingTable extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      addPendingDialogOpen: false,
      planPendingDialogOpen: false,
      pendingToEdit: null,
      pending: this.props.pending,
      page: 0,
      rowsPerPage: 10
    }
  }

  onChanged(key, value) {
    this.setState({
      [key]: value
    })
  }

  getTitle(id, treeData) {
    return TreeViewComponent.getNode(treeData, false, id).title;
  }

  getPeriod(periodType) {
    return periodTypes[periodType];    
  }

  getOperationHistory(data) {
    if (!data || !data.operationHistoryInfo) {
      return ""
    }

    let result

    if (data.operationHistoryInfo === 'operated') {
      result = 'оперирано'

      if (data.operationHistoryDescription) {
        result += ', ' + data.operationHistoryDescription
      }
    } else {
      result = 'нативно'
    }

    return result
  }

  ageToString(age) {
    if (age) {
      return (age.years ? age.years + "г " : "") + 
             (age.months ? age.months + "м " : "") + 
             (age.days ? age.days + "д " : "");
    } else {
      return "";
    }
  }

  onAddPending(data) {
    if (data) {
      console.log(data)
      addSurgicalPending(data, () => this.props.onChanged())
    }

    this.setState({
      addPendingDialogOpen: false,
      pendingToEdit: null
    })
  }

  onDeletePending(data) {
    if (data) {
      deleteSurgicalPending(data.id, () => this.props.onChanged())
    }
  }

  onEditPending(data) {
    this.setState({
      addPendingDialogOpen: true,
      pendingToEdit: data
    })
  }

  onPlanPending(data) {
    this.setState({
      planPendingDialogOpen: true,
      pendingToEdit: data
    })
  }

  onQuickSearchPerformed(search) {
    if (search) {
      this.setState({
        search: search,
        page: 0,
        pending: this.props.pending.filter(pending => pending.name.toLowerCase().includes(search.toLowerCase()) || pending.personId.includes(search))
      })
    } else {
      this.setState({
        search: search,
        page: 0,
        pending: this.props.pending
      })
    }
  }

  planningFinished(data, date) {
    data.plannedFor = date
    this.setState({
      planPendingDialogOpen: false,
      pendingToEdit: null
    })
    this.onAddPending(data)
  }

  handleChangePage(newPage) {
    this.setState({
      page: newPage
    })
  }

  handleChangeRowsPerPage(newRowsPerPage) {
    this.setState({
      page: 0,
      rowsPerPage: newRowsPerPage
    })
  }

  getTableRowStyle(data) {
    if (!data) {
      return { backgroundColor: 'white' }
    } 

    if (data?.caseType?.selectedCases && data?.caseType?.selectedCases[0]?.caseId.startsWith('1|')) {
      return { backgroundColor: 'lightgreen' } 
    } else {
      return { backgroundColor: 'yellow' }
    }
  }

  render () {
    let pending = this.state.pending

    if (!pending || pending.length == 0) {
      pending = [ null ]
    }

    const tableRows = 
      pending
        .slice(this.state.page * this.state.rowsPerPage, (this.state.page + 1) * this.state.rowsPerPage)
        .map((data) => {
          return (
            <TableRow hover role="checkbox" tabIndex={-1} key={data?.personId} style={this.getTableRowStyle(data)}>
              <TableCell key="name" align="center">
                {(data && data.name) || ""}
              </TableCell>
              <TableCell key="age" align="center">
                {(data && data.personId && this.ageToString(calculateAge(data.personId, Date.now()))) || ""}
              </TableCell>
              <TableCell key="phoneNumber" align="center">
                {(data && data.phoneNumber) || ""}
              </TableCell>
              <TableCell key="diagnose" align="center">
                {(data && data.diagnose && data.diagnose.map(diagnose => this.getTitle(diagnose, this.props.diagnoses)).join(' | ')) || ""}
              </TableCell>
              <TableCell key="caseType" align="center">
                {(data && data.caseType && data.caseType.selectedCases && 
                  data.caseType.selectedCases.map(selected => this.getTitle(selected.caseId, this.props.caseTypes)).join(' | ')) || ""}
              </TableCell>
              <TableCell key="operationInfo" align="center">
                {this.getOperationHistory(data)}
              </TableCell>
              <TableCell key="previousICE" align="center">
                {(data && data.previousICE && new Date(data.previousICE).toLocaleDateString('bg-BG')) || ""}
              </TableCell>
              <TableCell key="remark" align="center">
                {(data && data.remark) || ""}
              </TableCell>
              <TableCell key="notAfter" align="center">
                {(data && data.plannedFor && new Date(data.plannedFor).toLocaleDateString('bg-BG')) || ""}
              </TableCell>
              <TableCell key="plan" align="center">
                <IconButton onClick={() => data && this.onPlanPending(data)}>
                  <EventIcon opacity={data ? '100%' : '0%'}/>
                </IconButton>
              </TableCell>
              <TableCell key="edit" align="center">
                <IconButton onClick={() => data && this.onEditPending(data)}>
                  <EditIcon opacity={data ? '100%' : '0%'}/>
                </IconButton>
              </TableCell>
              <TableCell key="delete" align="center">
                <IconButton onClick={() => data && this.onDeletePending(data)}>
                  <DeleteIcon opacity={data ? '100%' : '0%'}/>
                </IconButton>
              </TableCell>
            </TableRow>
          )
        }) 
    
    return (
      <>
        <Fab variant="extended" 
          style={{
            margin: 0,
            top: 60,
            right: 50,
            bottom: 'auto',
            left: 'auto',
            position: 'fixed',
            zIndex: 100
        }}
          onClick={() => this.onChanged('addPendingDialogOpen', true)}>
          Добави
        </Fab>

        {this.state.addPendingDialogOpen && (
          <EventDetailsDialog caseTypes={this.props.caseTypes} diagnoses={this.props.diagnoses} onClose={data => this.onAddPending(data)} {...(this.state.pendingToEdit || {})}/>
        )}

        {this.state.planPendingDialogOpen && (
          <DateSelectDialog onClose={timestamp => this.planningFinished(this.state.pendingToEdit, timestamp)} />
        )}

        <TableContainer component={Paper} style={{ maxHeight: 350, minHeight: 350 }}>
          <Table size='small' stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    width={column.width}
                  >
                    {column.label}
                  </TableCell>
                ))}

                <TableCell
                  key='search'
                  align='center'
                  width='15%'
                  colSpan={3}
                >
                  <TextField style={{ width: "100%" }} value={this.state.search || ""} label="Търсене"
                    onChange={(event) => this.onQuickSearchPerformed(event.target.value)}/>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableRows}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={this.state.pending?.length || 0}
          labelRowsPerPage="Редове на страница"
          rowsPerPage={this.state.rowsPerPage}
          page={this.state.page}
          onChangePage={(event, newPage) => this.handleChangePage(newPage)}
          onChangeRowsPerPage={(event) => this.handleChangeRowsPerPage(event.target.value)}
        />
      </>
    )
  }
}

SurgicalPendingTable.propTypes = {
  onChanged: PropTypes.func.isRequired,
  caseTypes: PropTypes.object.isRequired,
  diagnoses: PropTypes.object.isRequired,
  pending: PropTypes.array.isRequired
}

export default SurgicalPendingTable;
