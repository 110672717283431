import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import BooleanResultToggleButtons from './BooleanResultToggleButtons';

class TitledBooleanResultToggleButtons extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Grid container justify="space-around" alignItems="center" spacing={3}>
        <Grid item xs={12}>
          {this.props.title}
        </Grid>
        <Grid item xs={12}>
          <BooleanResultToggleButtons {...this.props} />
        </Grid>
      </Grid>
    )
  }
}

TitledBooleanResultToggleButtons.propTypes = {
  title: PropTypes.string.isRequired
}

export default TitledBooleanResultToggleButtons;
