import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Fab, Snackbar, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SaveIcon from '@material-ui/icons/Save';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AssignmentIcon from '@material-ui/icons/Assignment';
import MuiAlert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';
import { calculateAge } from '../util/Formulas'

import { ProceduralEfficacy } from './procefficacy/ProceduralEfficacy';
import { GeneralInformation } from './generalinfo/GeneralInformation';
import { PhysicalInformation } from './physicalinfo/PhysicalInformation';
import { ProcedureCharacteristics } from './proccharacteristics/ProcedureCharacteristics';
import { Hemodynamics } from './hemodynamics/Hemodynamics';
import { CaseType } from './casetype/CaseType';
import { AdverseEvents } from './adverseevents/AdverseEvents';
import { Protocol } from './protocol/Protocol';
import { Remarks } from './remarks/Remarks';
import { Gallery } from './gallery/Gallery';

class LinkInformation {
  constructor(title, Component, groupId) {
    this.title = title;
    this.Component = Component;
    this.groupId = groupId;
  }
}

const LINKS = [
  new LinkInformation('Обща Информация', GeneralInformation, "generalInformation"),
  new LinkInformation('Антропометрични Показатели', PhysicalInformation, "physicalInformation"),
  new LinkInformation('Процедурни Характеристики', ProcedureCharacteristics, "procedureCharacteristics"),
  new LinkInformation('Хемодинамика', Hemodynamics, "hemodynamics"),
  new LinkInformation('Вид Процедура', CaseType, "caseType"),
  new LinkInformation('Ефективност на Процедурата', ProceduralEfficacy, "proceduralEfficacy"),
  new LinkInformation('Усложнения', AdverseEvents, "adverseEvents"),
  new LinkInformation('Протокол', Protocol, "protocol"),
  new LinkInformation('Забележки', Remarks, "remarks"),
  new LinkInformation('Галерия', Gallery, "gallery")
];

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class CaseInformation extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      id: this.props.caseInformation && this.props.caseInformation.id,
      generalInformation: (this.props.caseInformation && this.props.caseInformation.generalInformation) || {},
      physicalInformation: (this.props.caseInformation && this.props.caseInformation.physicalInformation) || {},
      procedureCharacteristics: (this.props.caseInformation && this.props.caseInformation.procedureCharacteristics) || {},
      hemodynamics: (this.props.caseInformation && this.props.caseInformation.hemodynamics) || {},
      caseType: (this.props.caseInformation && this.props.caseInformation.caseType) || {},
      proceduralEfficacy: (this.props.caseInformation && this.props.caseInformation.proceduralEfficacy) || {},
      adverseEvents: (this.props.caseInformation && this.props.caseInformation.adverseEvents) || {},
      protocol: (this.props.caseInformation && this.props.caseInformation.protocol) || {},
      remarks: (this.props.caseInformation && this.props.caseInformation.remarks) || {},
      gallery: (this.props.caseInformation && this.props.caseInformation.gallery) || {},
      showAlert: false,
      alertMessage: ''
    }

    if (!this.state.generalInformation) {
      this.state.generalInformation = { date: new Date().getTime() }
    } else if (!this.state.generalInformation.date) {
      this.state.generalInformation.date = new Date().getTime()
    }

    this.onCaseInformationChanged = this.onCaseInformationChanged.bind(this);
  }

  onCaseInformationChanged(key, value, path) {
    this.setState(function(state) {
      const group = path.shift();
      let groupInformation = { ...state[group] };
      let node = groupInformation;

      for (const pathPart of path) {
        let dataPart = node[pathPart];

        if (!dataPart) {
          dataPart = {};
        } else {
          dataPart = { ...dataPart };
        }

        node[pathPart] = dataPart;
        node = dataPart;
      }

      node[key] = value;

      console.log(state)

      return {
        [group]: groupInformation
      }
    })
  }

  onCaseSave(result) {
    if (result.id) {
      this.onIdChanged(result.id)
    } else {
      this.showAlert(result.message)
    }
  }

  onIdChanged(id) {
    this.setState({
      id: id
    })
  }

  showAlert(errorMessage) {
    this.setState({
      showAlert: true,
      alertMessage: errorMessage
    })
  }

  hideAlert(event, reason) {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({
      showAlert: false
    })
  }

  onSave(caseInformation) {
    this.props.addCase(caseInformation, data => this.onCaseSave(data));
  }

  isEmpty(obj) {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  }

  getDataForComponent(groupId) {
    const groupInformation = this.state[groupId] || {};

    if (groupId === "hemodynamics") {
      const physicalInformation = this.state['physicalInformation'] || {};
      groupInformation['weight'] = physicalInformation.weight;
      groupInformation['height'] = physicalInformation.height;

      const generalInformation = this.state['generalInformation'] || {};
      groupInformation['age'] = calculateAge(generalInformation.personId, generalInformation.date);
    } else if (groupId === 'physicalInformation') {
      const generalInformation = this.state['generalInformation'] || {};
      groupInformation['age'] = calculateAge(generalInformation.personId, generalInformation.date);
    } else if (groupId === 'procedureCharacteristics') {
      const physicalInformation = this.state['physicalInformation'] || {};
      groupInformation['weight'] = physicalInformation.weight;
    }

    return groupInformation;
  }

  compileCaseInformation() {
    return {
      id: this.state.id,
      generalInformation: this.state.generalInformation,
      physicalInformation: this.state.physicalInformation,
      procedureCharacteristics: this.state.procedureCharacteristics,
      hemodynamics: this.state.hemodynamics,
      caseType: this.state.caseType,
      proceduralEfficacy: this.state.proceduralEfficacy,
      adverseEvents: this.state.adverseEvents,
      protocol: this.state.protocol,
      remarks: this.state.remarks,
      gallery: this.state.gallery
    }
  }

  onExportCurrent(caseInfo) {
    this.props.getCurrentCaseProtocol(caseInfo, (blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'protocol.pdf');
      
      document.body.appendChild(link);
      
      link.click();
      
      link.parentNode.removeChild(link)
    })
  }

  render () {
    return (
      <>
        {this.props.onBackPerformed && (
          <Fab variant="extended" 
            style={{
              margin: 0,
              top: 60,
              right: 380,
              bottom: 'auto',
              left: 'auto',
              position: 'fixed',
              zIndex: 100
          }}
            onClick={() => this.props.onBackPerformed()}>
            <ArrowBackIcon />
            Назад
          </Fab>
        )}
        <Fab variant="extended" 
          style={{
            margin: 0,
            top: 60,
            right: 250,
            bottom: 'auto',
            left: 'auto',
            position: 'fixed',
            zIndex: 100
        }}
          onClick={() => this.onSave(this.compileCaseInformation())}>
          <SaveIcon />
          Запази
        </Fab>

        <Fab variant="extended" 
          style={{
            margin: 0,
            top: 60,
            right: 100,
            bottom: 'auto',
            left: 'auto',
            position: 'fixed',
            zIndex: 100
        }}
          onClick={() => this.onExportCurrent(this.compileCaseInformation())}>
          <AssignmentIcon />
          Протокол
        </Fab>

        {
          LINKS.map((info, index) => (
            <AccordionEntry key={info.title} title={info.title} defaultExpanded={index === 0}>
              <info.Component {...(this.getDataForComponent(info.groupId))} 
                dataGroupId={info.groupId}
                onChanged={this.onCaseInformationChanged}/>
            </AccordionEntry>
          ))
        }

        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={this.state.showAlert} 
          onClose={(event, reason) => this.hideAlert(event, reason)}>
          <Alert onClose={(event, reason) => this.hideAlert(event, reason)} severity="error">
            {this.state.alertMessage}
          </Alert>
        </Snackbar>
      </>
    )
  }
}

CaseInformation.propTypes = {
  caseInformation: PropTypes.node,
  addCase: PropTypes.func,
  getCurrentCaseProtocol: PropTypes.func,
  onBackPerformed: PropTypes.func
}

class AccordionEntry extends React.PureComponent {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Accordion defaultExpanded={this.props.defaultExpanded || false}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography variant="h6" component="h6">{this.props.title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {this.props.children}
        </AccordionDetails>
      </Accordion>
    );
  }
}

AccordionEntry.propTypes = {
  title: PropTypes.string.isRequired,
  defaultExpanded: PropTypes.bool,
  children: PropTypes.node
}

export default CaseInformation;
