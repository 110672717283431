import React from 'react';
import PropTypes from 'prop-types';
import { Grid, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

const OPERATORS = [
  'проф. Анна Кънева',
  'д-р Любомир Димитров',
  'д-р Елисавета Левунлиева',
  'д-р Кипарисия Ненова',
  'д-р Симеон Шишков',
  'д-р Петя Парашкевова'
]

const ANESTHESISTS = [
  'д-р Здравко Беров',
  'д-р Йоанна Генчева'
]

export class Protocol extends React.PureComponent {

  constructor(props) {
    super(props);
  }

  onChanged(key, value, path = []) {
    path.unshift(this.props.dataGroupId);
    this.props.onChanged(key, value, path);
  }

  getCombo(options, propId, propLabel) {
    return <Autocomplete
      id={propId}
      onChange={(event) => this.onChanged(propId, event.target.textContent)}
      freeSolo
      options={options}
      value={this.props[propId]}
      renderInput={(params) => 
        <TextField {...params} variant="outlined" value={this.props[propId]} label={propLabel}
          style={{ width: "100%" }}
          onChange={(event) => this.onChanged(propId, event.target.value)}/>
      }
    />
  }

  render () {
    return (
      <Grid container justify="space-around" alignItems="center" spacing={3}>
        <Grid item xs={6}>
          {this.getCombo(OPERATORS, 'firstOperator', 'Първи оператор')}
        </Grid>
        <Grid item xs={6}>
          {this.getCombo(ANESTHESISTS, 'anesthesiolog', 'Анестезиолог')}
        </Grid>
        <Grid item xs={6}>
          {this.getCombo(OPERATORS, 'secondOperator', 'Втори оператор')}
        </Grid>
        <Grid item xs={6}>
          <TextField variant="outlined" value={this.props.echographist} label="Ехографист"
            style={{ width: "100%" }}
            onChange={(event) => this.onChanged("echographist", event.target.value)}/>
        </Grid>
        <Grid item xs={6}>
          {this.getCombo(OPERATORS, 'thirdOperator', 'Трети оператор')}
        </Grid>
        <Grid item xs={6}>
          {this.getCombo(OPERATORS, 'preparedBy', 'Изготвил')}
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={12}>
          <TextField variant="outlined" value={this.props.protocol} label="Протокол"
            multiline rows={30}
            style={{ width: "100%" }}
            onChange={(event) => this.onChanged("protocol", event.target.value)}/>
        </Grid>
        <Grid item xs={12}>
          <TextField variant="outlined" value={this.props.conclusion} label="Заключение"
            multiline rows={10}
            style={{ width: "100%" }}
            onChange={(event) => this.onChanged("conclusion", event.target.value)}/>
        </Grid>
      </Grid>
    );
  }
}

Protocol.propTypes = {
  dataGroupId: PropTypes.string.isRequired,
  onChanged: PropTypes.func.isRequired,
  
  protocol: PropTypes.string,
  firstOperator: PropTypes.string,
  secondOperator: PropTypes.string,
  thirdOperator: PropTypes.string,
  anesthesiolog: PropTypes.string,
  echographist: PropTypes.string,
  conclusion: PropTypes.string,
}
