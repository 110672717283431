import { Grid, IconButton, List, Paper } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types'
import { PureComponent } from 'react';
import TreeViewComponent from '../util/TreeViewComponent';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const daysOfWeek = ['Понеделник', 'Вторник', 'Сряда', 'Четвъртък', 'Петък', 'Събота', 'Неделя']

class PlannedCase extends PureComponent {
  constructor(props) {
    super(props);
  }

  render () {
    const paperStyle = this.props.local ? 
      { margin: '5px 5px 5px 5px', backgroundColor: 'darkgray' } :
      { margin: '5px 5px 5px 5px', backgroundColor: 'pink' }

    return (
      <Paper style={paperStyle}>
        <h4 style={{ textAlign:'center' }}>{this.props.name}</h4>
        <h4 style={{ textAlign:'center' }}>{this.props.caseType}</h4>
      </Paper>
    )
  }
}

PlannedCase.propTypes = {
  name: PropTypes.string.isRequired,
  caseType: PropTypes.string.isRequired,
  local: PropTypes.bool.isRequired
}

class DailyPlan extends PureComponent {

  constructor(props) {
    super(props);
  }

  isPlannedForToday(pendingCase) {
    return pendingCase && pendingCase.plannedFor && 
      pendingCase.plannedFor >= this.props.periodStart && 
      pendingCase.plannedFor < this.props.periodEnd
  }

  getTitle(id, treeData) {
    return TreeViewComponent.getNode(treeData, false, id)?.title;
  }

  getCaseType(pendingCase) {
    const selectedCases = pendingCase?.caseType?.selectedCases;
    return selectedCases && selectedCases.length > 0 && this.getTitle(selectedCases[0].caseId, this.props.caseTypes)
  }

  render () {
    return (
      <Grid item xs>
        <Paper>
          <h2 style={{textAlign:'center'}}>{this.props.label}</h2>
          <h3 style={{textAlign:'center'}}>{this.props.periodStart.toLocaleDateString('bg-BG')}</h3>
          <List style={{ height: 500, overflow: "auto" }}>
            {
              (this.props.pending || [])
                .filter(pending => this.isPlannedForToday(pending))
                .map((pending) => (
                  <PlannedCase key={pending.personId} 
                    local={pending.local}
                    name={pending.name} 
                    caseType={this.getCaseType(pending)} />
              ))
            }
          </List>
        </Paper>
      </Grid>
    )
  }
}

DailyPlan.propTypes = {
  caseTypes: PropTypes.object.isRequired,
  pending: PropTypes.array.isRequired,
  periodStart: PropTypes.object.isRequired,
  periodEnd: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired
}

class WeeklyPlan extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      periodStart: this.calculateDefaultPeriodStart()
    }
  }

  calculateDefaultPeriodStart() {
    const mondayForCurrentWeek = this.getMondayForWeekOf(new Date())
    mondayForCurrentWeek.setHours(7 * 24)
    return mondayForCurrentWeek
  }

  calculatePeriodStartForDay(index) {
    const periodStart = new Date(this.state.periodStart)
    periodStart.setHours(index * 24)
    return periodStart
  }

  calculatePeriodEndForDay(index) {
    const periodStart = new Date(this.state.periodStart)
    periodStart.setHours((index + 1) * 24)
    return periodStart
  }

  getMondayForWeekOf(date) {
    var day = date.getDay() || 7 
    if (day !== 1) {
      date.setHours(-24 * (day - 1), 0, 0, 0) 
    } else {
      date.setHours(date.getHours(), 0, 0, 0)
    }
    return date;
  }

  nextWeek() {
    const periodStart = new Date(this.state.periodStart)
    periodStart.setHours(7 * 24)
    this.setState({
      periodStart: periodStart
    }) 
  }

  previousWeek() {
    const periodStart = new Date(this.state.periodStart)
    periodStart.setHours(-7 * 24)
    this.setState({
      periodStart: periodStart
    }) 
  }

  render () {
    return (
      <Paper height='50%'>
        <IconButton onClick={() => this.previousWeek()}>
          <ArrowBackIosIcon />
        </IconButton>
        <IconButton onClick={() => this.nextWeek()}>
          <ArrowForwardIosIcon />
        </IconButton>
        <Grid container justify="center" alignItems="center" spacing={0}>
          {daysOfWeek.map((day, index) => {
            return (
              <DailyPlan key={index} label={day} 
                periodStart={this.calculatePeriodStartForDay(index)}
                periodEnd={this.calculatePeriodEndForDay(index)}
                pending={this.props.pending}
                caseTypes={this.props.caseTypes} />
            )
          })}
        </Grid>
      </Paper>
    )
  }
}

WeeklyPlan.propTypes = {
  caseTypes: PropTypes.object.isRequired,
  diagnoses: PropTypes.object.isRequired,
  pending: PropTypes.array.isRequired
}

export default WeeklyPlan;
