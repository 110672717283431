import React from 'react';
import PropTypes from 'prop-types';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@material-ui/core';
import TreeViewComponent from '../../util/TreeViewComponent';
import { recordsToTree } from '../../util/TreeDataUtils';
import { listDiagnoses } from '../../rest/RESTUtils';
import { DiagnoseInput } from './DiagnoseInput';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

class DiagnoseChoiceDialog extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      selected: []
    }
  }

  onNodeSelected(nodeIds) {
    this.setState(function(state) {
      const selected = state.selected;
      
      if (TreeViewComponent.isLeafNode(this.props.diagnoses, false, nodeIds[0]) && selected.indexOf(nodeIds[0]) == -1) {
        selected.push(nodeIds[0]);
      }

      return {
        selected: selected
      }
    })
  }

  render() {
    return (
      <Dialog onClose={() => this.props.onClose(null)} open={true} width={500} fullWidth>
        <DialogTitle>Детайли</DialogTitle>
        <DialogContent>
          <TreeViewComponent multiSelect={true} root={this.props.diagnoses} showRootNode={false} 
            onNodeSelect={(nodeIds) => this.onNodeSelected(nodeIds)} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.props.onClose(null)} color="primary">
            Затвори
          </Button>
          <Button disabled={this.state.level == -1 || this.state.timeToManage == -1} color="primary"
            onClick={() => this.props.onClose(this.state.selected)}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

DiagnoseChoiceDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  diagnoses: PropTypes.object.isRequired
}

export class GeneralInformation extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      birthDate: this.getBirthDate(this.props.personId)
    }
  }

  componentDidMount() {
    listDiagnoses((diagnoses) => {
      this.setState({ 
        diagnoses: recordsToTree(diagnoses)
      })
    })
  }

  onChanged(key, value, path = []) {
    path.unshift(this.props.dataGroupId);
    this.props.onChanged(key, value, path);
  }

  onStateChanged(key, value) {
    this.setState({
      [key]: value
    })
  }

  onDiagnosisChanged(diagnosisIds) {
    this.onChanged("diagnosisIds", diagnosisIds || []);
  }

  onBirthDateChanged(birthDate) {
    this.setState({
      birthDate: birthDate
    })
    this.onChanged('personId', this.getPersonId(birthDate))
  }

  onPersonIdChanged(personId) {
    this.setState({
      birthDate: this.getBirthDate(personId)
    })
    this.onChanged('personId', personId)
  }

  isPersonIdValid(personId) {
    return personId && personId.length === 10 && !personId.endsWith('0000');
  }

  getBirthDate(personId) {
    if (personId && personId.length >= 6) {
      let year = parseInt(personId.substring(0, 2))
      let month = parseInt(personId.substring(2, 4))
      let day = personId.substring(4, 6)

      if (month > 40) {
        year += 2000;
        month -= 40;
      } else if (month > 20) {
        year += 1800;
        month -= 20;
      } else {
        year += 1900;
      }

      return day + '.' + month.toString().padStart(2, '0') + '.' + year.toString()
    } else {
      return ''
    }
  }

  getPersonId(birthDate) {
    if (birthDate && birthDate.length === 10) {
      let day = birthDate.substring(0, 2)
      let month = parseInt(birthDate.substring(3, 5))
      let year = parseInt(birthDate.substring(6, 10))

      if (year >= 2000) {
        year -= 2000
        month += 40
      } else if (year < 1900) {
        year -= 1800
        month += 20
      } else {
        year -= 1900
      }

      return year.toString() + month.toString().padStart(2, '0') + day + '0000'
    } else {
      return ''
    }
  }

  getValidPersonId(personId) {
    if (personId && !personId.endsWith('0000')) {
      return personId
    } else {
      return ''
    }
  }

  render () {
    return (
      <>
        { this.state.diagnoses && (
          <Grid container justify="space-around" alignItems="center" spacing={3}>
            <Grid item xs={3}>
              <TextField style={{ width: "100%" }} value={this.props.name || ""} label="Име"
                onChange={(event) => this.onChanged("name", event.target.value)}/>
            </Grid>
            <Grid item xs={3}>
              <TextField style={{ width: "100%" }} value={this.getValidPersonId(this.props.personId)} label="ЕГН"
                onChange={(event) => this.onPersonIdChanged(event.target.value)}/>
            </Grid>
            <Grid item xs={3}>
              <TextField style={{ width: "100%" }} value={this.state.birthDate} label="Дата на раждане"
                disabled={this.isPersonIdValid(this.props.personId)}
                onChange={(event) => this.onBirthDateChanged(event.target.value)}/>
            </Grid>
            <Grid item xs={3}/>

            <Grid item xs={3}>
              <TextField fullWidth value={this.props.patientId || ""} label="ИЗ"
                onChange={(event) => this.onChanged("patientId", event.target.value)}/>
            </Grid>

            <Grid item xs={3}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker disableToolbar
                  variant="inline" margin="normal" label="Дата" 
                  format="dd/MM/yyyy" value={(this.props.date && new Date(this.props.date)) || new Date()}
                  onChange={(newValue) => this.onChanged("date", newValue?.getTime())}
                />
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid item xs={6}/>

            <Grid item xs={6}>
              <DiagnoseInput diagnosesIds={this.props.diagnosisIds} diagnoses={this.state.diagnoses}
                onChanged={diagnosesIds => this.onDiagnosisChanged(diagnosesIds)} />
            </Grid>

            <Grid item xs={6}/>

            <Grid item xs={12}>
              <ToggleButtonGroup exclusive value={this.props.admissionType || ""} onChange={(event, newValue) => this.onChanged("admissionType", newValue)}>
                <ToggleButton value={'urgent'}>
                  {'Спешно'}
                </ToggleButton>
                <ToggleButton value={'planned'}>
                  {'Планово'}
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Grid item xs={12}>
              <ToggleButtonGroup exclusive value={this.props.operationHistoryInfo || ""} onChange={(event, newValue) => this.onChanged("operationHistoryInfo", newValue)}>
                <ToggleButton value={'native'}>
                  {'Нативно'}
                </ToggleButton>
                <ToggleButton value={'operated'}>
                  {'Следоперативно / Следпроцедурно'}
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            {this.props.operationHistoryInfo === 'operated' && 
              <Grid item xs={12}>
                <TextField style={{ width: "100%" }} label="Допълнителна информация" variant="outlined" value={this.props.operationHistoryDescription} multiline rows={2}
                  onChange={(event) => this.onChanged("operationHistoryDescription", event.target.value)}/>
              </Grid>
            } 

          </Grid>
        )}
      </>
    );
  }
}

GeneralInformation.propTypes = {
  onChanged: PropTypes.func.isRequired,
  dataGroupId: PropTypes.string.isRequired,

  name: PropTypes.string,
  personId: PropTypes.string,
  patientId: PropTypes.string,
  date: PropTypes.object,
  diagnosisIds: PropTypes.array,
  admissionType: PropTypes.string,
  operationHistoryInfo: PropTypes.string,
  operationHistoryDescription: PropTypes.string
}