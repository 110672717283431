import React from 'react'
import PropTypes from 'prop-types'
import { InputAdornment, TextField } from '@material-ui/core'

class TextInput extends React.Component {

    constructor(props) {
      super(props)
    }
  
    render() {
      return (
        <TextField style={{ width: '100%' }} 
          required
          error={!this.props.disabled && this.props.required && !this.props.value}
          value={this.props.value || ''} 
          label={this.props.label || ''}
          variant={this.props.variant || 'outlined'}
          size={this.props.size}
          onChange={event => this.props.onValueChanged(event.target.value)}
          InputProps={this.props.adornment ? {
            endAdornment: <InputAdornment position='end'>{this.props.adornment}</InputAdornment>,
          } : {}}
          {...this.props}/>
      )
    }
  }
  
  TextInput.propTypes = {
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onValueChanged: PropTypes.func,
    variant: PropTypes.string,
    adornment: PropTypes.string,
    size: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool
  }

  TextInput.defaultProps = {
    required: false
  }

  export default TextInput