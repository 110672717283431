import { Box } from '@material-ui/core';
import React from 'react';
import { PropTypes } from 'prop-types'

class TabPanel extends React.Component {

    constructor(props) {
      super(props);
    }
  
    render() {
      return (
        <div
          role="tabpanel"
          hidden={this.props.value !== this.props.index}
        >
          {this.props.value === this.props.index && (
            <Box p={3}>
              {this.props.children}
            </Box>
          )}
        </div>
      );
    }
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  export default TabPanel;