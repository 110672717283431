import React from 'react'
import PropTypes from 'prop-types'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { Grid } from '@material-ui/core'

class MultipleChoiceOptions extends React.Component {

  constructor(props) {
    super(props)
  }

  isEmpty() {
    if (typeof this.props.value === 'number') {
      return false
    } else if (Array.isArray(this.props.value)) {
      return this.props.value?.length == 0
    } else {
      return true
    }
  }

  render() {
    return (
      <Grid container justify='space-around' alignItems='center' spacing={3}>
        <Grid item xs={12}>
          <div style={this.props.disabled ? { color: 'lightgrey' } : { color : 'black' }}>{this.props.title || ''}</div>
          <ToggleButtonGroup exclusive={this.props.exclusive} value={this.props.value} size={this.props.size}
            orientation={this.props.orientation}
            onChange={(event, newValue) => this.props.onValueChanged(newValue)}>
            {
              this.props.labels.map((label, index) => (
                <ToggleButton key={index} value={index} disabled={this.props.disabled}
                  style={!this.props.disabled && this.props.required && this.isEmpty() ? 
                    { 'text-transform': 'none', color:'red', borderColor:'red' }: 
                    { 'text-transform': 'none' }}>
                  {label}
                </ToggleButton>
              ))
            }
          </ToggleButtonGroup>
        </Grid>
      </Grid>
    )
  }
}

MultipleChoiceOptions.propTypes = {
  onValueChanged: PropTypes.func.isRequired,
  labels: PropTypes.array.isRequired,

  title: PropTypes.string,
  value: PropTypes.any,
  exclusive: PropTypes.bool,
  disabled: PropTypes.bool,

  lines: PropTypes.number,
  size: PropTypes.string,

  orientation: PropTypes.string,

  required: PropTypes.bool
}

MultipleChoiceOptions.defaultProps = {
  orientation: 'horizontal',
  required: false
}

export default MultipleChoiceOptions
