import intervalToDuration from 'date-fns/intervalToDuration'

export function calculateVdAp(props) {
  if (!props || !props.measurementsData) {
    return ''
  }

  const vd = props.measurementsData['vdOut']

  if (!vd || !vd['maxPressure']) {
    return ''
  }

  const ap = props.measurementsData['ap']

  if (!ap || !ap['maxPressure']) {
    return ''
  }

  return vd['maxPressure'] - ap['maxPressure']
}

export function calculateApAps(props) {
  if (!props || !props.measurementsData) {
    return ''
  }

  const ap = props.measurementsData['ap']

  if (!ap || !ap['maxPressure']) {
    return ''
  }

  const aps = props.measurementsData['aps']

  if (!aps || !aps['maxPressure']) {
    return ''
  }

  return ap['maxPressure'] - aps['maxPressure']
}

export function calculateApApd(props) {
  if (!props || !props.measurementsData) {
    return ''
  }

  const ap = props.measurementsData['ap']

  if (!ap || !ap['maxPressure']) {
    return ''
  }

  const apd = props.measurementsData['apd']

  if (!apd || !apd['maxPressure']) {
    return ''
  }

  return ap['maxPressure'] - apd['maxPressure']
}

export function calculateVsAo(props) {
  if (!props || !props.measurementsData) {
    return ''
  }

  const vs = props.measurementsData['vsOut']

  if (!vs || !vs['maxPressure']) {
    return ''
  }

  const aoAsc = props.measurementsData['aoAsc']

  if (!aoAsc || !aoAsc['maxPressure']) {
    return ''
  }

  return vs['maxPressure'] - aoAsc['maxPressure']
}

export function calculateAoascAodesc(props) {
  if (!props || !props.measurementsData) {
    return ''
  }

  const aoAsc = props.measurementsData['aoAsc']

  if (!aoAsc || !aoAsc['maxPressure']) {
    return ''
  }

  const aoDesc = props.measurementsData['aoDesc']

  if (!aoDesc || !aoDesc['maxPressure']) {
    return ''
  }

  return aoAsc['maxPressure'] - aoDesc['maxPressure']
}

export function calculateQp(props) {
  if (!props || !props.oxygenConsumption || !props.measurementsData || !props.hemoglobin) {
    return '';
  }

  const vps = props.measurementsData['vps'];

  if (!vps || !vps['oxygenSaturation']) {
    return '';
  }

  const ap = props.measurementsData['ap'];

  if (!ap || !ap['oxygenSaturation']) {
    return '';
  }

  if (!vps['oxygenPartialPressure'] || !ap['oxygenPartialPressure']) {  
    return props.oxygenConsumption / ((vps['oxygenSaturation'] - ap['oxygenSaturation']) * props.hemoglobin * 0.136);
  } else {
    return props.oxygenConsumption / 
      (
        (vps['oxygenSaturation'] * props.hemoglobin * 0.136 + 0.003 * vps['oxygenPartialPressure']) - 
        (ap['oxygenSaturation'] * props.hemoglobin * 0.136 + 0.003 * ap['oxygenPartialPressure'])
      );
  }
}

export function calculateQps(props) {
  if (!props || !props.oxygenConsumption || !props.measurementsData || !props.hemoglobin) {
    return '';
  }

  const vps = props.measurementsData['vps'];

  if (!vps || !vps['oxygenSaturation']) {
    return '';
  }

  const aps = props.measurementsData['aps'];

  if (!aps || !aps['oxygenSaturation']) {
    return '';
  }

  if (!vps['oxygenPartialPressure'] || !aps['oxygenPartialPressure']) { 
    return props.oxygenConsumption / ((vps['oxygenSaturation'] - aps['oxygenSaturation']) * props.hemoglobin * 0.136);
  } else {
    return props.oxygenConsumption / 
      (
        (vps['oxygenSaturation'] * props.hemoglobin * 0.136 + 0.003 * vps['oxygenPartialPressure']) - 
        (aps['oxygenSaturation'] * props.hemoglobin * 0.136 + 0.003 * aps['oxygenPartialPressure'])
      )
  }
}

export function calculateQpd(props) {
  if (!props || !props.oxygenConsumption || !props.measurementsData || !props.hemoglobin) {
    return '';
  }

  const vps = props.measurementsData['vps'];

  if (!vps || !vps['oxygenSaturation']) {
    return '';
  }

  const apd = props.measurementsData['apd'];

  if (!apd || !apd['oxygenSaturation']) {
    return '';
  }

  if (!vps['oxygenPartialPressure'] || !apd['oxygenPartialPressure']) {
    return props.oxygenConsumption / ((vps['oxygenSaturation'] - apd['oxygenSaturation']) * props.hemoglobin * 0.136);
  } else {
    return props.oxygenConsumption / 
      (
        (vps['oxygenSaturation'] * props.hemoglobin * 0.136 + 0.003 * vps['oxygenPartialPressure']) - 
        (apd['oxygenSaturation'] * props.hemoglobin * 0.136 + 0.003 * apd['oxygenPartialPressure'])
      )
  }
}

export function calculateQs(props) {
  if (!props || !props.oxygenConsumption || !props.measurementsData || !props.hemoglobin) {
    return ''
  }

  const aoAsc = props.measurementsData['aoAsc'];

  if (!aoAsc || !aoAsc['oxygenSaturation']) {
    return ''
  }

  const mvs = props.measurementsData['mvs'];

  if (!mvs || !mvs['oxygenSaturation']) {
    return ''
  }

  if (!aoAsc['oxygenPartialPressure'] || !mvs['oxygenPartialPressure']) {
    return props.oxygenConsumption / ((aoAsc['oxygenSaturation'] - mvs['oxygenSaturation']) * props.hemoglobin * 0.136);
  } else {
    return props.oxygenConsumption / 
      (
        (aoAsc['oxygenSaturation'] * props.hemoglobin * 0.136 + 0.003 * aoAsc['oxygenPartialPressure']) - 
        (mvs['oxygenSaturation'] * props.hemoglobin * 0.136 + 0.003 * mvs['oxygenPartialPressure'])
      )
  }
}

export function calculateCI(props) {
  const qs = calculateQs(props)
  const bodyArea = doCalculateBodyArea(props);

  if (!qs || !bodyArea) {
    return '';
  }

  return qs / bodyArea;
}

export function calculateQpQs(props) {
  const qp = calculateQp(props);
  const qs = calculateQs(props);

  if (!qp || !qs) {
    return '';
  }

  return qp / qs;
}

export function calculateQpsQs(props) {
  const qps = calculateQps(props);
  const qs = calculateQs(props);

  if (!qps || !qs) {
    return '';
  }

  return qps / qs;
}

export function calculateQpdQs(props) {
  const qpd = calculateQpd(props);
  const qs = calculateQs(props);

  if (!qpd || !qs) {
    return '';
  }

  return qpd / qs;
}

export function calculateRp(props) {
  if (!props || !props.oxygenConsumption || !props.measurementsData || !props.hemoglobin) {
    return '';
  }

  const qp = calculateQp(props);

  if (!qp) {
    return '';
  }

  const ap = props.measurementsData['ap'];

  if (!ap || !ap['meanPressure']) {
    return '';
  }

  const as = props.measurementsData['as'];

  if (!as || !as['meanPressure']) {
    return '';
  }

  return (ap['meanPressure'] - as['meanPressure']) / qp;
}

export function calculateRps(props) {
  if (!props || !props.oxygenConsumption || !props.measurementsData || !props.hemoglobin) {
    return '';
  }

  const qps = calculateQps(props);

  if (!qps) {
    return '';
  }

  const aps = props.measurementsData['aps'];

  if (!aps || !aps['meanPressure']) {
    return '';
  }

  const as = props.measurementsData['as'];

  if (!as || !as['meanPressure']) {
    return '';
  }

  return (aps['meanPressure'] - as['meanPressure']) / qps;
}

export function calculateRpd(props) {
  if (!props || !props.oxygenConsumption || !props.measurementsData || !props.hemoglobin) {
    return '';
  }

  const qpd = calculateQpd(props);

  if (!qpd) {
    return '';
  }

  const apd = props.measurementsData['apd'];

  if (!apd || !apd['meanPressure']) {
    return '';
  }

  const as = props.measurementsData['as'];

  if (!as || !as['meanPressure']) {
    return '';
  }

  return (apd['meanPressure'] - as['meanPressure']) / qpd;
}

export function calculateRs(props) {
  if (!props || !props.oxygenConsumption || !props.measurementsData || !props.hemoglobin) {
    return '';
  }

  const qs = calculateQs(props);

  if (!qs) {
    return '';
  }

  const aoAsc = props.measurementsData['aoAsc'];

  if (!aoAsc || !aoAsc['meanPressure']) {
    return '';
  }

  const ad = props.measurementsData['ad'];

  if (!ad || !ad['meanPressure']) {
    return '';
  }

  return (aoAsc['meanPressure'] - ad['meanPressure']) / qs;
}

export function calculateRpRs(props) {
  const rp = calculateRp(props);
  const rs = calculateRs(props);

  if (!rp || !rs) {
    return '';
  }

  return rp / rs;
}

export function calculateRpsRs(props) {
  const rps = calculateRps(props);
  const rs = calculateRs(props);

  if (!rps || !rs) {
    return '';
  }

  return rps / rs;
}

export function calculateRpdRs(props) {
  const rpd = calculateRpd(props);
  const rs = calculateRs(props);

  if (!rpd || !rs) {
    return '';
  }

  return rpd / rs;
}

export function calculateRpi(props) {
  const rp = calculateRp(props);
  const bodyArea = doCalculateBodyArea(props);

  if (!rp || !bodyArea) {
    return '';
  }

  return rp * bodyArea;
}

export function calculateRpsi(props) {
  const rps = calculateRps(props);
  const bodyArea = doCalculateBodyArea(props);

  if (!rps || !bodyArea) {
    return '';
  }

  return rps * bodyArea;
}

export function calculateRpdi(props) {
  const rpd = calculateRpd(props);
  const bodyArea = doCalculateBodyArea(props);

  if (!rpd || !bodyArea) {
    return '';
  }

  return rpd * bodyArea;
}

export function calculateBodyArea(props) {
  const bsa = doCalculateBodyArea(props);

  if (bsa) {
    return bsa.toFixed(2);
  } else {
    return bsa;
  }
}

function doCalculateBodyArea(props) {
  return props && props.weight > 0 && props.height > 0 ? 
    0.007184 * Math.pow(props.weight, 0.425) * Math.pow(props.height, 0.725) : '';
}

export function calculateCoO2(saturation, hemoglobin) {
  if (!hemoglobin || !saturation) {
    return ''
  }
  return hemoglobin * saturation * 0.0136;
}

export function calculateAge(personId, currentDateTimestamp) {
  if (personId && personId.length === 10 && currentDateTimestamp) {
    const currentDate = new Date(currentDateTimestamp)
    let currentDateYear = currentDate.getFullYear();
    let currentDateMonth = currentDate.getMonth();
    let currentDateDay = currentDate.getDate();

    let birthdayYear = parseInt(personId.substring(0, 2));
    let birthdayMonth = parseInt(personId.substring(2, 4));
    let birthdayDay = parseInt(personId.substring(4, 6));

    if (birthdayMonth < 20) {
      birthdayYear += 1900;
    } else if (birthdayMonth < 40) {
      birthdayYear += 1800;
      birthdayMonth -= 20;
    } else {
      birthdayYear += 2000;
      birthdayMonth -= 40;
    }

    const duration = intervalToDuration({
      start: new Date(birthdayYear, birthdayMonth - 1, birthdayDay, 0, 0, 0), 
      end: new Date(currentDateYear, currentDateMonth, currentDateDay, 0, 0, 0)
    })

    return { years: duration.years, months: duration.months, days: duration.days };
  } else {
    return null;
  }
}