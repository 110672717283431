import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Fab, Grid, IconButton, 
   Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField } from '@material-ui/core'
import React from 'react'
import PropTypes from 'prop-types'
import { PureComponent } from 'react'
import { addRevatioPending, deleteRevatioPending } from '../rest/RESTUtils'
import { calculateAge } from '../util/Formulas'
import TreeViewComponent from '../util/TreeViewComponent'
import DateInput from '../util/DateInput'
import { DiagnoseInput } from '../caseinfo/generalinfo/DiagnoseInput'
import EditIcon from '@material-ui/icons/Edit'
import EventIcon from '@material-ui/icons/Event'
import DeleteIcon from '@material-ui/icons/Delete'

const HEADERS = [
  { id: 'name', label: 'Име', width: '15%', align: 'center' },
  { id: 'age', label: 'Възраст', width: '7%', align: 'center' },
  { id: 'phoneNumber', label: 'Телефонен номер', width: '8%', align: 'center' },
  { id: 'diagnose', label: 'Диагноза', width: '15%', align: 'center' },
  { id: 'lastReceived', label: 'Последно взето лекарство', width: '15%', align: 'center' },
  { id: 'plannedHospitalization', label: 'Предстояща хоспитализация', width: '15%', align: 'center' }
]

class EventDetailsDialog extends React.PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      customPeriodDialogOpen: false,
      id: this.props.id,
      name: this.props.name,
      personId: this.props.personId,
      phoneNumber: this.props.phoneNumber,
      diagnose: this.props.diagnose,
      surgeryInfo: this.props.surgeryInfo,
      catheterizationInfo: this.props.catheterizationInfo,
      lastReceived: this.props.lastReceived,
      plannedHospitalization: this.props.plannedHospitalization,
      therapy: this.props.therapy
    }
  }

  onChanged(key, value) {
    this.setState({
      [key]: value
    })
  }

  isDataFilled() {
    return this.state.name && this.state.personId && this.state.personId.length == 10 && this.state.diagnose
  }

  render() {
    return (
      <>
        <Dialog onClose={() => this.props.onClose(-1, -1)} open={true} maxWidth={'lg'}>
          <DialogTitle>Детайли</DialogTitle>
          <DialogContent>
            <Grid container justify='space-around' alignItems='center' spacing={3} >
              <Grid item xs={12}>
                <TextField value={this.state.name || ''} label='Име' fullWidth 
                  onChange={(event) => this.onChanged('name', event.target.value)}/>
              </Grid>

              <Grid item xs={6}>
                <TextField value={this.state.personId || ''} label='ЕГН' fullWidth
                  onChange={(event) => this.onChanged('personId', event.target.value)} />
              </Grid>
              <Grid item xs={6}>
                <TextField value={this.state.phoneNumber || ''} label='Телефонен номер' fullWidth 
                  onChange={(event) => this.onChanged('phoneNumber', event.target.value)}/>
              </Grid>

              <Grid item xs={12}>
                <DiagnoseInput diagnosesIds={this.state.diagnose} diagnoses={this.props.diagnoses}
                  onChanged={diagnosesIds => this.onChanged('diagnose', diagnosesIds)} />
              </Grid>

              <Grid item xs={6}>
                <TextField value={this.state.surgeryInfo || ''} label='Операции' fullWidth multiline rowsMax={4}
                  onChange={(event) => this.onChanged('surgeryInfo', event.target.value)}/>
              </Grid>

              <Grid item xs={6}>
                <TextField value={this.state.catheterizationInfo || ''} label='ИКИ' fullWidth multiline rowsMax={4}
                  onChange={(event) => this.onChanged('catheterizationInfo', event.target.value)}/>
              </Grid>

              <Grid item xs={3}>
                <DateInput label='Последно взето лекарство'
                  value={this.state.lastReceived}
                  onValueChanged={value => this.onChanged('lastReceived', value)}/>
              </Grid>
              <Grid item xs={3}>
                <DateInput label='Предстояща хоспитализация'
                  value={this.state.plannedHospitalization}
                  onValueChanged={value => this.onChanged('plannedHospitalization', value)}/>
              </Grid>

              <Grid item xs={6}>
                <TextField value={this.state.therapy || ''} label='Приемани медикаменти' fullWidth multiline rowsMax={4}
                  onChange={(event) => this.onChanged('therapy', event.target.value)}/>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.props.onClose()} color='primary'>
              Затвори
            </Button>
            <Button disabled={!this.isDataFilled()} color='primary'
              onClick={() => this.props.onClose({
                id: this.state.id,
                name: this.state.name,
                personId: this.state.personId,
                phoneNumber: this.state.phoneNumber,
                diagnose: this.state.diagnose,
                surgeryInfo: this.state.surgeryInfo,
                catheterizationInfo: this.state.catheterizationInfo,
                lastReceived: this.state.lastReceived,
                plannedHospitalization: this.state.plannedHospitalization,
                therapy: this.state.therapy
              })}>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

EventDetailsDialog.propTypes = {
  onClose: PropTypes.func.isRequired,

  diagnoses: PropTypes.object.isRequired,
  caseTypes: PropTypes.object.isRequired,
  
  id: PropTypes.string,
  name: PropTypes.string,
  personId: PropTypes.string,
  phoneNumber: PropTypes.string,
  diagnose: PropTypes.array,
  surgeryInfo: PropTypes.string,
  catheterizationInfo: PropTypes.string,
  lastReceived: PropTypes.string,
  plannedHospitalization: PropTypes.string,
  therapy: PropTypes.string
}

class PendingTable extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      addPendingDialogOpen: false,
      planPendingDialogOpen: false,
      pendingToEdit: null,
      pending: this.props.pending,
      page: 0,
      rowsPerPage: 10
    }
  }

  onChanged(key, value) {
    this.setState({
      [key]: value
    })
  }

  getTitle(id, treeData) {
    return TreeViewComponent.getNode(treeData, false, id).title
  }

  ageToString(age) {
    if (age) {
      return (age.years ? age.years + 'г ' : '') + 
             (age.months ? age.months + 'м ' : '') + 
             (age.days ? age.days + 'д ' : '')
    } else {
      return ''
    }
  }

  onAddPending(data) {
    if (data) {
      console.log(data)
      addRevatioPending(data, () => this.props.onChanged())
    }

    this.setState({
      addPendingDialogOpen: false,
      pendingToEdit: null
    })
  }

  onDeletePending(data) {
    if (data) {
      deleteRevatioPending(data.id, () => this.props.onChanged())
    }
  }

  onEditPending(data) {
    this.setState({
      addPendingDialogOpen: true,
      pendingToEdit: data
    })
  }

  onPlanPending(data) {
    this.setState({
      planPendingDialogOpen: true,
      pendingToEdit: data
    })
  }

  onQuickSearchPerformed(search) {
    if (search) {
      this.setState({
        search: search,
        page: 0,
        pending: this.props.pending.filter(pending => pending.name.toLowerCase().includes(search.toLowerCase()) || pending.personId.includes(search))
      })
    } else {
      this.setState({
        search: search,
        page: 0,
        pending: this.props.pending
      })
    }
  }

  planningFinished(data, date) {
    data.plannedFor = date
    this.setState({
      planPendingDialogOpen: false,
      pendingToEdit: null
    })
    this.onAddPending(data)
  }

  handleChangePage(newPage) {
    this.setState({
      page: newPage
    })
  }

  handleChangeRowsPerPage(newRowsPerPage) {
    this.setState({
      page: 0,
      rowsPerPage: newRowsPerPage
    })
  }

  render () {
    let pending = this.state.pending

    if (!pending || pending.length == 0) {
      pending = [ null ]
    }

    const tableRows = 
      pending
        .slice(this.state.page * this.state.rowsPerPage, (this.state.page + 1) * this.state.rowsPerPage)
        .map((data) => {
          return (
            <TableRow hover role='checkbox' tabIndex={-1} key={data?.personId}>
              <TableCell key='name' align='center'>
                {(data && data.name) || ''}
              </TableCell>
              <TableCell key='age' align='center'>
                {(data && data.personId && this.ageToString(calculateAge(data.personId, Date.now()))) || ''}
              </TableCell>
              <TableCell key='phoneNumber' align='center'>
                {(data && data.phoneNumber) || ''}
              </TableCell>
              <TableCell key='diagnose' align='center'>
                {(data?.diagnose?.map(diagnose => this.getTitle(diagnose, this.props.diagnoses)).join(' | ')) || ''}
              </TableCell>
              <TableCell key='lastReceived' align='center'>
                {(data && data.lastReceived) || ''}
              </TableCell>
              <TableCell key='plannedHospitalization' align='center'>
                {(data && data.plannedHospitalization) || ''}
              </TableCell>
              <TableCell key='plan' align='center'>
                <IconButton onClick={() => data && this.onPlanPending(data)}>
                  <EventIcon opacity={data ? '100%' : '0%'}/>
                </IconButton>
              </TableCell>
              <TableCell key='edit' align='center'>
                <IconButton onClick={() => data && this.onEditPending(data)}>
                  <EditIcon opacity={data ? '100%' : '0%'}/>
                </IconButton>
              </TableCell>
              <TableCell key='delete' align='center'>
                <IconButton onClick={() => data && this.onDeletePending(data)}>
                  <DeleteIcon opacity={data ? '100%' : '0%'}/>
                </IconButton>
              </TableCell>
            </TableRow>
          )
        }) 

    return (
      <>
        <Fab variant='extended' 
          style={{
            margin: 0,
            top: 60,
            right: 50,
            bottom: 'auto',
            left: 'auto',
            position: 'fixed',
            zIndex: 100
        }}
          onClick={() => this.onChanged('addPendingDialogOpen', true)}>
          Добави
        </Fab>

        {this.state.addPendingDialogOpen && (
          <EventDetailsDialog diagnoses={this.props.diagnoses} onClose={data => this.onAddPending(data)} {...(this.state.pendingToEdit || {})}/>
        )}

        <TableContainer component={Paper} style={{ maxHeight: 350, minHeight: 350 }}>
          <Table size='small' stickyHeader>
            <TableHead>
              <TableRow>
                {HEADERS.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    width={column.width}
                  >
                    {column.label}
                  </TableCell>
                ))}
                <TableCell
                    key='search'
                    align='center'
                    width='100px'
                    colSpan={3}
                  >
                    <TextField style={{ width: '100%' }} value={this.state.search || ''} label='Търсене'
                      onChange={(event) => this.onQuickSearchPerformed(event.target.value)}/>
                  </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableRows}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component='div'
          count={this.state.pending?.length || 0}
          labelRowsPerPage='Редове на страница'
          rowsPerPage={this.state.rowsPerPage}
          page={this.state.page}
          onChangePage={(event, newPage) => this.handleChangePage(newPage)}
          onChangeRowsPerPage={(event) => this.handleChangeRowsPerPage(event.target.value)}
        />
      </>
    )
  }
}

PendingTable.propTypes = {
  onChanged: PropTypes.func.isRequired,
  diagnoses: PropTypes.object.isRequired,
  pending: PropTypes.array.isRequired
}

export default PendingTable
