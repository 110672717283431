import React from 'react'
import PropTypes from 'prop-types'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { Grid } from '@material-ui/core'

class BooleanOptions extends React.Component {

  constructor(props) {
    super(props)
  }

  render() {
    return (
      <Grid container justify='space-around' alignItems='center' spacing={3}>
        <Grid item xs={12}>
          <div style={this.props.disabled ? { color: 'lightgrey' } : { color : 'black' }}>{this.props.title || ''}</div>
          <ToggleButtonGroup exclusive={this.props.exclusive} value={this.props.value || false} 
            onChange={(event, newValue) => this.props.onValueChanged(newValue)} size={this.props.size}>
            <ToggleButton value={false} disabled={this.props.disabled} style={{ 'text-transform': 'none'}} >
              {this.props.falseLabel ? this.props.falseLabel : 'не'}
            </ToggleButton>
            <ToggleButton value={true} disabled={this.props.disabled} style={{ 'text-transform': 'none' }}>
              {this.props.trueLabel ? this.props.trueLabel : 'да'}
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
    )
  }
}

BooleanOptions.propTypes = {
  onValueChanged: PropTypes.func.isRequired,

  title: PropTypes.string,
  
  value: PropTypes.bool,
  falseLabel: PropTypes.string,
  trueLabel: PropTypes.string,

  exclusive: PropTypes.bool,
  disabled: PropTypes.bool,

  size: PropTypes.string,

  t: PropTypes.func
}

export default BooleanOptions
