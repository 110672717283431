const CASE_TYPES = [
  {
    'id' : '0001',
    'title' : 'Бендинг БА',
    'isSingle' : false,
    'supportsAdditionalDetails' : false
  },
  {
    'id' : '0002',
    'title' : 'БТ шънт',
    'isSingle' : false,
    'supportsAdditionalDetails' : false
  },
  {
    'id' : '0003',
    'title' : 'Централна системно-пулмнална анастомоза',
    'isSingle' : false,
    'supportsAdditionalDetails' : false
  },
  {
    'id' : '0004',
    'title' : 'Сано шънт',
    'isSingle' : false,
    'supportsAdditionalDetails' : false
  },
  {
    'id' : '0005',
    'title' : 'Лигатура ПАК',
    'isSingle' : false,
    'supportsAdditionalDetails' : false
  },
  {
    'id' : '0006',
    'title' : 'Трансекция ПАК',
    'isSingle' : false,
    'supportsAdditionalDetails' : false
  },
  {
    'id' : '0007',
    'title' : 'Прекъсване на съдов пръстен',
    'isSingle' : false,
    'supportsAdditionalDetails' : false
  },
  {
    'id' : '0008',
    'title' : 'Резекция КоА и анастомоза край в край',
    'isSingle' : false,
    'supportsAdditionalDetails' : false
  },
  {
    'id' : '0009',
    'title' : 'Резекция КоА и разширена анастомоза край в край - Амато',
    'isSingle' : false,
    'supportsAdditionalDetails' : false
  },
  {
    'id' : '0010',
    'title' : 'Сутура на МПД',
    'isSingle' : false,
    'supportsAdditionalDetails' : false
  },
  {
    'id' : '0011',
    'title' : 'Пластика на МПД',
    'isSingle' : false,
    'supportsAdditionalDetails' : false
  },
  {
    'id' : '0012',
    'title' : 'Тунел пластика МПД',
    'isSingle' : false,
    'supportsAdditionalDetails' : false
  },
  {
    'id' : '0013',
    'title' : 'Изграждане на междупредсърдна преграда',
    'isSingle' : false,
    'supportsAdditionalDetails' : false
  },
  {
    'id' : '0014',
    'title' : 'Уордън процедура',
    'isSingle' : false,
    'supportsAdditionalDetails' : false
  },
  {
    'id' : '0015',
    'title' : 'Сутура на МКД',
    'isSingle' : false,
    'supportsAdditionalDetails' : false
  },
  {
    'id' : '0016',
    'title' : 'Пластика на МКД',
    'isSingle' : false,
    'supportsAdditionalDetails' : false
  },
  {
    'id' : '0017',
    'title' : 'Тунел пластика МКД',
    'isSingle' : false,
    'supportsAdditionalDetails' : false
  },
  {
    'id' : '0018',
    'title' : 'Пластика примум дефект',
    'isSingle' : false,
    'supportsAdditionalDetails' : false
  },
  {
    'id' : '0019',
    'title' : 'Австралийска техника АВСД',
    'isSingle' : false,
    'supportsAdditionalDetails' : false
  },
  {
    'id' : '0020',
    'title' : 'Дабъл пач пластика АВСД',
    'isSingle' : false,
    'supportsAdditionalDetails' : false
  },
  {
    'id' : '0021',
    'title' : 'Сингъл пач пластика АВСД',
    'isSingle' : false,
    'supportsAdditionalDetails' : false
  },
  {
    'id' : '0022',
    'title' : 'Пластика на аортопулмонален фенестър',
    'isSingle' : false,
    'supportsAdditionalDetails' : false
  },
  {
    'id' : '0023',
    'title' : 'Трансфер на пулмонална артерия',
    'isSingle' : false,
    'supportsAdditionalDetails' : false
  },
  {
    'id' : '0024',
    'title' : 'Пластика на трункална клапа',
    'isSingle' : false,
    'supportsAdditionalDetails' : false
  },
  {
    'id' : '0025',
    'title' : 'Анастомоза венозен колектор – ЛП',
    'isSingle' : false,
    'supportsAdditionalDetails' : false
  },
  {
    'id' : '0026',
    'title' : 'Дерууфинг коронарен синус',
    'isSingle' : false,
    'supportsAdditionalDetails' : false
  },
  {
    'id' : '0027',
    'title' : 'Ексцизия на фиброза в ЛП',
    'isSingle' : false,
    'supportsAdditionalDetails' : false
  },
  {
    'id' : '0028',
    'title' : 'Дезобструкция ДКИП',
    'isSingle' : false,
    'supportsAdditionalDetails' : false
  },
  {
    'id' : '0029',
    'title' : 'Пластика на ствола на БА',
    'isSingle' : false,
    'supportsAdditionalDetails' : false
  },
  {
    'id' : '0030',
    'title' : 'Пластика на ДБА',
    'isSingle' : false,
    'supportsAdditionalDetails' : false
  },
  {
    'id' : '0031',
    'title' : 'Пластика на ЛБА',
    'isSingle' : false,
    'supportsAdditionalDetails' : false
  },
  {
    'id' : '0032',
    'title' : 'Инфундибуларен пач',
    'isSingle' : false,
    'supportsAdditionalDetails' : false
  },
  {
    'id' : '0033',
    'title' : 'ТАП',
    'isSingle' : false,
    'supportsAdditionalDetails' : false
  },
  {
    'id' : '0034',
    'title' : 'Кондуит ДК-БА',
    'isSingle' : false,
    'supportsAdditionalDetails' : false
  },
  {
    'id' : '0035',
    'title' : 'Унифокализация',
    'isSingle' : false,
    'supportsAdditionalDetails' : false
  },
  {
    'id' : '0036',
    'title' : 'Пластика ТК',
    'isSingle' : false,
    'supportsAdditionalDetails' : false
  },
  {
    'id' : '0037',
    'title' : 'Протезиране ТК',
    'isSingle' : false,
    'supportsAdditionalDetails' : false
  },
  {
    'id' : '0038',
    'title' : 'Пластика МК',
    'isSingle' : false,
    'supportsAdditionalDetails' : false
  },
  {
    'id' : '0039',
    'title' : 'Протезиране МК',
    'isSingle' : false,
    'supportsAdditionalDetails' : false
  },
  {
    'id' : '0040',
    'title' : 'Комисуротомия ПК',
    'isSingle' : false,
    'supportsAdditionalDetails' : false
  },
  {
    'id' : '0041',
    'title' : 'Пластика ПК',
    'isSingle' : false,
    'supportsAdditionalDetails' : false
  },
  {
    'id' : '0042',
    'title' : 'Протезиране ПК',
    'isSingle' : false,
    'supportsAdditionalDetails' : false
  },
  {
    'id' : '0043',
    'title' : 'Комисуротомия АК',
    'isSingle' : false,
    'supportsAdditionalDetails' : false
  },
  {
    'id' : '0044',
    'title' : 'Пластика АК',
    'isSingle' : false,
    'supportsAdditionalDetails' : false
  },
  {
    'id' : '0045',
    'title' : 'Протезиране АК',
    'isSingle' : false,
    'supportsAdditionalDetails' : false
  },
  {
    'id' : '0046',
    'title' : 'Разширяване на аортен пръстен',
    'isSingle' : false,
    'supportsAdditionalDetails' : false
  },
  {
    'id' : '0047',
    'title' : 'Пластика на аортен корен и възходяща аорта',
    'isSingle' : false,
    'supportsAdditionalDetails' : false
  },
  {
    'id' : '0048',
    'title' : 'Екстирпация САС',
    'isSingle' : false,
    'supportsAdditionalDetails' : false
  },
  {
    'id' : '0049',
    'title' : 'Миектомия по Мороу',
    'isSingle' : false,
    'supportsAdditionalDetails' : false
  },
  {
    'id' : '0050',
    'title' : 'Модифицирано Коно',
    'isSingle' : false,
    'supportsAdditionalDetails' : false
  },
  {
    'id' : '0051',
    'title' : 'Рос операция',
    'isSingle' : false,
    'supportsAdditionalDetails' : false
  },
  {
    'id' : '0052',
    'title' : 'Реконструкция на аортна дъга',
    'isSingle' : false,
    'supportsAdditionalDetails' : false
  },
  {
    'id' : '0053',
    'title' : 'Артериален суич',
    'isSingle' : false,
    'supportsAdditionalDetails' : false
  },
  {
    'id' : '0054',
    'title' : 'Атриален суич',
    'isSingle' : false,
    'supportsAdditionalDetails' : false
  },
  {
    'id' : '0055',
    'title' : 'Директен коронарен трансфер',
    'isSingle' : false,
    'supportsAdditionalDetails' : false
  },
  {
    'id' : '0056',
    'title' : 'Такеучи процедура',
    'isSingle' : false,
    'supportsAdditionalDetails' : false
  },
  {
    'id' : '0057',
    'title' : 'Растели процедура',
    'isSingle' : false,
    'supportsAdditionalDetails' : false
  },
  {
    'id' : '0058',
    'title' : 'РЕВ процедура',
    'isSingle' : false,
    'supportsAdditionalDetails' : false
  },
  {
    'id' : '0059',
    'title' : 'Никайдо процедура',
    'isSingle' : false,
    'supportsAdditionalDetails' : false
  },
  {
    'id' : '0060',
    'title' : 'Ясуи процедура',
    'isSingle' : false,
    'supportsAdditionalDetails' : false
  },
  {
    'id' : '0061',
    'title' : 'Кон процедура',
    'isSingle' : false,
    'supportsAdditionalDetails' : false
  },
  {
    'id' : '0062',
    'title' : 'Глен анастомоза',
    'isSingle' : false,
    'supportsAdditionalDetails' : false
  },
  {
    'id' : '0063',
    'title' : 'Атриосептектомия',
    'isSingle' : false,
    'supportsAdditionalDetails' : false
  },
  {
    'id' : '0064',
    'title' : 'Фонтан процедура',
    'isSingle' : false,
    'supportsAdditionalDetails' : false
  },
  {
    'id' : '0065',
    'title' : 'Норууд процедура',
    'isSingle' : false,
    'supportsAdditionalDetails' : false
  },
  {
    'id' : '0066',
    'title' : 'Кавашима процедура',
    'isSingle' : false,
    'supportsAdditionalDetails' : false
  },
  {
    'id' : '0067',
    'title' : 'ДКС анастомоза',
    'isSingle' : false,
    'supportsAdditionalDetails' : false
  },
  {
    'id' : '0068',
    'title' : 'Имплантация ПМ генератор',
    'isSingle' : false,
    'supportsAdditionalDetails' : false
  },
  {
    'id' : '0069',
    'title' : 'Имплантация ПМ електрод',
    'isSingle' : false,
    'supportsAdditionalDetails' : false
  },
  {
    'id' : '0070',
    'title' : 'Плеврален дренаж',
    'isSingle' : false,
    'supportsAdditionalDetails' : false
  },
  {
    'id' : '0071',
    'title' : 'Хирургичен дренаж на перикард',
    'isSingle' : false,
    'supportsAdditionalDetails' : false
  },
  {
    'id' : '0072',
    'title' : 'Отложено затваряне на стернум',
    'isSingle' : false,
    'supportsAdditionalDetails' : false
  },
  {
    'id' : '0073',
    'title' : 'Артефициален ФО',
    'isSingle' : false,
    'supportsAdditionalDetails' : false
  }
]

export default CASE_TYPES