import React from 'react'
import PropTypes from 'prop-types'
import DateFnsUtils from '@date-io/date-fns'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'

class DateInput extends React.Component {

    constructor(props) {
      super(props)
    }
  
    render() {
      return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker disableToolbar disabled={this.props.disabled}
            variant='inline' margin='normal' label={this.props.label}
            error={!this.props.disabled && this.props.required && !this.props.value}
            inputVariant='outlined' size={this.props.size} 
            format='yyyy/MM/dd' value={this.props.value && new Date(this.props.value)}
            onChange={(date, value) => this.props.onValueChanged(value)}
          />
        </MuiPickersUtilsProvider>
      )
    }
  }
  
  DateInput.propTypes = {
    value: PropTypes.string,
    label: PropTypes.string.isRequired,
    onValueChanged: PropTypes.func.isRequired,

    disabled: PropTypes.bool,
    size: PropTypes.string,

    required: PropTypes.bool
  }

  DateInput.defaultProps = {
    required: false
  }

  export default DateInput