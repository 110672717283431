import React from 'react';
import PropTypes from 'prop-types';
import { Grid, ListItem, TextField } from '@material-ui/core';
import BooleanResultToggleButtons from '../../util/BooleanResultToggleButtons';

export class ASDDeviceClosure extends React.PureComponent {

  constructor(props) {
    super(props);
  }

  onChanged(key, value, path = []) {
    path.unshift(this.props.dataGroupId);
    this.props.onChanged(key, value, path);
  }

  render() {
    return (
      <ListItem>
        <Grid container justify="space-around" alignItems="center" spacing={3}>
          <Grid item xs={3}>
            Транскатетърно Затваряне на МПД
          </Grid>
          <Grid item xs={3}>
            <BooleanResultToggleButtons value={this.props.performed} 
              onValueChange={(newValue) => this.onChanged("performed", newValue)} />
          </Grid>
          <Grid item xs={6} />

          {this.props.performed && (
            <>
              <Grid item xs={6}>
                <TextField style={{ width: "100%" }} label="Индикации" variant="outlined" multiline rows={2} 
                  value={this.props.indications}
                  onChange={(event) => this.onChanged("indications", event.target.value)}/>
              </Grid>
              <Grid item xs={6} />

              <Grid item xs={3}>
                Остатъчен шънт
              </Grid>
              <Grid item xs={3}>
                <BooleanResultToggleButtons 
                  value={this.props.residualShunt}
                  onValueChange={(newValue) => this.onChanged("residualShunt", newValue)} />
              </Grid>
              <Grid item xs={6} />

              {this.props.residualShunt && (
                <>
                  <Grid item xs={3}>
                    <TextField style={{ width: "100%" }} label="Размер на шънта" variant="outlined" 
                      value={this.props.shuntSize || ""}
                      onChange={(event) => this.onChanged("shuntSize", parseInt(event.target.value))}/>
                  </Grid>
                  <Grid item xs={9} />
                </>
              )}

              <Grid item xs={3}>
                Инсуфициенция на митрална клапа
              </Grid>
              <Grid item xs={3}>
                <BooleanResultToggleButtons 
                  value={this.props.mitralValveInsufficiency}
                  onValueChange={(newValue) => this.onChanged("mitralValveInsufficiency", newValue)} />
              </Grid>
              <Grid item xs={6} />
            </>
          )}
        </Grid>
      </ListItem>
    );
  }
}

ASDDeviceClosure.propTypes = {
  onChanged: PropTypes.func.isRequired,
  dataGroupId: PropTypes.string.isRequired,

  performed: PropTypes.bool,
  indications: PropTypes.string,

  residualShunt: PropTypes.bool,
  shuntSize: PropTypes.number,

  mitralValveInsufficiency: PropTypes.bool
}