import { Grid, InputAdornment, Paper, Tab, Tabs, TextField } from '@material-ui/core';
import React from 'react';
import CatheterizationMeasurements from './CatheterizationMeasurements';
import TabPanel from '../../util/TabPanel';
import PropTypes from 'prop-types';
import BooleanResultToggleButtons from '../../util/BooleanResultToggleButtons';
import { calculateBodyArea } from '../../util/Formulas';

class LinkInformation {
  constructor(title, Component, dataId, isVasodilation) {
    this.title = title;
    this.Component = Component;
    this.dataId = dataId;
    this.isVasodilation = isVasodilation;
  }
}

const LINKS = [
  new LinkInformation('Двукамерна', CatheterizationMeasurements, "biventricular", false),
  new LinkInformation('Еднокамерна', CatheterizationMeasurements, "semiventricular", false),
  new LinkInformation('Вазодилатативен тест', CatheterizationMeasurements, "vasodilationTest", true)
]

export class Hemodynamics extends React.PureComponent {

  constructor(props) {
    super(props);
  }

  onChanged(key, value, path = []) {
    path.unshift(this.props.dataGroupId);
    this.props.onChanged(key, value, path);
  }

  onStateChanged(key, value) {
    this.setState({
      [key]: value
    })
  }

  calculateOxygenConsumption() {
    if (!this.props.weight || !this.props.age) {
      return "";
    } else {
      let result = (242.1 + (9.7 * Math.log(this.getAgeInYears())) - (34 * Math.log(this.props.weight)) - 
        (9.6 * this.singleVentricle()) - (11.2 * (this.props.critical ? 1 : 0))).toFixed();
      
      this.onStateChanged('oxygenConsumption', result);

      return result;
    }
  }

  getAgeInYears() {
    return this.props.age.years + (this.props.age.months / 12) + (this.props.age.days / 365);
  }

  singleVentricle() {
    return this.props.selected === 1 ? 1 : 0;
  }

  render () {
    return (
      <Paper>
        <Grid container justify="space-around" alignItems="center" spacing={3}>
          <Grid item xs={3}>
            <TextField variant="outlined" value={this.props.hemoglobin || ""} 
              style={{ width: "100%" }} label="Хемоглобин"
              onChange={(event) => this.onChanged("hemoglobin", event.target.value)}
              InputProps={{
                endAdornment: <InputAdornment position="end">g/dL</InputAdornment>,
              }}/>
          </Grid>
          <Grid item xs={9}/>
          <Grid item xs={2}>
            Критично болно
          </Grid>
          <Grid item xs={1}>
            <BooleanResultToggleButtons value={this.props.critical || false} 
              onValueChange={(newValue) => this.onChanged("critical", newValue)} />
          </Grid>
          <Grid item xs={9} />
          <Grid item xs={3}>
            <TextField disabled variant="outlined" value={this.calculateOxygenConsumption()} 
              style={{ width: "100%" }} label="Кислородна консумация" />
          </Grid>
          <Grid item xs={9} />
          <Grid item xs={12}>
            <Tabs value={this.props.selected || 0} onChange={(event, newValue) => this.onChanged("selected", newValue)}
              indicatorColor="primary" textColor="primary" centered
            >
              {
                LINKS.map((link) => (
                  <Tab key={link.title} label={link.title} />
                ))
              }
            </Tabs>
            {
              LINKS.map((link, index) => (
                <TabPanel key={"panel" + link.title} value={this.props.selected || 0} index={index} >
                  <link.Component {...(this.props[link.dataId] || {})}
                    height={this.props.height}
                    weight={this.props.weight}
                    dataGroupId={link.dataId}
                    isVasodilation={link.isVasodilation}
                    onChanged={(key, value, path) => this.onChanged(key, value, path)}
                    bodyArea={calculateBodyArea(this.props.height, this.props.weight)}
                    hemoglobin={this.props.hemoglobin}
                    oxygenConsumption={this.calculateOxygenConsumption()} />
                </TabPanel>
              ))
            }
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

Hemodynamics.propTypes = {
  onChanged: PropTypes.func.isRequired,
  dataGroupId: PropTypes.string.isRequired,

  hemoglobin: PropTypes.string,
  critical: PropTypes.bool,
  selected: PropTypes.number,

  age: PropTypes.object,
  weight: PropTypes.number,
  height: PropTypes.number
}