import React from 'react'
import PropTypes from 'prop-types'
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'

class Combo extends React.Component {

  constructor(props) {
    super(props)
  }

  render() {
    return (
      <Autocomplete
        disablePortal 
        onChange={(event, newValue) => {
          this.props.onValueChanged(newValue)
        }}
        id={this.props.id + '-select'}
        options={this.props.labels}
        freeSolo
        value={this.props.labels[this.props.value]}
        renderInput={(params) => 
          <TextField {...params} value={this.props.labels[this.props.value]} variant='outlined' label={this.props.title} 
            onChange={(event) => this.props.onValueChanged(event.target.value)}/>
        }
      />
    )
  }
}

Combo.propTypes = {
  onValueChanged: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  labels: PropTypes.array.isRequired,

  title: PropTypes.string,
  value: PropTypes.number,

  size: PropTypes.string
}

export default Combo
