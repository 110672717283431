import React from 'react'
import PropTypes from 'prop-types'
import { Fab, Grid, IconButton } from '@material-ui/core'
import Image from 'material-ui-image'
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'

export class Gallery extends React.PureComponent {

  constructor(props) {
    super(props)

    this.handleUploadClick = this.handleUploadClick.bind(this);
    this.imageAdded = this.imageAdded.bind(this);
  }

  onChanged(key, value, path = []) {
    path.unshift(this.props.dataGroupId)
    this.props.onChanged(key, value, path)
  }

  handleUploadClick(event) {
    for (let i = 0; i < event.target.files.length; i++) {
      let file = event.target.files.item(i);
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onloadend = this.imageAdded
    }
  }

  imageAdded(event) {
    let images = [... (this.props.images || [])]
    images.push(event.target.result)
    this.onChanged('images', images)
  }

  imageRemoved(index) {
    let images = [... (this.props.images || [])]
    images.splice(index, 1)
    this.onChanged('images', images)
  }

  render () {
    return (
      <>
        <Grid container justify='space-around' alignItems='center' spacing={3}>
          {this.props.images?.map((imageData, index) => (
            <>
              <Grid key={'image' + index} item xs={11}>
                <Image src={imageData}/>
              </Grid>
              <Grid key={'button' + index} item xs={1}>
                <IconButton onClick={() => this.imageRemoved(index)} 
                  color="secondary" component="span">
                  <RemoveCircleIcon fontSize="large" />
                </IconButton>
              </Grid>
            </>
          ))}

          <Grid item xs={12}>
            <input
              accept='image/*'
              id='contained-button-file'
              multiple
              hidden
              type='file'
              onChange={this.handleUploadClick}
            />
            <label htmlFor='contained-button-file'>
              <Fab component='span'>
                <AddPhotoAlternateIcon />
              </Fab>
            </label>
          </Grid>
        </Grid>
      </>
    )
  }
}

Gallery.propTypes = {
  dataGroupId: PropTypes.string.isRequired,
  onChanged: PropTypes.func.isRequired,
  
  images: PropTypes.array,
}