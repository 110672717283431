import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Grid, List } from '@material-ui/core';
import { AorticValvuloplasty } from './AorticValvuloplasty';
import { PulmonaryValvuloplasty } from './PulmonaryValvuloplasty';
import { ASDDeviceClosure } from './ASDDeviceClosure';
import { CoarctationProcedure } from './CoarctationProcedure';
import { PDADeviceClosure } from './PDADeviceClosure';

export class ProceduralEfficacy extends React.PureComponent {

  constructor(props) {
    super(props);
  }

  onChanged(key, value, path = []) {
    path.unshift(this.props.dataGroupId);
    this.props.onChanged(key, value, path);
  }

  render () {
    return (
      <Grid container justify="space-around" alignItems="center" spacing={3}>
        <Grid item xs={12}>
          Бяха ли извършени някои от следните процедури?
        </Grid>
        <Grid item xs={12}>
          <List style={{ width: "100%" }}>
            <AorticValvuloplasty {...(this.props.avData || {})} dataGroupId="avData"
              onChanged={(key, value, path) => this.onChanged(key, value, path)}/>
            <Divider />
            <PulmonaryValvuloplasty {...(this.props.pvData || {})} dataGroupId="pvData"
              onChanged={(key, value, path) => this.onChanged(key, value, path)}/>
            <Divider />
            <ASDDeviceClosure {...(this.props.asddcData || {})} dataGroupId="asddcData"
              onChanged={(key, value, path) => this.onChanged(key, value, path)}/>
            <Divider />
            <CoarctationProcedure {...(this.props.cpData || {})} dataGroupId="cpData"
              onChanged={(key, value, path) => this.onChanged(key, value, path)}/>
            <Divider />
            <PDADeviceClosure {...(this.props.pdadcData || {})} dataGroupId="pdadcData"
              onChanged={(key, value, path) => this.onChanged(key, value, path)}/>
          </List>
        </Grid>
      </Grid>
    );
  }
}

ProceduralEfficacy.propTypes = {
  onChanged: PropTypes.func.isRequired,
  dataGroupId: PropTypes.string.isRequired,

  avData: PropTypes.object,
  pvData: PropTypes.object,
  asddcData: PropTypes.object,
  cpData: PropTypes.object,
  pdadcData: PropTypes.object,
}