import React from 'react';
import SurgicalCaseInformation from './SurgicalCaseInformation';

class AddSurgicalCase extends React.PureComponent {

  constructor(props) {
    super(props);
  }

  render () {
    return (
      <SurgicalCaseInformation/>
    );
  }
}

export default AddSurgicalCase;
