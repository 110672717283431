import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types'
import { PureComponent } from 'react';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

export default class DateSelectDialog extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      date: props.date || new Date().getTime()
    }
  }

  onChanged(key, value) {
    this.setState({
      [key]: value
    })
  }

  render() {
    return (
      <Dialog onClose={() => this.props.onClose(null)} open={true}>
        <DialogTitle>Календар</DialogTitle>
        <DialogContent>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker disableToolbar
              variant="inline" margin="normal" inputVariant="outlined"
              format="dd/MM/yyyy" value={(this.state.date && new Date(this.state.date)) || new Date()}
              onChange={(newValue) => this.onChanged("date", newValue?.getTime())}
            />
          </MuiPickersUtilsProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.props.onClose(null)} color="primary">
            Затвори
          </Button>
          <Button disabled={this.state.level == -1 || this.state.timeToManage == -1} color="primary"
            onClick={() => this.props.onClose(this.state.date)}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

DateSelectDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  date: PropTypes.number
}