import React from 'react';
import PropTypes from 'prop-types';
import { Grid, TextField } from '@material-ui/core';

export class Remarks extends React.PureComponent {

  constructor(props) {
    super(props);
  }

  onChanged(key, value, path = []) {
    path.unshift(this.props.dataGroupId);
    this.props.onChanged(key, value, path);
  }

  render () {
    return (
      <>
        <Grid container justify="space-around" alignItems="center" spacing={3}>
          <Grid item xs={12}>
            <TextField variant="outlined" value={this.props.remarks} label="Забележки"
              multiline rows={20}
              style={{ width: "100%" }}
              onChange={(event) => this.onChanged("remarks", event.target.value)}/>
          </Grid>
        </Grid>
      </>
    );
  }
}

Remarks.propTypes = {
  dataGroupId: PropTypes.string.isRequired,
  onChanged: PropTypes.func.isRequired,
  
  remarks: PropTypes.string,
}