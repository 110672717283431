import React from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton, List, ListItem, ListItemText, ListSubheader, Paper } from '@material-ui/core';
import { ToggleButton } from '@material-ui/lab';
import DeleteIcon from '@material-ui/icons/Delete';
import TreeViewComponent from '../../util/TreeViewComponent.js'
import { listCaseTypes } from '../../rest/RESTUtils';
import { recordsToTree } from '../../util/TreeDataUtils';

class SelectedCase extends React.PureComponent {

  constructor(props) {
    super(props)
  }

  getCaseTypeTitle(id) {
    return TreeViewComponent.getNode(this.props.caseTypes, false, id).title;
  }

  render() {
    return (
      <ListItem key={this.props.caseId}>
        <ListItemText primary={this.getCaseTypeTitle(this.props.caseId)}
                      secondary={
                        this.props.caseId.indexOf('|') != -1 ? 
                          this.getCaseTypeTitle(this.props.caseId.substring(0, this.props.caseId.indexOf("|"))) :
                          ''} />
        <ToggleButton value={this.props.caseId} selected={this.props.primary}
          onChange={(selected) => this.props.onPrimaryChanged(this.props.caseId, selected)}>
          Основна процедура
        </ToggleButton>
        <IconButton onClick={() => this.props.onCaseDeleted(this.props.caseId)}>
          <DeleteIcon />
        </IconButton>
      </ListItem>
    )
  }
}

SelectedCase.propTypes = {
  caseId: PropTypes.string.isRequired,
  caseTypes: PropTypes.object.isRequired,
  primary: PropTypes.bool,
  onPrimaryChanged: PropTypes.func.isRequired,
  onCaseDeleted: PropTypes.func.isRequired
}

export class CaseType extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      inputDialogCaseId: ""
    }
  }

  onChanged(key, value, path = []) {
    path.unshift(this.props.dataGroupId);
    this.props.onChanged(key, value, path);
  }

  onStateChanged(key, value) {
    this.setState({
      [key]: value
    })
  }

  onCaseSelected(caseId) {
    const selected = this.props.selectedCases || [];
    const selectedCase = TreeViewComponent.getNode(this.state.caseTypes, false, caseId);

    if (selectedCase && (!selectedCase.children || !selectedCase.children.length)) {
      if (selectedCase.single) {
        this.onChanged("selectedCases", 
          [
            { 
              "caseId": caseId, 
              "primary": true,
              "single": true
            }
          ]);
      } else if (selected && selected.length === 1 && selected[0].single) {
        this.onChanged("selectedCases", [ ...selected ]);
      } else if (selectedCase.input) {
        this.onStateChanged("inputDialogCaseId", caseId);
      } else {
        for (const current of selected) {
          if (current.caseId === caseId) {
            return null;
          }
        }
        
        selected.push({ "caseId": caseId, "primary": selected.length === 0 , "single": selectedCase.single || false});

        this.onChanged("selectedCases", [ ...selected ]);
      }
    }
  }

  onPrimaryChanged(caseId, value) {
    if (!value) {
      return;
    }

    const selected = this.props.selectedCases;

    if (TreeViewComponent.isLeafNode(this.state.caseTypes, false, caseId)) {
      for (let i = 0; i < selected.length; i++) {
        if (selected[i].caseId === caseId) {
          selected[i].primary = true;
        } else {
          selected[i].primary = false;
        }
      }

      this.onChanged("selectedCases", [ ...selected ]);
    }
  }

  onCaseDeleted(caseId) {
    const selected = this.props.selectedCases;

    if (TreeViewComponent.isLeafNode(this.state.caseTypes, false, caseId)) {
      for (let i = 0; i < selected.length; i++) {
        if (selected[i].caseId === caseId) {
          const deletedWasPrimary = selected[i].primary;
          selected.splice(i, 1);

          if (deletedWasPrimary && selected.length > 0) {
            selected[0].primary = true;
          }

          break;
        }
      }

      this.onChanged("selectedCases", [ ...selected ]);
    }
  }

  componentDidMount() {
    if (this.props.caseTypes) {
      this.setState({
        caseTypes: this.props.caseTypes
      })
    } else {
      listCaseTypes((caseTypes) => {
        this.setState({ 
          caseTypes: recordsToTree(caseTypes)
        })
      })
    }
  }

  render () {
    return (
      <>
        {this.state.caseTypes && (
          <Grid container justify="space-around" alignItems="center" spacing={3}>
            <Grid item xs={6}>
              <Paper style={{ height: 300 }}>
                <TreeViewComponent root={this.state.caseTypes} shouldShowRoot={false} 
                  onNodeSelect={(nodeIds) => this.onCaseSelected(nodeIds)}/>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper style={{ height: 300 }}>
                <List style={{ height: 290, overflow: "auto" }} subheader={
                  <ListSubheader component="div">
                    Избрани Процедури
                  </ListSubheader>
                }>
                  {
                    (this.props.selectedCases || []).map((selectedCase) => (
                      <SelectedCase key={selectedCase.caseId} {...selectedCase} 
                        caseTypes={this.state.caseTypes}
                        onPrimaryChanged={(id, value) => this.onPrimaryChanged(id, value)}
                        onCaseDeleted={(id) => this.onCaseDeleted(id)} />
                    ))
                  }
                </List>
              </Paper>
            </Grid>
          </Grid>
        )}
      </>
    );
  }
}

CaseType.propTypes = {
  onChanged: PropTypes.func.isRequired,
  dataGroupId: PropTypes.string.isRequired,

  selectedCases: PropTypes.array,
  caseTypes: PropTypes.array
}
