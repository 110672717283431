import './index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App.js';
import 'fontsource-roboto';
import UserUtils from './rest/UserUtils';

UserUtils.initKeycloak(() => {
  //React Render
  ReactDOM.render(<App />, document.getElementById('root'))
})
