import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, List, ListItem, ListItemText, ListSubheader, Paper } from '@material-ui/core'
import TreeViewComponent from '../../util/TreeViewComponent'
// import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
// import LooksOneIcon from '@material-ui/icons/LooksOne'
// import LooksTwoIcon from '@material-ui/icons/LooksTwo'
// import Looks3Icon from '@material-ui/icons/Looks3'
// import Looks4Icon from '@material-ui/icons/Looks4'
// import Looks5Icon from '@material-ui/icons/Looks5'
import PropTypes from 'prop-types'
// import { green, yellow, orange, red } from '@material-ui/core/colors'
import BooleanOptions from '../../util/BooleanOptions'
import TextInput from '../../util/TextInput'

const EVENTS = {
  'id': 'root',
  'title': 'root',
  'children': [
    {
      'id': 'bleeding',
      'title': 'Кървене'
    }, {
      'id': 'rhythm.problems',
      'title': 'Ритъмни нарушения',
      'children': [
        { 
          'id': 'av.block',
          'title': 'AV блок'
        },
        { 
          'id': 'tachicardia',
          'title': 'Тахикардия',
        }, {
          'id': 'fibrilation',
          'title': 'Фибрилация'
        }
      ]
    }, {
      'id': 'open.sternum',
      'title': 'Отворен стернум'
    }, {
      'id': 'ecmo',
      'title': 'ECMO'
    }, {
      'id': 'death',
      'title': 'Ex in tabulae'
    }
  ]
}

function getEventTitle(eventId) {
  const eventData = TreeViewComponent.getNode(EVENTS, false, eventId)
  return eventData.title
}

// const SEVERITY_LEVELS = [
//   { 'title': 'None', 'icon': <LooksOneIcon style={{ color: green[100] }} /> },
//   { 'title': 'Minor', 'icon': <LooksTwoIcon style={{ color: green[500] }} /> },
//   { 'title': 'Moderate', 'icon': <Looks3Icon style={{ color: yellow[500] }} /> },
//   { 'title': 'Major', 'icon': <Looks4Icon style={{ color: orange[500] }} /> },
//   { 'title': 'Catastrophic', 'icon': <Looks5Icon style={{ color: red[500] }} /> },
// ]

class SelectedEventComponent extends React.PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      editDialogOpen: false
    }
  }

  onEventEdited(level, timeToManage) {
    this.setState({editDialogOpen: false})

    if (level !== -1 && timeToManage !== -1) {
      this.props.onEventEdited(this.props.eventId, level, timeToManage)
    }
  }

  render() {
    return (
      <ListItem key={this.props.eventId}>
        <ListItemText primary={getEventTitle(this.props.eventId) + 
          (this.props.timeToManage > 0 ? ' (' + this.props.timeToManage + ' мин)' : '')}/>
        <IconButton onClick={() => this.setState({ editDialogOpen: true })}>
          <EditIcon />
        </IconButton>
        <IconButton onClick={() => this.props.onDeleted(this.props.eventId)}>
          <DeleteIcon />
        </IconButton>

        {this.state.editDialogOpen &&
          <EventDetailsDialog eventId={this.props.eventId} level={this.props.level} timeToManage={this.props.timeToManage}
            onClose={(level, timeToManage) => this.onEventEdited(level, timeToManage)} />
        }
      </ListItem>
    )
  }
}

SelectedEventComponent.propTypes = {
  eventId: PropTypes.string.isRequired,
  level: PropTypes.number.isRequired,
  timeToManage: PropTypes.number.isRequired,

  onEventEdited: PropTypes.func.isRequired,
  onDeleted: PropTypes.func.isRequired
}

class SelectedEvent {

  constructor(eventId, level, timeToManage) {
    this.eventId = eventId
    this.level = level
    this.timeToManage = timeToManage
  }
}

class EventDetailsDialog extends React.PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      level: this.props.level || '',
      timeToManage: this.props.timeToManage || ''
    }
  }

  onChanged(key, value) {
    this.setState({
      [key]: value
    })
  }

  render() {
    return (
      <Dialog onClose={() => this.props.onClose(-1, -1)} open={true}>
        <DialogTitle>Детайли</DialogTitle>
        <DialogContent>
          <Grid container justify='space-around' alignItems='center' spacing={3}>
            <Grid item xs={6}>
              <TextInput variant='standard' value={getEventTitle(this.props.eventId)} label='Усложнение' disabled/>
            </Grid>
            <Grid item xs={6}/>

            <Grid item xs={6}>
              <TextInput 
                value={this.state.timeToManage}
                type='number'
                label='Време'
                adornment='мин.'
                onValueChanged={value => this.onChanged('timeToManage', value)} />
            </Grid>
            <Grid item xs={6}/>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.props.onClose(-1, -1)} color='primary'>
            Затвори
          </Button>
          <Button disabled={this.state.level == -1 || this.state.timeToManage == -1} color='primary'
            onClick={() => this.props.onClose(parseInt(this.state.level), parseInt(this.state.timeToManage))}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

EventDetailsDialog.propTypes = {
  eventId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  level: PropTypes.number,
  timeToManage: PropTypes.number
}

export class AdverseEvents extends React.PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      currentEventId: null
    }
  }

  onChanged(key, value, path = []) {
    path.unshift(this.props.dataGroupId)
    this.props.onChanged(key, value, path)
  }

  onStateChanged(key, value) {
    this.setState({
      [key]: value
    })
  }

  onEventDeleted(eventId) {
    const selected = this.props.selectedEvents
    
    for (let i = 0; i < selected.length; i++) {
      if (selected[i].eventId === eventId) {
        selected.splice(i, 1)
        this.onChanged('selectedEvents', selected)
        break
      }
    }
  }

  onEventEdited(eventId, level, timeToManage) {
    const selected = this.props.selectedEvents
    
    for (let i = 0; i < selected.length; i++) {
      if (selected[i].eventId === eventId) {
        selected[i].level = level
        selected[i].timeToManage = timeToManage

        this.onChanged('selectedEvents', selected)
        break
      }
    }
  }

  onEventAdded(eventId, level, timeToManage) {
    this.onStateChanged('currentEventId', null)

    if (level !== -1 && timeToManage !== -1) {
      const selected = this.props.selectedEvents || []
      
      if (this.isEvent(eventId)) {
        let found = null

        for (const current of selected) {
          if (current.eventId === eventId) {
            found = current
            break
          }
        }
        
        if (found) {
          found.level = level
          found.timeToManage = timeToManage
        } else {
          selected.push(new SelectedEvent(eventId, level, timeToManage))
        }

        this.onChanged('selectedEvents', [ ...selected ])
      }
    }
  }

  isEvent(eventId) {
    const eventData = TreeViewComponent.getNode(EVENTS, false, eventId)
    return eventData && !eventData.children
  }

  render () {
    return (
      <Grid container justify='space-around' alignItems='center' spacing={3}>
          <Grid item xs={3}>
            <BooleanOptions value={this.props.eventOccurred} title='Имало ли е усложнения?'
              exclusive={true}
              onValueChanged={(newValue) => this.onChanged('eventOccurred', newValue)} />
          </Grid>
          <Grid item xs={9}/>

          {this.props.eventOccurred && (
            <>
              <Grid item xs={6}>
                <Paper style={{ minHeight: 300 }}>
                  <TreeViewComponent root={EVENTS} showRootNode={false} 
                    onNodeSelect={(nodeId) => this.onStateChanged('currentEventId', nodeId)} />
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper style={{ minHeight: 300 }}>
                  <List subheader={
                    <ListSubheader component='div'>
                      Усложнения
                    </ListSubheader>
                  }>
                    {
                      (this.props.selectedEvents || []).map((selectedEvent) => (
                        <SelectedEventComponent key={selectedEvent.eventId} eventId={selectedEvent.eventId} 
                          level={selectedEvent.level} timeToManage={selectedEvent.timeToManage} 
                          onEventEdited={(eventId, level, timeToManage) => this.onEventEdited(eventId, level, timeToManage)}
                          onDeleted={(eventId) => this.onEventDeleted(eventId)} />
                      ))
                    }
                  </List>
                </Paper>
              </Grid>
            </>
          )}

          {this.state.currentEventId && this.isEvent(this.state.currentEventId) &&
            <EventDetailsDialog eventId={this.state.currentEventId} 
              onClose={(level, timeToManage) => this.onEventAdded(this.state.currentEventId, level, timeToManage)} />
          }
        </Grid>
    )
  }
}

AdverseEvents.propTypes = {
  onChanged: PropTypes.func.isRequired,
  dataGroupId: PropTypes.string.isRequired,

  selectedEvents: PropTypes.array,
  eventOccurred: PropTypes.bool,
  deathAsResult: PropTypes.bool,
  unexpectedCatheterizationAsResult: PropTypes.bool,
  unexpectedSurgeryAsResult: PropTypes.bool,
  eventDetails: PropTypes.string
}