import React from 'react';
import PropTypes from 'prop-types';
import { Grid, ListItem, TextField } from '@material-ui/core';
import BooleanResultToggleButtons from '../../util/BooleanResultToggleButtons';

export class CoarctationProcedure extends React.PureComponent {

  constructor(props) {
    super(props);
  }

  onChanged(key, value, path = []) {
    path.unshift(this.props.dataGroupId);
    this.props.onChanged(key, value, path);
  }

  render() {
    return (
      <ListItem>
        <Grid container justify="space-around" alignItems="center" spacing={3}>
          <Grid item xs={3}>
            Процедура за коарктация
          </Grid>
          <Grid item xs={3}>
            <BooleanResultToggleButtons value={this.props.performed} 
              onValueChange={(newValue) => this.onChanged("performed", newValue)} />
          </Grid>
          <Grid item xs={6} />

          {this.props.performed && (
            <>
              <Grid item xs={6}>
                <TextField style={{ width: "100%" }} label="Индикации" variant="outlined" multiline rows={2} 
                  value={this.props.indications}
                  onChange={(event) => this.onChanged("indications", event.target.value)}/>
              </Grid>
              <Grid item xs={6} />

              <Grid item xs={3}>
                Постпроцедурни аневризми
              </Grid>
              <Grid item xs={3}>
                <BooleanResultToggleButtons 
                  value={this.props.postProcedureAneurysms}
                  onValueChange={(newValue) => this.onChanged("postProcedureAneurysms", newValue)} />
              </Grid>
              <Grid item xs={6} />

              <Grid item xs={3}>
                Миграция на стента
              </Grid>
              <Grid item xs={3}>
                <BooleanResultToggleButtons 
                  value={this.props.stentMigration}
                  onValueChange={(newValue) => this.onChanged("stentMigration", newValue)} />
              </Grid>
              <Grid item xs={6} />

              <Grid item xs={3}>
                Jailing of head/neck vessels
              </Grid>
              <Grid item xs={3}>
                <BooleanResultToggleButtons 
                  value={this.props.vesselsJailing}
                  onValueChange={(newValue) => this.onChanged("vesselsJailing", newValue)} />
              </Grid>
              <Grid item xs={6} />
            </>
          )}
        </Grid>
      </ListItem>
    );
  }
}

CoarctationProcedure.propTypes = {
  onChanged: PropTypes.func.isRequired,
  dataGroupId: PropTypes.string.isRequired,

  performed: PropTypes.bool,
  indications: PropTypes.string,

  postProcedureAneurysms: PropTypes.bool,
  stentMigration: PropTypes.bool,
  vesselsJailing: PropTypes.bool,
}
