import React from 'react';
import CaseInformation from './CaseInformation';
import { addCase, getCurrentCaseProtocol } from '../rest/RESTUtils'

class AddCase extends React.PureComponent {

  constructor(props) {
    super(props);
  }

  render () {
    return (
      <CaseInformation 
        addCase={addCase}
        getCurrentCaseProtocol={getCurrentCaseProtocol}/>
    );
  }
}

export default AddCase;
