import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { calculateBodyArea } from '../../util/Formulas'
import MultipleChoiceOptions from '../../util/MultipleChoiceOptions'
import TextInput from '../../util/TextInput'

export class PhysicalInformation extends React.PureComponent {

  constructor(props) {
    super(props)
  }

  onChanged(key, value, path = []) {
    path.unshift(this.props.dataGroupId)
    this.props.onChanged(key, value, path)
  }

  calculateAge(age) {
    if (age) {
      return (age.years ? age.years + 'г ' : '') + 
             (age.months ? age.months + 'м ' : '') + 
             (age.days ? age.days + 'д ' : '')
    } else {
      return ''
    }
  }

  render() {
    return (
      <Grid container justify='space-around' alignItems='center' spacing={3} >
        <Grid item xs={3}>
          <TextInput 
            value={this.calculateAge(this.props.age)}
            label='Възраст' disabled/>
        </Grid>
        <Grid item xs={3}>
          <MultipleChoiceOptions title='Пол:' 
            labels={[ 'Мъжки', 'Женски' ]} 
            exclusive={true} 
            value={this.props.gender} onValueChanged={value => this.onChanged('gender', value)}
          />
        </Grid>
        <Grid item xs={6} />

        <Grid item xs={3}>
          <TextInput 
            value={this.props.height}
            label='Ръст' type='number' adornment='cm'
            onValueChanged={value => this.onChanged('height', value)} />
        </Grid>
        <Grid item xs={3}>
          <TextInput 
            value={this.props.weight}
            label='Тегло' type='number' adornment='kg'
            onValueChanged={value => this.onChanged('weight', value)} />
        </Grid>
        <Grid item xs={6} />

        <Grid item xs={3}>
          <TextInput 
            value={calculateBodyArea({height: this.props.height, weight: this.props.weight})}
            label='Телесна повърхност' disabled />
        </Grid>
        <Grid item xs={9} />
      </Grid>
    )
  }
}

PhysicalInformation.propTypes = {
  onChanged: PropTypes.func.isRequired,
  dataGroupId: PropTypes.string.isRequired,

  age: PropTypes.object,
  gender: PropTypes.number,
  height: PropTypes.number,
  weight: PropTypes.number,
  hemoglobin: PropTypes.number
}
