import React from 'react'
import PropTypes from 'prop-types'
import { Grid, TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

const OPERATORS = [
  'Д-р Стоян Лазаров', 
  'Д-р Ивайло Митев', 
  'Д-р Георги Константинов', 
  'Д-р Венцислав Бошнаков'
]

const SURGICAL_NURSES = [
  'Мариела Кръстева',
  'Цветелина Бориславова',
  'Цветелина Петкова',
  'Нели Николова'
]

const PERFUSIONISTS = [
  'Людмила Николова', 
  'Златка Сидова'
]

const ANESTHESIOLOGISTS = [
  'д-р Здравко Беров',
  'д-р Йоанна Генчева'
]

export class Protocol extends React.PureComponent {

  constructor(props) {
    super(props)
  }

  onChanged(key, value, path = []) {
    path.unshift(this.props.dataGroupId)
    this.props.onChanged(key, value, path)
  }

  getCombo(options, propId, propLabel) {
    return <Autocomplete
      id={propId}
      onChange={(event) => this.onChanged(propId, event.target.textContent)}
      freeSolo
      options={options}
      value={this.props[propId]}
      renderInput={(params) => 
        <TextField {...params} variant='outlined' value={this.props[propId]} label={propLabel}
          style={{ width: '100%' }}
          onChange={(event) => this.onChanged(propId, event.target.value)}/>
      }
    />
  }

  render () {
    return (
      <Grid container justify='space-around' alignItems='center' spacing={3}>
        <Grid item xs={6}>
          {this.getCombo(OPERATORS, 'firstOperator', 'Оператор')}
        </Grid>
        <Grid item xs={6}>
          {this.getCombo(ANESTHESIOLOGISTS, 'anesthesiologist', 'Анестезиолог')}
        </Grid>
        <Grid item xs={6}>
          {this.getCombo(OPERATORS, 'secondOperator', 'Асистент')}
        </Grid>
        <Grid item xs={6}>
          {this.getCombo(ANESTHESIOLOGISTS, 'secondAnesthesiologist', 'Анестезиолог')}
        </Grid>
        <Grid item xs={6}>
          {this.getCombo(OPERATORS, 'thirdOperator', 'Асистент')}
        </Grid>
        <Grid item xs={6}/>
        <Grid item xs={6}>
          {this.getCombo(SURGICAL_NURSES, 'nurse', 'Инструментатор')}
        </Grid>
        <Grid item xs={6}>
          {this.getCombo(PERFUSIONISTS, 'perfusionist', 'Перфузионист')}
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={12}>
          <TextField variant='outlined' value={this.props.protocol} label='Протокол'
            multiline rows={30}
            style={{ width: '100%' }}
            onChange={(event) => this.onChanged('protocol', event.target.value)}/>
        </Grid>
      </Grid>
    )
  }
}

Protocol.propTypes = {
  dataGroupId: PropTypes.string.isRequired,
  onChanged: PropTypes.func.isRequired,
  
  protocol: PropTypes.string,

  firstOperator: PropTypes.string,
  secondOperator: PropTypes.string,
  thirdOperator: PropTypes.string,

  anesthesiologist: PropTypes.string,
  secondAnesthesiologist: PropTypes.string,

  nurse: PropTypes.string,
  perfusionist: PropTypes.string
}
