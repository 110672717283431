import React from 'react'

class Home extends React.Component {

  constructor(props) {
    super(props);
  }

  render () {
    return (
      <>
        <h2 style={{textAlign: 'center'}}>Регистър за катетеризации</h2>
        <div style={{textAlign: 'center'}}>Позволява въвеждане, редактиране и търсене на катетеризации</div>
      </>
    )
  }
}

export default Home;
