import { Fab, Grid,  Paper,  TextField } from '@material-ui/core'
import React, { PureComponent } from 'react'
import TreeViewComponent from '../util/TreeViewComponent'
import SearchIcon from '@material-ui/icons/Search'
import ClearIcon from '@material-ui/icons/Clear'
import PropTypes from 'prop-types'
import DateInput from '../util/DateInput'

export class AdvancedSearch extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      startDate: null,
      endDate: null,
      name: null,
      caseType: null
    }
  }

  onStateChanged(key, value) {
    this.setState({
      [key]: value
    })
  }

  onClearPerformed() {
    this.setState({
      startDate: null,
      endDate: null,
      name: null,
      caseType: null
    })
  }

  render() {
    return (
      <Paper>
      <Grid style={{ padding: '5px', height: 770 }} container justify="space-around" alignItems="center" spacing={3}>
        <Grid item xs={12}>
          <h2 style={{ textAlign: 'center' }}>Търсене</h2>
        </Grid>
        <Grid item xs={12}>
          <TextField style={{ width: "100%" }} value={this.state.name || ""} label="Име"
            onChange={(event) => this.onStateChanged("name", event.target.value)}/>
        </Grid>
        <Grid item xs={6}>
          <DateInput label='Начална дата'
            value={this.state.startDate}
            onValueChanged={value => this.onStateChanged("startDate", value)}/>
        </Grid>
        <Grid item xs={6}>
          <DateInput label='Крайна дата'
            value={this.state.endDate}
            onValueChanged={value => this.onStateChanged("endDate", value)}/>
        </Grid>
        <Grid item xs={12}>
          <h5>Тип на процедурата</h5>
          <TreeViewComponent root={this.props.caseTypes} shouldShowRoot={false} 
              onNodeSelect={(nodeIds) => this.onStateChanged("caseType", nodeIds)}/>
        </Grid>
        <Grid item xs={3}>
          <Fab variant="extended" onClick={() => 
            this.props.onSearchPerformed(this.state.startDate, this.state.endDate, 
                                         this.state.name, this.state.caseType )}>
            <SearchIcon /> 
            Търси
          </Fab>
        </Grid>
        <Grid item xs={3}>
          <Fab variant="extended" onClick={() => this.onClearPerformed()}>
            <ClearIcon />
            Изчисти
          </Fab>
        </Grid>
        <Grid item xs={6}/>
      </Grid>
      </Paper>
    )
  }
}

AdvancedSearch.propTypes = {
  onSearchPerformed: PropTypes.func.isRequired,
  caseTypes: PropTypes.object.isRequired
}