import React from 'react'
import { PropTypes } from 'prop-types'
import { Grid, TextField } from '@material-ui/core'

class MeasurementData {
  
  constructor(id, title) {
    this.id = id
    this.title = title
  }
}

const MEASUREMENTS = [
  new MeasurementData('maxPressure', 'P max (mmHg)'),
  new MeasurementData('minPressure', 'P min (mmHg)'),
  new MeasurementData('meanPressure', 'P mean (mmHg)')
]

class TypeData {
  
  constructor(id, title) {
    this.id = id
    this.title = title
  }
}

const TYPES = [ 
  new TypeData('vcs', 'VCS'), 
  new TypeData('vci', 'VCI'), 
  new TypeData('ra', 'RA'), 
  new TypeData('rv', 'RV'), 
  new TypeData('mpa', 'MPA'), 
  new TypeData('lpa', 'LPA'), 
  new TypeData('rpa', 'RPA'), 
  new TypeData('la', 'LA'), 
  new TypeData('lv', 'LV'), 
  new TypeData('aoasc', 'Ao Asc'), 
  new TypeData('aodesc', 'Ao Desc')
]

class TypeMeasurementsData extends React.PureComponent {

  constructor(props) {
    super(props);
  }

  onChanged(key, value, path = []) {
    path.unshift(this.props.type.id);
    this.props.onChanged(key, value, path);
  }

  render() {
    return (
      <>
        <Grid item xs={1}>
          <TextField disabled variant="outlined" value={this.props.type.title} />
        </Grid>
        {
          MEASUREMENTS.map((measurement, index) => (
            <Grid key={this.props.type.id + measurement.id} item xs={1}>
              <TextField variant="outlined" 
                value={(this.props[this.props.type.id] && this.props[this.props.type.id][MEASUREMENTS[index].id]) || ""} 
                onChange={(event) => this.onChanged(MEASUREMENTS[index].id, parseFloat(event.target.value))}/>
            </Grid>
          ))
        }
        <Grid item xs={8}/>
      </>
    );
  }
}

TypeMeasurementsData.propTypes = {
  onChanged: PropTypes.func.isRequired,
  type: PropTypes.object.isRequired
}

class CatheterizationMeasurements extends React.PureComponent {

  constructor(props) {
    super(props)
  }

  onChanged(key, value, path = []) {
    path.unshift(this.props.dataGroupId)
    this.props.onChanged(key, value, path)
  }

  render () {
    return (
      <>
        <Grid container justify='center' alignItems='center' spacing={0} columns={10}>
          <Grid item xs={1}>
            <TextField disabled variant="outlined"/>
          </Grid>
          {
            MEASUREMENTS.map((measurement) => (
              <Grid key={measurement.id} item xs={1}>
                <TextField disabled variant='outlined' value={measurement.title} />
              </Grid>
            ))
          }
          <Grid item xs={8}/>

          {
            TYPES.map((type) => (
              <TypeMeasurementsData key={type.id} type={type}
                {...(this.props || {})}
                onChanged={(key, value, path) => this.onChanged(key, value, path)}/>
            ))
          }
        </Grid>
      </>
    )
  }
}

CatheterizationMeasurements.propTypes = {
  onChanged: PropTypes.func.isRequired,
  dataGroupId: PropTypes.string.isRequired
}

export default CatheterizationMeasurements
