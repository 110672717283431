import React from 'react';
import { PureComponent } from 'react';
import PendingTable from './PendingTable';
import { listPending, listSurgicalPending, listCaseTypes, listDiagnoses } from '../rest/RESTUtils'
import { recordsToTree } from '../util/TreeDataUtils';
import WeeklyPlan from './WeeklyPlan';

class Planning extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      caseTypes: [],
      diagnoses: []
    }
  }
  
  init() {
    listCaseTypes(caseTypes => {
      listDiagnoses(diagnoses => {
        listPending((pending) => {
          listSurgicalPending((surgicalPending) => {
            pending.forEach(element => {
              element.local = true
            });

            this.setState({
              caseTypes: recordsToTree(caseTypes),
              diagnoses: recordsToTree(diagnoses),
              pending: pending,
              surgicalPending: surgicalPending
            })
          })
        })
      })
    })
  }

  onPendingChanged() {
    listPending((pending) => {
      pending.forEach(element => {
        element.local = true
      });

      this.setState({
        pending: pending
      })
    })
  }

  componentDidMount() {
    this.init();
  }

  render () {
    const allPending = []
    Array.prototype.push.apply(allPending, this.state.pending)
    Array.prototype.push.apply(allPending, this.state.surgicalPending)

    return (
      <>
        { this.state.pending && (
          <>
            <PendingTable caseTypes={this.state.caseTypes} diagnoses={this.state.diagnoses} pending={this.state.pending} 
              onChanged={() => this.onPendingChanged()}/>
            <WeeklyPlan caseTypes={this.state.caseTypes} diagnoses={this.state.diagnoses} pending={allPending}/>
          </>
        )}
      </>
    )
  }
}

export default Planning;
