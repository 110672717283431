import React from 'react'
import { PureComponent } from 'react'
import RevatioPendingTable from './RevatioPendingTable'
import { listRevatioPending, listDiagnoses } from '../rest/RESTUtils'
import { recordsToTree } from '../util/TreeDataUtils'
import WeeklyPlan from './WeeklyPlan'

class RevatioPlanning extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      caseTypes: [],
      diagnoses: []
    }
  }
  
  init() {
    listDiagnoses(diagnoses => {
      listRevatioPending((pending) => {
        this.setState({
          diagnoses: recordsToTree(diagnoses),
          pending: pending,
        })
      })
    })
  }

  onPendingChanged() {
    listRevatioPending((pending) => {
      this.setState({
        pending: pending
      })
    })
  }

  componentDidMount() {
    this.init()
  }

  render () {
    return (
      <>
        { this.state.pending && (
          <>
            <RevatioPendingTable caseTypes={this.state.caseTypes} diagnoses={this.state.diagnoses} pending={this.state.pending} 
              onChanged={() => this.onPendingChanged()}/>
            <WeeklyPlan caseTypes={this.state.caseTypes} diagnoses={this.state.diagnoses} pending={this.state.pending}/>
          </>
        )}
      </>
    )
  }
}

export default RevatioPlanning
