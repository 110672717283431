import React from 'react'
import { Grid, TextField } from '@material-ui/core'
import BooleanResultToggleButtons from '../../util/BooleanResultToggleButtons'
import PropTypes from 'prop-types'

export class AdverseEvents extends React.PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      currentEventId: null
    }
  }

  onChanged(key, value, path = []) {
    path.unshift(this.props.dataGroupId)
    this.props.onChanged(key, value, path)
  }

  onStateChanged(key, value) {
    this.setState({
      [key]: value
    })
  }

  render () {
    return (
      <Grid container justify='space-around' alignItems='center' spacing={3}>
          <Grid item xs={3}>
            Имало ли е усложнения?
          </Grid>
          <Grid item xs={3}>
            <BooleanResultToggleButtons value={this.props.eventOccurred} onValueChange={(newValue) => this.onChanged('eventOccurred', newValue)} />
          </Grid>
          <Grid item xs={6} />

          {this.props.eventOccurred && (
            <>
              <Grid item xs={12}>
                <TextField style={{ width: '100%' }} variant='outlined' label='Усложнения' multiline rows={10} rowsMax={10}
                  value={this.props.adverseEvents || ''}
                  onChange={(event) => this.onChanged('adverseEvents', event.target.value)}/>    
              </Grid>
            </>
          )}
        </Grid>
    )
  }
}

AdverseEvents.propTypes = {
  onChanged: PropTypes.func.isRequired,
  dataGroupId: PropTypes.string.isRequired,

  eventOccurred: PropTypes.bool,
  adverseEvents: PropTypes.string
}