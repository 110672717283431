import React from 'react';
import { PropTypes } from 'prop-types'
import { Button, Grid, IconButton, TextField } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { calculateCoO2, calculateQp, calculateQps, calculateQpd, calculateQpQs, calculateQpsQs, calculateQpdQs, calculateQs, 
         calculateRp, calculateRps, calculateRpd, calculateRpi, calculateRpsi, calculateRpdi, calculateCI,
         calculateRpRs, calculateRpsRs, calculateRpdRs, calculateRs,
         calculateVdAp, calculateApAps, calculateApApd, calculateVsAo, calculateAoascAodesc } from '../../util/Formulas';

class MeasurementData {
  
  constructor(id, title) {
    this.id = id;
    this.title = title;
  }
}

const MEASUREMENTS = [
  new MeasurementData("maxPressure", "P max (mmHg)"),
  new MeasurementData("minPressure", "P min (mmHg)"),
  new MeasurementData("meanPressure", "P mean (mmHg)"),
  new MeasurementData("oxygenSaturation", "Sat O2 (%)"),
  new MeasurementData("oxygenPartialPressure", "P O2"),
  new MeasurementData("oxygenContent", "Co O2 (%)")
]

class TypeData {
  
  constructor(id, title) {
    this.id = id;
    this.title = title;
  }
}

const TYPES = [
  new TypeData("mvs", "MVS"), 
  new TypeData("vcs", "VCS"), 
  new TypeData("vci", "VCI"), 
  new TypeData("ad", "Ad"), 
  new TypeData("vdIn", "Vd вход"), 
  new TypeData("vdOut", "Vd изход"), 
  new TypeData("ap", "AP ствол"), 
  new TypeData("aps", "APS"), 
  new TypeData("apd", "APD"), 
  new TypeData("vps", "VPS"), 
  new TypeData("vpd", "VPD"), 
  new TypeData("pc", "PC"), 
  new TypeData("as", "AS"), 
  new TypeData("vsIn", "VS вход"), 
  new TypeData("vsOut", "VS изход"), 
  new TypeData("aoAsc", "Ao acц"), 
  new TypeData("aoDesc", "Ao десц")
]

class AdditionalInfo {
  
  constructor(title, id, calculate) {
    this.title = title;
    this.id = id;
    this.calculate = calculate;
  }
}

const ADDITIONAL_INFOS = [
  new AdditionalInfo('Qp', 'qp', calculateQp),
  new AdditionalInfo('Qs', 'qs', calculateQs),
  new AdditionalInfo('CI', 'ci', calculateCI),
  null,
  new AdditionalInfo('Qp/Qs', 'qpqs', calculateQpQs),
  null,
  new AdditionalInfo('Rp', 'rp', calculateRp),
  null,
  new AdditionalInfo('Rs', 'rs', calculateRs),
  null,
  new AdditionalInfo('Rp/Rs', 'rprs', calculateRpRs),
  null,
  new AdditionalInfo('Rpi', 'rpi', calculateRpi),
]

const ADDITIONAL_INFOS_SEPARATE_SD = [
  new AdditionalInfo('Qps', 'qps', calculateQps),
  new AdditionalInfo('Qpd', 'qpd', calculateQpd),
  new AdditionalInfo('Qs', 'qs', calculateQs),
  new AdditionalInfo('CI', 'ci', calculateCI),
  new AdditionalInfo('Qps/Qs', 'qpsqs', calculateQpsQs),
  new AdditionalInfo('Qpd/Qs', 'qpdqs', calculateQpdQs),
  null,
  new AdditionalInfo('Rps', 'rps', calculateRps),
  new AdditionalInfo('Rpd', 'rpd', calculateRpd),
  new AdditionalInfo('Rs', 'rs', calculateRs),
  null,
  new AdditionalInfo('Rps/Rs', 'rpsrs', calculateRpsRs),
  new AdditionalInfo('Rpd/Rs', 'rpdrs', calculateRpdRs),
  null,
  new AdditionalInfo('Rpsi', 'rpsi', calculateRpsi),
  new AdditionalInfo('Rpdi', 'rpdi', calculateRpdi)
]

const GRADIENTS = [
  new AdditionalInfo('Vd изход - AP ствол', 'vdap', calculateVdAp),
  null,
  new AdditionalInfo('AP ствол - APS', 'apaps', calculateApAps),
  null,
  new AdditionalInfo('AP ствол - APD', 'apapd', calculateApApd),
  null,
  new AdditionalInfo('VS изход - Ао асц', 'vsaoasc', calculateVsAo),
  null,
  new AdditionalInfo('Ао асц - Ао десц', 'aoascaodesc', calculateAoascAodesc),
  null,
  null,
  null,
  null
]

class TypeMeasurementsData extends React.PureComponent {

  constructor(props) {
    super(props);
  }

  onChanged(key, value, path = []) {
    path.unshift(this.props.type.id);
    this.props.onChanged(key, value, path);

    if (key === 'oxygenSaturation' && (this.props.type.id === 'vcs' || this.props.type.id === 'vci')) {
      let vcsOS = this.props.type.id === 'vcs' ? value : this.props['vcs'] && this.props['vcs']['oxygenSaturation']
      let vciOS = this.props.type.id === 'vci' ? value : this.props['vci'] && this.props['vci']['oxygenSaturation']

      if (vcsOS || vciOS) {
        let mvs = 0
        let count = 0

        if (vcsOS) {
          mvs += 3 * vcsOS
          count += 3
        }

        if (vciOS) {
          mvs += vciOS
          count += 1
        }

        mvs = Math.round(mvs / count)

        this.props.onChanged('oxygenSaturation', mvs, ['mvs'])
      } else {
        this.props.onChanged('oxygenSaturation', '', ['mvs'])
      }
    }
  }

  getAdditionalInfo() {
    if (this.props.additionalInfo.title) {
      return (
        <>
          <Grid item xs={1} />
          <Grid item xs={1}>
            <TextField variant="outlined" disabled 
              value={this.props.additionalInfo.value || ""} 
              label={this.props.additionalInfo.title} />
          </Grid>

          {this.props.gradient && this.props.gradient.title ? 
            <>
              <Grid item xs={1}/>
              <Grid item xs={1}>
                <TextField variant="outlined" disabled 
                  value={this.props.gradient.value || ""} 
                  label={this.props.gradient.title} />
              </Grid>
              <Grid item xs={1} />
            </> :
            <Grid item xs={3} />}
        </>
      )
    } else {
      if (this.props.gradient && this.props.gradient.title) {
        return (
          <>
            <Grid item xs={3} />
            <Grid item xs={1}>
              <TextField variant="outlined" disabled 
                value={this.props.gradient.value || ""} 
                label={this.props.gradient.title} />
            </Grid>
            <Grid item xs={1} />
          </>
        )
      } else {
        return <Grid item xs={5} />
      }
    }
  }

  calculateCoO2ForMeasurement() {
    if (this.props[this.props.type.id]) {
      let result = calculateCoO2(this.props[this.props.type.id]['oxygenSaturation'], this.props.hemoglobin)

      if (this.props[this.props.type.id]['oxygenContent'] !== result) {
        this.onChanged('oxygenContent', result)
      }

      return result ? result.toFixed(2) : result
    } else {
      return ""
    }
  }

  getMeasurementField(index) {
    if (index === 5) { // oxygen content is calculated
      return (
        <TextField variant="outlined" value={this.calculateCoO2ForMeasurement()} disabled />
      )
    } else {
      return (
        <TextField variant="outlined" 
          value={(this.props[this.props.type.id] && this.props[this.props.type.id][MEASUREMENTS[index].id]) || ""} 
          onChange={(event) => this.onChanged(MEASUREMENTS[index].id, parseFloat(event.target.value))}/>
      )
    }
  }

  render() {
    return (
      <>
        <Grid item xs={1}>
          <TextField disabled variant="outlined" value={this.props.type.title} />
        </Grid>
        {
          MEASUREMENTS.map((measurement, index) => (
            <Grid key={this.props.type.id + measurement.id} item xs={1}>
              {this.getMeasurementField(index)}
            </Grid>
          ))
        }

        {this.getAdditionalInfo()}
      </>
    );
  }
}

TypeMeasurementsData.propTypes = {
  onChanged: PropTypes.func.isRequired,
  type: PropTypes.object.isRequired,
  hemoglobin: PropTypes.number,
  additionalInfo: PropTypes.object,
  gradient: PropTypes.object,
  vcs: PropTypes.object,
  vci: PropTypes.object
}

class CatheterizationMeasurements extends React.PureComponent {

  constructor(props) {
    super(props);
  }

  onChanged(group, key, value, path = []) {
    path.unshift(this.props.dataGroupId, group);
    this.props.onChanged(key, value, path);
  }

  calculateAdditionalInfo(additionalInfo, props, group, isFloat) {
    if (!props) {
      return ''
    }

    let propsCopy = Object.assign({}, props)
    propsCopy['measurementsData'] = props[group]
    let result = additionalInfo.calculate(propsCopy)

    if (!props[group] || props[group][additionalInfo.id] !== result) {
      this.onChanged(group, additionalInfo.id, result)
    }

    if (isFloat) {
      return result ? result.toFixed(2) : result
    } else {
      return result ? result.toString() : result
    }
  }

  needsSeparateSD() {
    return this.props.measurementsData && this.props.measurementsData['aps'] && this.props.measurementsData['apd'] &&
      this.props.measurementsData['aps']['oxygenSaturation'] && this.props.measurementsData['apd']['oxygenSaturation'] &&
      this.props.measurementsData['aps']['oxygenSaturation'] !== this.props.measurementsData['apd']['oxygenSaturation'] 
  }

  render () {
    const additionalInfos = this.needsSeparateSD() ? ADDITIONAL_INFOS_SEPARATE_SD : ADDITIONAL_INFOS;
    return (
      <>
        <Grid container justify="center" alignItems="center" spacing={0} columns={10}>
          <Grid item xs={1}>
            <TextField disabled variant="outlined" value={this.props.hasAfter || this.props.isVasodilation ? 'Преди' : ''} />
          </Grid>
          {
            MEASUREMENTS.map((measurement) => (
              <Grid key={measurement.id} item xs={1}>
                <TextField disabled variant="outlined" value={measurement.title} />
              </Grid>
            ))
          }
          <Grid item xs={5} />
          {
            TYPES.map((type, index) => (
              <TypeMeasurementsData key={type.id} type={type}
                {...(this.props.measurementsData || {})}
                hemoglobin={this.props.hemoglobin}
                onChanged={(key, value, path) => this.onChanged('measurementsData', key, value, path)} 
                additionalInfo={{ 
                  title: additionalInfos[index] && additionalInfos[index].title, 
                  value: additionalInfos[index] && this.calculateAdditionalInfo(additionalInfos[index], this.props, 'measurementsData', true)
                }}
                gradient={{ 
                  title: GRADIENTS[index] && GRADIENTS[index].title, 
                  value: GRADIENTS[index] && this.calculateAdditionalInfo(GRADIENTS[index], this.props, 'measurementsData', false)
                }} />
            ))
          }
        </Grid>

        {!this.props.hasAfter && !this.props.isVasodilation &&
          <Button onClick={() => this.props.onChanged('hasAfter', true, [this.props.dataGroupId])} color="primary" component="span" size="large" startIcon={<AddCircleIcon />}>
            Добави &apos;След&apos;
          </Button>
        }

        {(this.props.hasAfter || this.props.isVasodilation) &&
          <Grid container justify="center" alignItems="center" spacing={0}>
            <Grid item xs={12}>&nbsp;</Grid>
            <Grid item xs={1}>
              <TextField disabled variant="outlined" value="След" />
            </Grid>
            {
              MEASUREMENTS.map((measurement) => (
                <Grid key={measurement.id} item xs={1}>
                  <TextField disabled variant="outlined" value={measurement.title} />
                </Grid>
              ))
            }
            {!this.props.isVasodilation ? 
              <>
                <Grid item xs={1} >
                  <IconButton onClick={() => this.props.onChanged('hasAfter', false, [this.props.dataGroupId])} color="secondary" component="span">
                    <RemoveCircleIcon fontSize="large" />
                  </IconButton>
                </Grid>
                <Grid item xs={4} />
              </> :
              <Grid item xs={5} />
            }
            
            {
              TYPES.map((type, index) => (
                <TypeMeasurementsData key={type.id} type={type}
                  {...(this.props.measurementsDataAfter || {})}
                  hemoglobin={this.props.hemoglobin}
                  onChanged={(key, value, path) => this.onChanged('measurementsDataAfter', key, value, path)} 
                  additionalInfo={{ 
                    title: ADDITIONAL_INFOS[index] && ADDITIONAL_INFOS[index].title, 
                    value: ADDITIONAL_INFOS[index] && this.calculateAdditionalInfo(ADDITIONAL_INFOS[index], this.props, 'measurementsDataAfter', true)
                  }}
                  gradient={{ 
                    title: GRADIENTS[index] && GRADIENTS[index].title, 
                    value: GRADIENTS[index] && this.calculateAdditionalInfo(GRADIENTS[index], this.props, 'measurementsDataAfter', false)
                  }} />
              ))
            }
          </Grid>
        }
      </>
    );
  }
}

CatheterizationMeasurements.propTypes = {
  onChanged: PropTypes.func.isRequired,
  dataGroupId: PropTypes.string.isRequired,

  measurementsData: PropTypes.object,
  measurementsDataAfter: PropTypes.object,
  hasAfter: PropTypes.bool,

  isVasodilation: PropTypes.bool,

  hemoglobin: PropTypes.number,
  critical: PropTypes.bool,
  bodyArea: PropTypes.number,
  oxygenConsumption: PropTypes.number,

  weight: PropTypes.number,
  height: PropTypes.number
}

export default CatheterizationMeasurements;
