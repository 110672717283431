import { Grid } from '@material-ui/core'
import React from 'react'
import CatheterizationMeasurements from './CatheterizationMeasurements'
import PropTypes from 'prop-types'
import TextInput from '../../util/TextInput'

export class Hemodynamics extends React.PureComponent {

  constructor(props) {
    super(props)
  }

  onChanged(key, value, path = []) {
    path.unshift(this.props.dataGroupId)
    this.props.onChanged(key, value, path)
  }

  onStateChanged(key, value) {
    this.setState({
      [key]: value
    })
  }

  render () {
    return (
      <Grid container justify='space-around' alignItems='center' spacing={3}>
        <Grid item xs={2}>
          <TextInput 
            value={this.props.htc || ''}
            label='Htc' adornment='%'
            onValueChanged={value => this.onChanged('htc', value)} />
        </Grid>
        <Grid item xs={2}>
          <TextInput 
            value={this.props.lac || ''}
            label='Lac' 
            onValueChanged={value => this.onChanged('lac', value)} />
        </Grid>
        <Grid item xs={8}/>
        
        <Grid item xs={12}>
          <CatheterizationMeasurements {...(this.props.measurements || {})}
            dataGroupId='measurements'
            onChanged={(key, value, path) => this.onChanged(key, value, path)} />
        </Grid>
      </Grid>
    )
  }
}

Hemodynamics.propTypes = {
  onChanged: PropTypes.func.isRequired,
  dataGroupId: PropTypes.string.isRequired,

  htc: PropTypes.number,
  lac: PropTypes.number,

  measurements: PropTypes.object
}