import { Grid } from '@material-ui/core'
import React from 'react'
import { AdvancedSearch } from './AdvancedSearch'
import { SearchResult } from './SearchResult'
import { getSurgicalCase, deleteSurgicalCase, listSurgicalCases, getSurgicalCaseProtocol } from '../rest/RESTUtils'
import { recordsToTree } from '../util/TreeDataUtils'
import { PureComponent } from 'react'
import CASE_TYPES from '../util/SurgicalCaseTypes.js'
import SurgicalCaseInformation from '../surgicalcaseinfo/SurgicalCaseInformation'

class SurgicalSearch extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      data: [],
      search: null,
      caseForEdit: null
    }
  }

  init() {
    this.setState({
      caseTypes: recordsToTree(CASE_TYPES)
    })
  }

  updateData(search) {
    listSurgicalCases(search, newData => {
      this.setState({ 
        search: search,
        data: newData
      })
    })
  }

  componentDidMount() {
    this.init()
    this.updateData()
  }

  onSearchPerformed(startDate, endDate, name, caseType) {
    this.updateData({startDate: startDate, endDate: endDate, name: name, caseType: caseType})
  }

  onCaseEdited(caseId) {
    getSurgicalCase(caseId, (newData) => {
      this.setState({ 
        caseForEdit: newData
      })
    })
  }

  onCaseDeleted(caseId) {
    deleteSurgicalCase(caseId, () => {
      this.updateData(this.state.search)
    })
  }

  onExportProtocol(caseId) {
    getSurgicalCaseProtocol(caseId, (blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'protocol.pdf');
      
      document.body.appendChild(link);
      
      link.click();
      
      link.parentNode.removeChild(link)
    })
  }

  render () {
    if (this.state.caseForEdit) {
      return <SurgicalCaseInformation caseInformation={this.state.caseForEdit}
        onBackPerformed={() => this.setState({ 
          caseForEdit: null
        })}/>
    } else {
      return (
        <>
          {this.state.caseTypes && (
            <Grid container justify='space-around' alignItems='center' spacing={3}>
              <Grid item xs={4}>
                <AdvancedSearch caseTypes={this.state.caseTypes}
                  onSearchPerformed={(startDate, endDate, name, caseType) => this.onSearchPerformed(startDate, endDate, name, caseType)}/>
              </Grid>
              <Grid item xs={8}>
                <SearchResult rows={this.state.data} 
                  caseTypes={this.state.caseTypes}
                  onCaseEdited={(caseId) => this.onCaseEdited(caseId)}
                  onCaseDeleted={(caseId) => this.onCaseDeleted(caseId)}
                  onExportProtocol={(caseId) => this.onExportProtocol(caseId)}/>
              </Grid>
            </Grid>
          )}
        </>
      )
    }
  }
}

export default SurgicalSearch
