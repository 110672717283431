import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@material-ui/core'
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import AssignmentIcon from '@material-ui/icons/Assignment';
import TreeViewComponent from '../util/TreeViewComponent'
import UserUtils from '../rest/UserUtils'

const columns = [
  { id: 'name', label: 'Име', minWidth: 200, align: 'center' },
  { id: 'date', label: 'Дата', minWidth: 100, align: 'center' },
  { id: 'casetype', label: 'Тип на процедурата', minWidth: 200, align: 'center' },
  { id: 'edit', label: 'Промени', minWidth: 50, align: 'center' },
  { id: 'delete', label: 'Изтрий', minWidth: 50, align: 'center' },
  { id: 'summary', label: 'Протокол', minWidth: 50, align: 'center' },
]

export class SearchResult extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      rowsPerPage: 10,
      caseTypes: []
    }
  }

  handleChangePage(newPage) {
    this.setState({
      page: newPage
    })
  }

  handleChangeRowsPerPage(newRowsPerPage) {
    this.setState({
      page: 0,
      rowsPerPage: newRowsPerPage
    })
  }

  getCaseTypeTitle(id) {
    return TreeViewComponent.getNode(this.props.caseTypes, false, id).title
  }

  render() {
    return (
      <Paper>
        <TableContainer style={{ height: 720 }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.rows && this.props.rows.slice(this.state.page * this.state.rowsPerPage, (this.state.page + 1) * this.state.rowsPerPage).map((data) => {
                return (
                  <TableRow hover role='checkbox' tabIndex={-1} key={data.id}>
                    <TableCell key='name' align='center'>
                      {(data && data.generalInformation && data.generalInformation.name) || ''}
                    </TableCell>
                    <TableCell key='date' align='center'>
                      {(data && data.generalInformation && data.generalInformation.date && new Date(data.generalInformation.date).toLocaleDateString('bg-BG')) || ''}
                    </TableCell>
                    <TableCell key='caseType' align='center'>
                      {((data && data.caseType && data.caseType.selectedCases && data.caseType.selectedCases.map(selectedCase => this.getCaseTypeTitle(selectedCase.caseId)) || []).map((row, index) => {
                        return <div key={index}>{row}</div>
                      }))}
                    </TableCell>
                    <TableCell key='edit' align='center'>
                      <IconButton onClick={() => this.props.onCaseEdited(data.id)}
                        disabled={!UserUtils.hasRole(['riki-surgical-team', 'riki-admin'])}>
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell key='delete' align='center'>
                      <IconButton onClick={() => this.props.onCaseDeleted(data.id)}
                        disabled={!UserUtils.hasRole(['riki-surgical-team', 'riki-admin'])}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell key="summary" align="center">
                      <IconButton onClick={() => this.props.onExportProtocol(data.id)}>
                        <AssignmentIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component='div'
          count={(this.props.rows && this.props.rows.length) || 0}
          labelRowsPerPage='Редове на страница'
          rowsPerPage={this.state.rowsPerPage}
          page={this.state.page}
          onChangePage={(event, newPage) => this.handleChangePage(newPage)}
          onChangeRowsPerPage={(event) => this.handleChangeRowsPerPage(event.target.value)}
        />
      </Paper>
    )
  }
}

SearchResult.propTypes = {
  rows: PropTypes.array,
  caseTypes: PropTypes.object.isRequired,
  onCaseEdited: PropTypes.func.isRequired,
  onCaseDeleted: PropTypes.func.isRequired,
  onExportProtocol: PropTypes.func.isRequired
}