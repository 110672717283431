import { Grid, Paper, TextField, InputAdornment } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

export class AccessTypeComponent extends React.PureComponent {

  constructor(props) {
    super(props);
  }

  onChanged(key, value, path = []) {
    path.unshift(this.props.id);
    this.props.onChanged(key, value, path);
  }

  render() {
    return (
      <Paper style={{ width: "100%" }}>
        <Grid container justify="space-around" alignItems="center" spacing={3}>
          <Grid item xs={12}>
            {this.props.title}
          </Grid>

          <Grid item xs={12}>
            <ToggleButtonGroup exclusive value={this.props.value || ""} onChange={(event, newValue) => this.onChanged("value", newValue)}>
              <ToggleButton value={'femoral'}>
                {'Феморален'}
              </ToggleButton>
              <ToggleButton value={'radial'}>
                {'Радиален'}
              </ToggleButton>
              <ToggleButton value={'other'}>
                {'Друг'}
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          {this.props.value && this.props.value !== 'other' &&
            <>
              <Grid item xs={12}>
                <ToggleButtonGroup exclusive value={this.props.position || ""} onChange={(event, newValue) => this.onChanged("position", newValue)}>
                  <ToggleButton value={'right'}>
                    {'Дясна'}
                  </ToggleButton>
                  <ToggleButton value={'left'}>
                    {'Лява'}
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              <Grid item xs={8}>
                <TextField style={{ width: "100%" }} label="Размер на дезилето" variant="outlined" value={this.props.accessDeviceSize} 
                  onChange={(event) => this.onChanged("accessDeviceSize", event.target.value)}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">Fr</InputAdornment>,
                  }}/>
              </Grid>
            </>
          }
          {this.props.value === 'other' && 
            <Grid item xs={12}>
              <TextField style={{ width: "100%" }} label="Друг" variant="outlined" value={this.props.otherValue} multiline rows={2}
                onChange={(event) => this.onChanged("otherValue", event.target.value)}/>
            </Grid>
          } 
        </Grid>
      </Paper>
    );
  }
}

AccessTypeComponent.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.string,
  position: PropTypes.string,
  otherValue: PropTypes.string,
  accessDeviceSize: PropTypes.number,
  onChanged: PropTypes.func.isRequired
}