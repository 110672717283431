import React from 'react';
import PropTypes from 'prop-types';
import { Grid, ListItem, TextField } from '@material-ui/core';
import BooleanResultToggleButtons from '../../util/BooleanResultToggleButtons';
import TitledBooleanResultToggleButtons from '../../util/TitledBooleanResultToggleButtons';

const AORTIC_VALVE_GRADIENT_LESS_THAN_30 = 0;
const AORTIC_VALVE_GRADIENT_MORE_THAN_30 = 1;

export class AorticValvuloplasty extends React.PureComponent {

  constructor(props) {
    super(props);
  }

  onChanged(key, value, path = []) {
    path.unshift(this.props.dataGroupId);
    this.props.onChanged(key, value, path);
  }

  render() {
    return (
      <ListItem>
        <Grid container justify="space-around" alignItems="center" spacing={3}>
          <Grid item xs={3}>
            Аортна Валвулопластика
          </Grid>
          <Grid item xs={3}>
            <BooleanResultToggleButtons value={this.props.performed} 
              onValueChange={(newValue) => this.onChanged("performed", newValue)} />
          </Grid>
          <Grid item xs={6} />

          {this.props.performed && (
            <>
              <Grid item xs={6}>
                <TextField style={{ width: "100%" }} label="Индикации" variant="outlined" multiline rows={2} 
                  value={this.props.indications}
                  onChange={(event) => this.onChanged("indications", event.target.value)}/>
              </Grid>
              <Grid item xs={6} />

              <Grid item xs={2}>
                Лявокамерна дисфункция
              </Grid>
              <Grid item xs={2}>
                <TitledBooleanResultToggleButtons title="Преди процедурата"
                  value={this.props.disfunctionBeforeProcedure}
                  onValueChange={(newValue) => this.onChanged("disfunctionBeforeProcedure", newValue)} />
              </Grid>
              <Grid item xs={2}>
                <TitledBooleanResultToggleButtons title="След процедурата"
                  value={this.props.disfunctionAfterProcedure}
                  onValueChange={(newValue) => this.onChanged("disfunctionAfterProcedure", newValue)} />
              </Grid>
              <Grid item xs={6} />

              <Grid item xs={2}>
                Градиент
              </Grid>
              <Grid item xs={2}>
                <TitledBooleanResultToggleButtons title="Преди процедурата"
                  value={this.props.aorticValveGradientBeforeProcedure === AORTIC_VALVE_GRADIENT_MORE_THAN_30}
                  falseLabel="< 30 mmHg" trueLabel="> 30 mmHg"
                  onValueChange={(newValue) => 
                    this.onChanged("aorticValveGradientBeforeProcedure", newValue ? AORTIC_VALVE_GRADIENT_MORE_THAN_30 : AORTIC_VALVE_GRADIENT_LESS_THAN_30)
                  } />
              </Grid>
              <Grid item xs={2}>
                <TitledBooleanResultToggleButtons title="След процедурата"
                  value={this.props.aorticValveGradientAfterProcedure === AORTIC_VALVE_GRADIENT_MORE_THAN_30}
                  falseLabel="< 30 mmHg" trueLabel="> 30 mmHg"
                  onValueChange={(newValue) => 
                    this.onChanged("aorticValveGradientAfterProcedure", newValue ? AORTIC_VALVE_GRADIENT_MORE_THAN_30 : AORTIC_VALVE_GRADIENT_LESS_THAN_30)
                  } />
              </Grid>
              <Grid item xs={6} />
            </>
          )}
        </Grid>
      </ListItem>
    );
  }
}

AorticValvuloplasty.propTypes = {
  onChanged: PropTypes.func.isRequired,
  dataGroupId: PropTypes.string.isRequired,

  performed: PropTypes.bool,
  indications: PropTypes.string,

  aorticValveGradientBeforeProcedure: PropTypes.number,
  aorticValveGradientAfterProcedure: PropTypes.number,

  disfunctionBeforeProcedure: PropTypes.bool,
  disfunctionAfterProcedure: PropTypes.bool
}
