export function recordsToTree(records) {
  const rootChildren = getRootChildren(records);

  rootChildren.forEach(element => {
    initRecord(records, element);
  });

  return { "id": "root", "title": "root", "children": rootChildren };
}

function initRecord(records, record) {
  const children = getChildrenForRecord(records, record);
  
  children.forEach(element => {
    initRecord(records, element);
  });

  record.children = children;
}

function getRootChildren(records) {
  return records.filter((el) => {
    return el.id.indexOf('.') === -1;
  });
}

function getChildrenForRecord(records, record) {
  const prefix = record.id + '.';
  return records.filter((el) => {
    return el.id.startsWith(prefix) && el.id.lastIndexOf('.') === prefix.length - 1;
  });
}