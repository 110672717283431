import React from 'react';
import PropTypes from 'prop-types';
import { Grid, InputAdornment, Paper, TextField } from '@material-ui/core';
import { KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import differenceInMinutes from 'date-fns/differenceInMinutes'
import { AccessTypeComponent } from './AccessTypeComponent';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

export class ProcedureCharacteristics extends React.PureComponent {

  constructor(props) {
    super(props);
  }

  onChanged(key, value, path = []) {
    path.unshift(this.props.dataGroupId);
    this.props.onChanged(key, value, path);
  }

  onAccessChanged(key, value, path = []) {
    path.unshift(this.props.dataGroupId, 'access');
    this.props.onChanged(key, value, path);
  }

  calculateProcedureDuration() {
    if (this.props.catheterIn && this.props.catheterOut) {
      var difference = differenceInMinutes(this.props.catheterOut, this.props.catheterIn);
      var minutes = difference % 60;
      var hours = (difference - minutes) / 60;

      return (hours ? hours + "ч. " : "") + String(minutes).padStart(2, '0') + "мин.";
    } else {
      return "";
    }
  }

  calculateVolumePerKg() {
    if (this.props.contrastVolume && this.props.weight) {
      return (this.props.contrastVolume / this.props.weight).toFixed(2);
    } else {
      return "";
    }
  }

  render () {
    return (
      <Grid container justify="space-around" alignItems="center" spacing={3}>
        <Grid item xs={6}>
          <Paper>
            <Grid container justify="space-around" alignItems="center" spacing={6}>
              <Grid item xs={12}>
                Airway management
              </Grid>

              <Grid item xs={12}>
                <ToggleButtonGroup exclusive value={this.props.airwayManagement || (this.props.premedication ? "other" : "")} 
                  onChange={(event, newValue) => this.onChanged("airwayManagement", newValue)}>
                  <ToggleButton value={'intubation'}>
                    {'Интубация'}
                  </ToggleButton>
                  <ToggleButton value={'laringialMask'}>
                    {'Ларингиална маска'}
                  </ToggleButton>
                  <ToggleButton value={'spontaneousBreathing'}>
                    {'Спонтанно дишане'}
                  </ToggleButton>
                  <ToggleButton value={'other'}>
                    {'Друго'}
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>

              {(this.props.airwayManagement === 'other' || (!this.props.airwayManagement && this.props.premedication))&& 
                <Grid item xs={12}>
                  <TextField style={{ width: "100%" }} label="Друго" variant="outlined" value={this.props.premedication} multiline rows={2}
                    onChange={(event) => this.onChanged("premedication", event.target.value)}/>
                </Grid>
              } 
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={6}>
          <Paper>
            <Grid container justify="space-around" alignItems="center" spacing={6}>
              <Grid item xs={12}>
                Седация
              </Grid>

              <Grid item xs={12}>
                <ToggleButtonGroup exclusive value={this.props.sedation || ""} 
                  onChange={(event, newValue) => this.onChanged("sedation", newValue)}>
                  <ToggleButton value={'total'}>
                    {'Тотална'}
                  </ToggleButton>
                  <ToggleButton value={'inhalatory'}>
                    {'Инхалаторна'}
                  </ToggleButton>
                  <ToggleButton value={'venous'}>
                    {'Венозна'}
                  </ToggleButton>
                  <ToggleButton value={'local'}>
                    {'Локална'}
                  </ToggleButton>
                  <ToggleButton value={'other'}>
                    {'Друго'}
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>

              {this.props.sedation === 'other' && 
                <Grid item xs={12}>
                  <TextField style={{ width: "100%" }} label="Друго" variant="outlined" value={this.props.sedationDetails} multiline rows={2}
                    onChange={(event) => this.onChanged("sedationDetails", event.target.value)}/>
                </Grid>
              } 
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={6}>
          <Paper>
            <Grid container justify="space-around" alignItems="center" spacing={3}>
              <Grid item xs={12}>
                Съдов достъп
              </Grid>

              <Grid item xs={6}>
                <AccessTypeComponent id="arterial" title="Артериален" {...((this.props.access && this.props.access.arterial) || {})}
                  onChanged={(key, value, path) => this.onAccessChanged(key, value, path)}/>
              </Grid>
              <Grid item xs={6}>
                <AccessTypeComponent id="venous" title="Венозен" {...((this.props.access && this.props.access.venous) || {})}
                  onChanged={(key, value, path) => this.onAccessChanged(key, value, path)}/>
              </Grid>
            </Grid>
          </Paper>
          
        </Grid>
        <Grid item xs={6} />

        <Grid item xs={3}>
          <TextField style={{ width: "100%" }} label="Рентгеново време" variant="outlined" 
            value={this.props.roentgenTime || ""} 
            onChange={(event) => this.onChanged("roentgenTime", event.target.value)}
            InputProps={{
              endAdornment: <InputAdornment position="end">мин</InputAdornment>,
            }}/>
        </Grid>
        <Grid item xs={3}>
          <TextField style={{ width: "100%" }} label="Рентгенова доза" variant="outlined" 
            value={this.props.roentgenDosage || ""} 
            onChange={(event) => this.onChanged("roentgenDosage", event.target.value)}
            InputProps={{
              endAdornment: <InputAdornment position="end">μGy*M²</InputAdornment>,
            }}/>
        </Grid>
        <Grid item xs={6} />

        <Grid item xs={3}>
          <TextField style={{ width: "100%" }} label="Контраст" variant="outlined" 
            value={this.props.usedContrast || ""} 
            onChange={(event) => this.onChanged("usedContrast", event.target.value)}/>
        </Grid>
        <Grid item xs={3}>
          <TextField style={{ width: "100%" }} label="Количество" variant="outlined" 
            value={this.props.contrastVolume || ""} 
            onChange={(event) => this.onChanged("contrastVolume", parseInt(event.target.value))}
            InputProps={{
              endAdornment: <InputAdornment position="end">mL</InputAdornment>,
            }}/>
        </Grid>
        <Grid item xs={3}>
          <TextField style={{ width: "100%" }} label="Количество на килограм" variant="outlined" 
            disabled
            value={this.calculateVolumePerKg()} 
            InputProps={{
              endAdornment: <InputAdornment position="end">mL/kg</InputAdornment>,
            }}/>
        </Grid>
        <Grid item xs={3} />

        <Grid item xs={3}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardTimePicker label="Начало" margin="normal" inputVariant="outlined"
              value={(this.props.catheterIn && new Date(this.props.catheterIn)) || null}
              onChange={(newValue) => this.onChanged("catheterIn", newValue?.getTime())}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={3}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardTimePicker label="Край" margin="normal" inputVariant="outlined"
              value={(this.props.catheterOut && new Date(this.props.catheterOut)) || null}
              onChange={(newValue) => this.onChanged("catheterOut", newValue?.getTime())}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={3}>
          <TextField style={{ width: "100%" }} label="Продължителност"
            disabled variant="outlined" value={this.calculateProcedureDuration()} />
        </Grid>
        <Grid item xs={3} />
      </Grid>
    );
  }
}

ProcedureCharacteristics.propTypes = {
  onChanged: PropTypes.func.isRequired,
  dataGroupId: PropTypes.string.isRequired,

  airwayManagement: PropTypes.string,
  premedication: PropTypes.string,

  sedation: PropTypes.string,
  sedationDetails: PropTypes.string,

  access: PropTypes.object,

  roentgenTime: PropTypes.number,
  roentgenDosage: PropTypes.number,

  usedContrast: PropTypes.string,
  contrastVolume: PropTypes.number,

  catheterIn: PropTypes.object,
  catheterOut: PropTypes.object,

  weight: PropTypes.number
}