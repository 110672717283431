import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, Input, InputAdornment, InputLabel } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import TreeViewComponent from '../../util/TreeViewComponent';

class DiagnoseChoiceDialog extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      selected: []
    }
  }

  onNodeSelected(nodeIds) {
    this.setState(function(state) {
      const selected = state.selected;
      
      if (TreeViewComponent.isLeafNode(this.props.diagnoses, false, nodeIds[0]) && selected.indexOf(nodeIds[0]) == -1) {
        selected.push(nodeIds[0]);
      }

      return {
        selected: selected
      }
    })
  }

  render() {
    return (
      <Dialog onClose={() => this.props.onClose(null)} open={true} width={500} fullWidth>
        <DialogTitle>Детайли</DialogTitle>
        <DialogContent>
          <TreeViewComponent multiSelect={true} root={this.props.diagnoses} showRootNode={false} 
            onNodeSelect={(nodeIds) => this.onNodeSelected(nodeIds)} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.props.onClose(null)} color="primary">
            Затвори
          </Button>
          <Button disabled={this.state.level == -1 || this.state.timeToManage == -1} color="primary"
            onClick={() => this.props.onClose(this.state.selected)}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

DiagnoseChoiceDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  diagnoses: PropTypes.object.isRequired
}

export class DiagnoseInput extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      diagnosesDialogOpen: false
    }
  }

  onStateChanged(key, value) {
    this.setState({
      [key]: value
    })
  }

  onDiagnosesChanged(diagnosesIds) {
    this.onStateChanged("diagnosesDialogOpen", false);
    this.props.onChanged(diagnosesIds || []);
  }

  getDiagnosesTitle(id) {
    return TreeViewComponent.getNode(this.props.diagnoses, false, id).title;
  }

  render () {
    return (
      <>
        <FormControl style={{ width: "100%" }}>
          <InputLabel htmlFor="standard-adornment-diagnoses">Диагноза</InputLabel>
          <Input style={{ width: "100%" }} 
            value={(this.props.diagnosesIds && this.props.diagnosesIds.map((diagnose) => this.getDiagnosesTitle(diagnose)).join(" | ")) || ""} 
            id="standard-adornment-diagnoses"
            disabled
            onClick={() => this.onStateChanged("diagnosesDialogOpen", true)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            }/>
        </FormControl>
      
        {this.state.diagnosesDialogOpen && (
          <DiagnoseChoiceDialog diagnoses={this.props.diagnoses} 
            onClose={(selected) => this.onDiagnosesChanged(selected)}/>
        )}
      </>
    )
  }
}

DiagnoseInput.propTypes = {
  onChanged: PropTypes.func.isRequired,
  diagnoses: PropTypes.object,
  diagnosesIds: PropTypes.array
}